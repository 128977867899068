define('im-webapp/routes/protected/my-feed', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),

        model: function model() {
            var currentUserId = this.get('session.data.authenticated.user_id');
            var filter = {
                user: currentUserId,
                itemsPerPage: 20,
                include: 'creator,initiative',
                order: {
                    created: 'DESC'
                },
                page: 1
            };
            return {
                myFeed: this.get('store').query('activity', filter),
                filter: filter
            };
        },

        actions: {
            loading: function loading() {
                this.controllerFor('protected').set('isLoading', true);
                this.router.on('didTransition', this, function () {
                    this.controllerFor('protected').set('isLoading', false);
                });
            }
        }
    });
});