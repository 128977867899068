define('im-webapp/components/initiative-overview-box', ['exports', 'im-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['initiative-overview'],
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    counter: Ember.inject.service(),
    coverPhoto: Ember.inject.service(),
    momentFormat: _environment.default.APP.MOMENT_FORMAT,

    fullImagePath: Ember.computed('initiative.cover', function () {
      var coverPhoto = this.get('coverPhoto');
      return this.initiative.get('cover') ? _environment.default.APP.HOST + this.initiative.get('cover') : coverPhoto.setCoverPhoto();
    }),

    isFollowed: Ember.computed('initiative.{followers,followers.[]}', function () {
      var currentUserId = this.get('session.data.authenticated.user_id');
      var followers = this.get('initiative.followers');
      var isFollowed = false;

      followers.forEach(function (follower) {
        if (+follower.get('id') === currentUserId) {
          isFollowed = true;
        }
      });

      return isFollowed;
    }),

    followers: Ember.computed('initiative.{followers,followers.[]}', function () {
      return this.get('counter').checkArray(this.get('initiative'), 'followers');
    }),

    actions: {
      addIdea: function addIdea() {
        this.get('addIdea')();
      },
      follow: function follow(initiative, _follow) {
        var currentUser = this.get('store').peekRecord('user', this.get('session.data.authenticated.user_id'));

        if (_follow) {
          initiative.get('followers').pushObject(currentUser);
        } else {
          initiative.get('followers').removeObject(currentUser);
        }

        initiative.save();
      },


      /**
       * like
       * @method like
       * @param isLike (boolean) - defines is like or dislike
       * @param liked (boolean) - defines is adding or removing like/dislike
       */
      like: function like(isLike, liked) {
        var currentUser = this.get('store').peekRecord('user', this.get('session.data.authenticated.user_id'));
        var initiative = this.get('initiative');

        if (isLike) {
          if (liked) {
            initiative.get('likes').removeObject(currentUser);
          } else {
            initiative.get('likes').pushObject(currentUser);
          }
        } else {
          if (liked) {
            initiative.get('dislikes').removeObject(currentUser);
          } else {
            initiative.get('dislikes').pushObject(currentUser);
          }
        }

        initiative.save();
      }
    }

  });
});