define('im-webapp/components/server-models-table', ['exports', 'ember-models-table/components/models-table-server-paginated'], function (exports, _modelsTableServerPaginated) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var extend = Ember.$.extend;


  var NOT_SORTED = -1;

  /**
   * Default filter-function used in the filter by columns
   *
   * @param {string} cellValue value in the table cell
   * @param {string} filterString needed substring
   * @returns {boolean}
   * @ignore
   */
  function defaultFilter(cellValue, filterString) {
    return -1 !== cellValue.indexOf(filterString);
  }

  /**
   * Convert some string to the human readable one
   *
   * @param {string} name value to convert
   * @returns {string}
   * @ignore
   */
  function propertyNameToTitle(name) {
    return Ember.String.capitalize(Ember.String.dasherize(name).replace(/-/g, ' '));
  }

  exports.default = _modelsTableServerPaginated.default.extend({
    i18n: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),

    init: function init() {
      this._super.apply(this, arguments);
      //subscribe to changing language
      this.get('eventBus').subscribe('chooseLanguage', this, this.rebuildTable);

      //initial setup for table
      this.setMessages();
    },


    /**
     * Create new properties for `columns`
     *
     * @method _setupColumns
     * @returns {undefined}
     * @private
     */
    _setupColumns: function _setupColumns() {
      var _this = this;

      var i18n = this.get('i18n');
      var nColumns = Ember.A(Ember.get(this, 'columns').map(function (column) {

        //
        var filterFunction = Ember.get(column, 'filterFunction');
        filterFunction = 'function' === Ember.typeOf(filterFunction) ? filterFunction : defaultFilter;

        //translating columns
        if (column.hasOwnProperty('title_translation')) {
          column.title = i18n.t('table.' + column.title_translation);
        } else {
          var label = (column.propertyName || column.title).toLowerCase();
          column.title_translation = label;
          column.title = i18n.t('table.' + label);
        }

        var c = _this._createColumn(column);

        Ember.setProperties(c, {
          filterString: Ember.get(c, 'filterString') || '',
          originalDefinition: column
        });

        _this._setupColumnsComponent(c, column);

        Ember.set(c, 'filterFunction', filterFunction);

        if (Ember.isNone(Ember.get(c, 'mayBeHidden'))) {
          Ember.set(c, 'mayBeHidden', true);
        }

        var sortDirection = column.sortDirection,
            sortPrecedence = column.sortPrecedence;

        var hasSortPrecedence = !Ember.isNone(sortPrecedence) && sortPrecedence > NOT_SORTED;
        var defaultSortPrecedence = hasSortPrecedence ? sortPrecedence : NOT_SORTED;
        var defaultSorting = sortDirection && sortPrecedence > NOT_SORTED ? sortDirection.toLowerCase() : 'none';

        Ember.setProperties(c, {
          defaultVisible: !Ember.get(c, 'isHidden'),
          sorting: defaultSorting,
          sortPrecedence: defaultSortPrecedence
        });
        return c;
      }));

      nColumns.filterBy('propertyName').forEach(function (column) {
        var propertyName = Ember.get(column, 'propertyName');
        if (Ember.isNone(Ember.get(column, 'title'))) {
          Ember.set(column, 'title', propertyNameToTitle(propertyName));
        }
      });
      Ember.set(this, 'processedColumns', nColumns);

      this._super.apply(this, arguments);
    },
    setMessages: function setMessages() {
      var i18n = this.get('i18n');
      var customMessages = {
        "searchPlaceholder": i18n.t('general.search').string,
        "searchLabel": "",
        "noDataToShow": i18n.t('general.no_data').string,
        "tableSummary": i18n.t('general.show').string + ' %@ - %@ ' + i18n.t('general.of').string + ' %@',
        "columns-title": i18n.t('general.columns').string,
        "columns-showAll": i18n.t('general.show_all').string,
        "columns-hideAll": i18n.t('general.hide_all').string,
        "columns-restoreDefaults": i18n.t('general.restore_defaults').string
      };
      // this.set('themeInstance.messages', customMessages);
    },
    rebuildTable: function rebuildTable() {
      this._super.apply(this, arguments);
      this.setMessages();
    },


    pageSizeValues: Ember.computed(function () {
      return Ember.A([5, 10, 30]);
    }),

    metaItemsCountProperty: 'totalItems',

    showGlobalFilter: false,
    useFilteringByColumn: true,
    gotoForwardEnabled: true,

    pagesCount: Ember.computed('arrangedContent.[]', 'pageSize', function () {
      var pagesCount = Ember.get(this, 'arrangedContentLength') / parseInt(Ember.get(this, 'pageSize'), 10);
      return 0 === pagesCount % 1 ? pagesCount : Math.floor(pagesCount) + 1;
    }),

    /**
     * This function actually loads the data from the server.
     * It takes the store, modelName and query from the passed in data-object and adds page, sorting & filtering to it.
     *
     * @returns {Promise}
     * @method _loadData
     * @private
     */
    _loadData: function _loadData() {
      var data = Ember.get(this, 'data');
      var pageSize = Ember.get(this, 'pageSize');

      if (!Ember.get(data, 'query')) {
        (true && Ember.warn('You must use http://emberjs.com/api/data/classes/DS.Store.html#method_query for loading data'));

        return;
      }
      var query = extend({}, Ember.get(data, 'query'));
      Ember.set(query, 'itemsPerPage', pageSize);
      Ember.set(data, 'query', query);
      this._super.apply(this, arguments);
    }

  });
});