define('im-webapp/components/comment-single', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['comment'],
        session: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);
            this.set('isReadonly', true);
            this.set('commentText', this.get('comment.text'));
        },


        editable: Ember.computed('comment', function () {
            return this.get('session.data.authenticated.user_id').toString() === this.comment.get('creator.id');
        }),

        actions: {
            edit: function edit() {
                this.set('isReadonly', false);
            },
            confirmEdit: function confirmEdit(comment, text) {
                var self = this;
                if (text) {
                    self.set('blankComment', false);
                    comment.set('text', text);
                    comment.save();
                    this.set('isReadonly', true);
                } else {
                    self.set('blankComment', true);
                }
            }
        }
    });
});