define('im-webapp/router', ['exports', 'im-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend({
        location: _environment.default.locationType,
        rootURL: _environment.default.rootURL
    });

    Router.map(function () {
        this.route('login');
        this.route('forgot-password');
        this.route('recover-password');
        this.route('protected', { path: '' }, function () {
            this.route('user-management', function () {
                this.route('user', { path: '/:id' });
            });
            this.route('status-management', function () {
                this.route('status', { path: '/:id' });
            });
            this.route('tag-management', function () {
                this.route('tag', { path: '/:id' });
            });
            this.route('group-management', function () {
                this.route('group', { path: '/:id' });
            });
            this.route('category-management', function () {
                this.route('category', { path: '/:id' });
            });
            this.route('initiative-management', function () {
                this.route('initiative', { path: '/:id' });
            });
            this.route('idea-management', function () {
                this.route('idea', { path: '/:id' });
            });
            this.route('my-profile');
            this.route('my-feed');
            this.route('my-ideas');
            this.route('my-initiatives');
            this.route('initiative', { path: 'initiative/:id' }, function () {
                this.route('comments');
                this.route('media');
                this.route('feeds');
            });
            this.route('idea', { path: 'idea/:id' }, function () {
                this.route('comments');
                this.route('media');
            });
            this.route('media-library');
            this.route('messages', function () {
                this.route('private', { path: '/:id' });
            });
            this.route('notifications');
            this.route('statistics');
        });
        this.route('missing', { path: '/*path' });
    });

    exports.default = Router;
});