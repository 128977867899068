define('im-webapp/routes/protected/user-management', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    model: function model() {

      return Ember.RSVP.hash({
        users: this.get('store').query('user', {})
      });
    },

    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      },

      loading: function loading() {
        this.controllerFor('protected').set('isLoading', true);
        this.router.on('didTransition', this, function () {
          this.controllerFor('protected').set('isLoading', false);
        });
      }
    }

  });
});