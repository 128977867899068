define('im-webapp/components/forgot-password-form', ['exports', 'im-webapp/validations/forgot-password', 'im-webapp/config/environment'], function (exports, _forgotPassword, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    customAjax: Ember.inject.service('customAjax'),
    classNames: ['login-form'],
    toast: Ember.inject.service(),
    i18n: Ember.inject.service(),
    forgotPassValidation: _forgotPassword.default,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('forgot', {
        email: ''
      });
    },


    actions: {
      recoverPassword: function recoverPassword(changeset) {
        changeset.validate();
        var isValid = changeset.get('isValid');
        var self = this;

        if (isValid) {
          var url = _environment.default.APP.API_HOST + "/forgot-password";
          var data = { email: changeset.get('email') };
          var customAjax = self.get('customAjax');
          var toast = this.get('toast');
          var i18n = this.get('i18n');

          customAjax.request('POST', url, data).then(function () {
            toast.success(i18n.t('auth.email_with_instructions'), i18n.t('general.successful'));
          }).catch(function (error) {
            toast.error(error || error ? error.message : i18n.t('general.error'), i18n.t('general.error'));
          });
        }
      }
    }
  });
});