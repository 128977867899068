define('im-webapp/routes/protected/my-initiatives', ['exports', 'moment', 'im-webapp/config/environment'], function (exports, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),

    model: function model() {
      var currentUserId = this.get('session.data.authenticated.user_id');
      var filter = {
        'initiative[exists]': false,
        include: 'status,followers,comments,timelineStatuses',
        order: {
          created: 'DESC'
        },
        'active': true,
        'owner.id': currentUserId,
        "created[after]": (0, _moment.default)().startOf('month').format(_environment.default.APP.FILTER_FORMAT),
        "created[before]": (0, _moment.default)().endOf('month').format(_environment.default.APP.FILTER_FORMAT)
      };

      return {
        tags: this.get('store').findAll('tag'),
        categories: this.get('store').findAll('category'),
        status: this.get('store').findAll('status'),
        myInitiatives: this.get('store').query('initiative', filter),
        additionalFilters: filter
      };
    },

    actions: {
      loading: function loading() {
        this.controllerFor('protected').set('isLoading', true);
        this.router.on('didTransition', this, function () {
          this.controllerFor('protected').set('isLoading', false);
        });
      }
    }
  });
});