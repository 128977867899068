define('im-webapp/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'im-webapp/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    toast: Ember.inject.service(),
    i18n: Ember.inject.service(),
    host: _environment.default.APP.API_HOST,

    authorize: function authorize(xhr) {
      var _get = this.get('session.data.authenticated'),
          access_token = _get.access_token;

      if (Ember.isPresent(access_token)) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
      }
    },
    handleResponse: function handleResponse(status, head, model, action) {
      var i18n = this.get('i18n');
      var toast = this.get('toast');

      var errorString = i18n.t('general.error');
      var successfulString = i18n.t('general.successful');

      switch (status) {
        case 400:
          toast.error(i18n.t('error.bad_request'), errorString);
          break;
        case 404:
          toast.error(i18n.t('error.resource_not_found'), errorString);
          break;
        case 401:
          toast.error(i18n.t('error.unauthorized'), errorString);
          break;
        case 403:
          toast.error(i18n.t('error.no_permission'), errorString);
          break;
        case 405:
          toast.error(i18n.t('error.not_allowed'), errorString);
          break;
        case 408:
          toast.warning(i18n.t('error.time_out'), errorString);
          break;
        case 429:
          toast.warning(i18n.t('error.too_many'), errorString);
          break;
        case 200:
          if (action.method === 'POST' || action.method === 'PATCH') {
            if (model.data.type === 'initiatives' && model.data.relationships.hasOwnProperty('initiative')) {
              toast.success(i18n.t('notify.idea') + i18n.t('notify.updated'), successfulString);
            } else {
              toast.success(i18n.t('notify.' + model.data.type) + i18n.t('notify.updated'), successfulString);
            }
          }
          break;
        case 201:
          if (model.data.type !== 'messages') {
            if (model.data.type === 'initiatives' && model.data.relationships.hasOwnProperty('initiative')) {
              toast.success(i18n.t('notify.idea') + i18n.t('notify.added'), successfulString);
            } else {
              toast.success(i18n.t('notify.' + model.data.type) + i18n.t('notify.added'), successfulString);
            }
          }
          break;
        case 204:
          toast.success(i18n.t('notify.deleted'), successfulString);
          break;
        default:
          toast.error(i18n.t('error.server'), errorString);
          break;
      }

      return this._super.apply(this, arguments);
    }
  });
});