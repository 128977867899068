define('im-webapp/components/side-bar-nav-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['sideMenu-link-wrapper']

  });
});