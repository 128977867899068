define('im-webapp/controllers/protected/group-management', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        groupsTable: Ember.computed('model.{groups,groups.[]}', function () {

            var groups = this.get('model').groups;
            return {
                header: [{ title: 'Name', propertyName: 'name', routeName: "protected.group-management.group" }, {
                    title: 'Edit',
                    component: "table-button",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-button",
                    isDelete: true,
                    disableSorting: true
                }],
                data: groups
            };
        }),

        actions: {
            closeModal: function closeModal() {
                this.set('deleteModal', false);
            },
            deleteGroup: function deleteGroup(id) {
                var group = this.get('store').peekRecord('group', id);
                this.set('currentGroup', group);
                this.set('deleteModal', true);
            },
            confirmDeleteGroup: function confirmDeleteGroup() {
                var group = this.get('currentGroup');
                group.deleteRecord();
                group.save();
                this.send('closeModal');
            },
            addGroup: function addGroup() {
                this.transitionToRoute('protected.group-management.group', 'new');
            },
            editGroup: function editGroup(id) {
                this.transitionToRoute('protected.group-management.group', id);
            }
        }
    });
});