define('im-webapp/routes/protected/messages', ['exports', 'im-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    customAjax: Ember.inject.service('customAjax'),
    toast: Ember.inject.service(),
    session: Ember.inject.service(),

    model: function model() {

      var customAjax = this.get('customAjax');
      var toast = this.get('toast');
      var i18n = this.get('i18n');

      var url = _environment.default.APP.API_HOST + "/get-messages-conversation/" + this.get('session.data.authenticated.user_id');

      return customAjax.request('GET', url).then(function (conversations) {
        return conversations;
      }).catch(function () {
        toast.error(i18n.t('error.server'), i18n.t('general.error'));
      });
    },

    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
});