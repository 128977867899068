define('im-webapp/services/current-user', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        session: Ember.inject.service('session'),
        store: Ember.inject.service(),

        load: function load() {
            var _this = this;

            var userId = this.get('session.data.authenticated.user_id');
            if (!Ember.isEmpty(userId)) {
                return this.get('store').findRecord('user', userId).then(function (user) {
                    _this.set('user', user);
                    return user;
                });
            } else {
                return Ember.RSVP.resolve();
            }
        }
    });
});