define('im-webapp/models/status', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        name: (0, _attr.default)('string'),
        description: (0, _attr.default)('string'),
        active: (0, _attr.default)('boolean'),
        voting: (0, _attr.default)('boolean'),
        color: (0, _attr.default)('string')
    });
});