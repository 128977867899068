define('im-webapp/instance-initializers/application', ['exports', 'im-webapp/utils/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    var SubscriberArg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

    _application.default.instance = appInstance;

    var applicationRoute = appInstance.lookup('route:application');
    var session = appInstance.lookup('service:session');
    session.on('authenticationSucceeded', function () {
      applicationRoute.transitionTo('protected.index');
    });
    session.on('invalidationSucceeded', function () {
      applicationRoute.transitionTo('login');
    });

    //event bus
    var Subscriber = Ember.isPresent(SubscriberArg) ? SubscriberArg : Ember.Object.extend({
      eventBus: Ember.inject.service('event-bus')
    });

    appInstance.register('event-bus:subscriber', Subscriber, { instantiate: false });
  }

  exports.default = {
    name: 'application',
    initialize: initialize
  };
});