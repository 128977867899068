define('im-webapp/services/event-bus', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    publish: function publish() {
      return this.trigger.apply(this, arguments);
    },
    subscribe: function subscribe() {
      return this.on.apply(this, arguments);
    },
    unsubscribe: function unsubscribe() {
      return this.off.apply(this, arguments);
    }
  });
});