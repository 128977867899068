define('im-webapp/routes/protected/initiative/index', ['exports', 'moment', 'im-webapp/config/environment'], function (exports, _moment, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function model(params, history) {

            var initiativeId = history.state.params['protected.initiative'].id;

            //ideas filter for initiative
            var filter = {
                'initiative[exists]': true,
                include: 'status,followers,comments,timelineStatuses',
                order: {
                    created: 'DESC'
                },
                'active': true,
                'initiative.id': initiativeId,
                "created[after]": (0, _moment.default)().startOf('month').format(_environment.default.APP.FILTER_FORMAT),
                "created[before]": (0, _moment.default)().endOf('month').format(_environment.default.APP.FILTER_FORMAT)
            };

            return Ember.RSVP.hash({
                initiative: this.get('store').findRecord('initiative', initiativeId, { include: 'status,followers,comments,timelineStatuses' }),
                initiativeTimelineStatuses: this.get('store').query('timeline-status', { 'initiative': initiativeId, include: 'status' }),
                tags: this.get('store').findAll('tag'),
                categories: this.get('store').findAll('category'),
                status: this.get('store').findAll('status'),
                additionalFilters: filter
            });
        }
    });
});