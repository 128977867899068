define('im-webapp/components/language-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['language-picker'],
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),

    init: function init() {
      this._super.apply(this, arguments);
      var locales = this.get('i18n.locales').map(function (loc) {
        return { value: loc, text: 'languages.' + loc };
      });

      var currentLocale = this.locale || 'en';
      this.set('i18n.locale', currentLocale);
      this.set('languages', locales);

      this.setSelectedLanguage(locales, currentLocale);

      //subscribe to update selected language on user management
      this.get('eventBus').subscribe('updateSelectedLanguage', this, this.updateSelectedLanguage);
    },
    updateSelectedLanguage: function updateSelectedLanguage() {
      var languages = this.get('languages');
      var currentUser = this.get('store').peekRecord('user', this.get('session.data.authenticated.user_id'));
      this.setSelectedLanguage(languages, currentUser.get('locale'));
    },
    setSelectedLanguage: function setSelectedLanguage(languages, currentLocale) {
      var self = this;
      languages.forEach(function (locale) {
        if (locale.value === currentLocale) {
          self.set('selectedLanguage', {
            value: locale.value,
            text: locale.text
          });
        }
      });
    },


    actions: {
      selectLanguge: function selectLanguge(lang) {
        this.set('i18n.locale', lang.value);
        this.set('selectedLanguage', lang);
        this.get('store').findRecord('user', this.get('session.data.authenticated.user_id')).then(function (user) {
          user.set('locale', lang.value);
          user.save();
        });

        //publish event
        this.get('eventBus').publish('chooseLanguage');
      }
    }
  });
});