define('im-webapp/validations/initiative', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    title: (0, _validators.validatePresence)(true),
    content: (0, _validators.validatePresence)(true)
  };
});