define('im-webapp/routes/protected/idea/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    model: function model(params, history) {
      var initiativeId = history.state.params['protected.idea'].id;
      return Ember.RSVP.hash({
        feeds: this.get('store').query('activity', { initiative: initiativeId, include: 'creator' }),
        initiativeId: initiativeId
      });
    },

    actions: {
      refreshModel: function refreshModel(callingFromParent) {
        if (!callingFromParent) this.refresh();
      }
    }
  });
});