define('im-webapp/validations/messages', ['exports', 'im-webapp/utils/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Messages = Ember.Object.extend({
        i18n: Ember.computed(function () {
            return _application.default.instance.lookup('service:i18n');
        }),

        init: function init() {
            this.set('confirmation', this.get('i18n').t('validations.password_must_match').toString());
            this.set('email', this.get('i18n').t('validations.email').toString());
            this.set('notANumber', this.get('i18n').t('validations.not_a_number').toString());
            this.set('present', this.get('i18n').t('validations.present').toString());
            this.set('tooShort', this.get('i18n').t('validations.too_short').toString());
            this.set('invalid', this.get('i18n').t('validations.invalid').toString());
        },


        inclusion: '{description} is not included in the list',
        exclusion: '{description} is reserved',
        accepted: '{description} must be accepted',
        empty: "{description} can't be empty",
        blank: '{description} must be blank',
        collection: '{description} must be a collection',
        singular: "{description} can't be a collection",
        tooLong: '{description} is too long (maximum is {max} characters)',
        between: '{description} must be between {min} and {max} characters',
        before: '{description} must be before {before}',
        onOrBefore: '{description} must be on or before {onOrBefore}',
        after: '{description} must be after {after}',
        onOrAfter: '{description} must be on or after {onOrAfter}',
        wrongDateFormat: '{description} must be in the format of {format}',
        wrongLength: '{description} is the wrong length (should be {is} characters)',
        notAnInteger: '{description} must be an integer',
        greaterThan: '{description} must be greater than {gt}',
        greaterThanOrEqualTo: '{description} must be greater than or equal to {gte}',
        equalTo: '{description} must be equal to {is}',
        lessThan: '{description} must be less than {lt}',
        lessThanOrEqualTo: '{description} must be less than or equal to {lte}',
        otherThan: '{description} must be other than {value}',
        odd: '{description} must be odd',
        even: '{description} must be even',
        positive: '{description} must be positive',
        multipleOf: '{description} must be a multiple of {multipleOf}',
        date: '{description} must be a valid date',
        phone: '{description} must be a valid phone number',
        url: '{description} must be a valid url'
    });

    exports.default = Messages.create();
});