define('im-webapp/routes/protected/initiative', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function model(params) {

            return Ember.RSVP.hash({
                initiative: this.get('store').findRecord('initiative', params.id, { include: 'status,followers,comments,timelineStatuses' }),
                tags: this.get('store').findAll('tag'),
                categories: this.get('store').findAll('category'),
                status: this.get('store').findAll('status'),
                tabs: [{
                    label: 'idea.ideas',
                    link: 'protected.initiative.index'
                }, {
                    label: 'comments.comments',
                    link: 'protected.initiative.comments'
                }, {
                    label: 'media.media',
                    link: 'protected.initiative.media'
                }, {
                    label: 'initiative.feed',
                    link: 'protected.initiative.feeds'
                }]
            });
        },
        actions: {
            loading: function loading() {
                this.controllerFor('protected').set('isLoading', true);
                this.router.on('didTransition', this, function () {
                    this.controllerFor('protected').set('isLoading', false);
                });
            }
        }
    });
});