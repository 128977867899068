define('im-webapp/models/message', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    from: (0, _relationships.belongsTo)('user'),
    to: (0, _relationships.belongsTo)('user'),
    content: (0, _attr.default)('string'),
    read: (0, _attr.default)('boolean'),
    created: (0, _attr.default)('date')
  });
});