define('im-webapp/controllers/protected/messages', ['exports', 'im-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        momentFormat: _environment.default.APP.FULL_MOMENT_FORMAT,
        ws: Ember.inject.service('ws'),

        init: function init() {
            this._super.apply(this, arguments);
            this.get('ws');
        },


        messages: Ember.computed('model', function () {
            return this.get('model') ? this.get('model') : [];
        }),

        messagesObserver: Ember.observer('ws.newMessage', function () {
            if (this.get('ws.newMessage')) {
                var newMessage = this.get('ws.newMessage');
                var messages = this.get('messages');
                var newConversation = true;

                messages = messages.map(function (msg) {
                    if (msg.user == newMessage.user) {
                        newConversation = false;
                        return Object.assign({}, newMessage);
                    }
                    return msg;
                });

                if (newConversation) {
                    messages.unshift(newMessage);
                }

                messages.sort(function (a, b) {
                    return new Date(b.created) - new Date(a.created);
                });

                this.set('messages', messages);
            }
        })

    });
});