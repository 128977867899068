define('im-webapp/components/top-bar', ['exports', 'im-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['top-bar'],
    classNameBindings: ['narrowSidebar:top-bar--narrow'],
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    momentFormat: _environment.default.APP.MOMENT_FORMAT,
    customAjax: Ember.inject.service('custom-ajax'),
    i18n: Ember.inject.service('i18n'),
    toast: Ember.inject.service('toast'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('narrowSidebar', Ember.$(window).width() < 992);
    },


    actions: {
      logout: function logout() {
        this.get('store').unloadAll();
        localStorage.clear();
        this.get('session').invalidate();
      },

      resetNotificationNumber: function resetNotificationNumber() {
        var self = this;
        var userId = this.get('session.data.authenticated.user_id');
        this.get('customAjax').request('GET', _environment.default.APP.API_HOST + '/check-notification/' + userId);

        this.get('store').query('notification', {
          belongs: userId
        }).then(function (userNotifications) {
          self.set('notifications', userNotifications);
          self.get('ws').setupProperty('unreadNotifications', 0);
        });
      },
      resetMessagesNotification: function resetMessagesNotification() {
        var i18n = this.get('i18n');
        var toast = this.get('toast');
        var userId = this.get('session.data.authenticated.user_id');
        var self = this;
        var url = _environment.default.APP.API_HOST + "/get-messages-conversation/" + userId;

        this.get('customAjax').request('GET', url).then(function (conversations) {
          self.set('lastConversations', conversations);
          self.get('ws').setupProperty('unreadMessages', 0);
        }).catch(function () {
          toast.error(i18n.t('error.server'), i18n.t('general.error'));
        });
      }
    }
  });
});