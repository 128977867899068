define('im-webapp/routes/protected/idea', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function model(params) {

            return Ember.RSVP.hash({
                idea: this.get('store').findRecord('initiative', params.id, { include: 'status,followers,comments,likes,dislikes,timelineStatuses' }),
                ideaTimelineStatuses: this.get('store').query('timeline-status', { 'initiative': params.id, include: 'status' }),
                tabs: [{
                    label: 'idea.idea_feed',
                    link: 'protected.idea.index'
                }, {
                    label: 'comments.comments',
                    link: 'protected.idea.comments'
                }, {
                    label: 'media.media',
                    link: 'protected.idea.media'
                }]
            });
        },
        actions: {
            loading: function loading() {
                this.controllerFor('protected').set('isLoading', true);
                this.router.on('didTransition', this, function () {
                    this.controllerFor('protected').set('isLoading', false);
                });
            }
        }
    });
});