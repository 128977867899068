define('im-webapp/components/activity-feed', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['activity-feed'],
        classNameBindings: ['narrowFeed:activity-feed--narrow'],

        isOutside: false,

        init: function init() {
            this._super.apply(this, arguments);
            this.set('narrowFeed', true);

            this.handleDown = this.handleDown.bind(this);
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            document.addEventListener('mousedown', this.handleDown, true);
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            document.removeEventListener('mousedown', this.handleDown, true);
        },
        handleDown: function handleDown(e) {

            if (this.isDestroyed || this.isDestroying) {
                return;
            }

            if (!this.element.contains(e.target) && !this.narrowFeed) {
                this.set('narrowFeed', true);
            }
        }
    });
});