define('im-webapp/components/regular-box', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['regular-box-wrapper'],

    init: function init() {
      this._super.apply(this, arguments);

      Ember.$(window).scroll(function () {
        Ember.$("form").css("opacity", 1 - Ember.$(window).scrollTop() / 250);
      });
    }
  });
});