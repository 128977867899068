define('im-webapp/services/route-crud', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        store: Ember.inject.service(),

        getRecord: function getRecord(params, modelName, propertyName, include) {

            var record = {};
            var randomId = null;
            var property = propertyName || 'id';

            if (params[property] === 'new') {
                randomId = Math.floor(Math.random() * 10000) + 1000;
                record = this.get('store').createRecord(modelName, {
                    id: randomId
                });
            } else {

                if (include) {
                    record = this.get('store').findRecord(modelName, params[property], { include: include });
                } else {
                    record = this.get('store').findRecord(modelName, params[property]);
                }
            }

            return {
                record: record,
                randomId: randomId
            };
        }
    });
});