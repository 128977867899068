define('im-webapp/components/tag-form', ['exports', 'im-webapp/validations/tag'], function (exports, _tag) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['form'],
        tagValidation: _tag.default,
        tag: null,

        actions: {
            save: function save(changeset) {

                var changes = changeset.get('changes');
                changeset.validate();
                var isValid = changeset.get('isValid');
                var tag = this.get('tag');
                var self = this;

                if (isValid) {
                    changes.forEach(function (change) {
                        tag.set(change.key, change.value);
                    });
                    self.sendAction('save', tag);
                }
            },
            rollback: function rollback(changeset) {
                var tag = this.get('tag');
                this.get('closeModal')(tag);
                return changeset.rollback();
            }
        }
    });
});