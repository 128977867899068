define('im-webapp/components/recovery-form', ['exports', 'im-webapp/validations/recover-password', 'im-webapp/config/environment'], function (exports, _recoverPassword, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        customAjax: Ember.inject.service('customAjax'),
        classNames: ['login-form'],
        queryParams: ['hash'],
        toast: Ember.inject.service(),
        i18n: Ember.inject.service(),

        recoveryPassValidation: _recoverPassword.default,

        init: function init() {
            this._super.apply(this, arguments);
            this.set('recovery', {
                password: '',
                passwordConfirmation: ''
            });
        },


        actions: {
            confirmNewPassword: function confirmNewPassword(changeset) {
                var toast = this.get('toast');
                var i18n = this.get('i18n');
                changeset.validate();
                var isValid = changeset.get('isValid');
                var self = this;

                if (isValid) {
                    var hash = this.get('hash');

                    var url = _environment.default.APP.API_HOST + "/reset-password/" + hash;
                    var data = { "recovery": { "password": changeset.get('password'), "confirmPassword": changeset.get('passwordConfirmation') } };

                    var customAjax = self.get('customAjax');

                    customAjax.request('POST', url, data).then(function () {
                        toast.success(i18n.t('general.successful'), i18n.t('general.successful'));
                    }).catch(function () {
                        toast.error(i18n.t('error.server'), i18n.t('general.error'));
                    });
                }
            }
        }
    });
});