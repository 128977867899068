define('im-webapp/components/models-table/row-sorting', ['exports', 'ember-models-table/components/models-table/row-sorting'], function (exports, _rowSorting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _rowSorting.default;
    }
  });
});