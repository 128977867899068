define('im-webapp/components/side-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['side-bar'],
    classNameBindings: ['narrowSidebar:side-bar--narrow'],
    init: function init() {
      this._super.apply(this, arguments);
      this.defaultMenu();
    },
    defaultMenu: function defaultMenu() {
      var self = this;
      this.set('narrowSidebar', self.smallScreen());

      Ember.$(window).on('resize', function () {
        Ember.run(function () {
          self.set('narrowSidebar', self.smallScreen());
        });
      });
    },
    smallScreen: function smallScreen() {
      return Ember.$(window).width() < 992;
    },

    actions: {
      toggleSidebar: function toggleSidebar() {
        this.get('toggleSidebar')();
      }
    }
  });
});