define('im-webapp/components/high-charts', ['exports', 'ember-highcharts/components/high-charts'], function (exports, _highCharts) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _highCharts.default.extend({
    i18n: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),

    init: function init() {
      this._super.apply(this, arguments);
      this.get('eventBus').subscribe('chooseLanguage', this, this.contentDidChange);
    },


    /**
     * Event that fires on change of underlying data for chart and renders it with new data
     * @params {object} chartData
     */
    contentDidChange: Ember.observer('content', function () {
      var chartOptions = this.get('chartOptions');
      var chart = this.get('chart');

      if (chartOptions.chart.type === 'column' || chartOptions.chart.type === 'area') {
        var yAxis = '';

        if (Array.isArray(chartOptions.yAxis)) {
          yAxis = chartOptions.yAxis[0];
        } else {
          yAxis = chartOptions.yAxis;
        }

        chart.yAxis[0].axisTitle.attr({
          text: yAxis.title.text
        });
      }
      var xAxis = this.chartOptions.xAxis;
      //check does categories on xAxis exists
      var arrayOfCategories = [];
      if (xAxis) {
        arrayOfCategories = xAxis.categories;
      }
      this.get('chart').redraw();
      // Check if chart has been initialised
      if (this.get('chart')) {

        var _chart = this.get('chart');
        _chart.xAxis[0].setCategories(arrayOfCategories);
        _chart.redraw();
      }
    })

  });
});