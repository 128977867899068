define('im-webapp/components/single-notification', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var self = this;
      var timeout = void 0;
      var notification = this.get('notification');

      function removeNotification() {
        timeout = setTimeout(function () {
          self.send('closeNotification', notification);
        }, 5000);
        self.set('timeout', timeout);
      }

      Ember.run(function () {
        Ember.run.scheduleOnce('afterRender', this, removeNotification);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var timeout = this.get('timeout');
      clearTimeout(timeout);
    },


    actions: {
      closeNotification: function closeNotification(notification) {
        this.sendAction('closeNotification', notification);
      }
    }
  });
});