define('im-webapp/components/notifications-list', ['exports', 'im-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    momentFormat: _environment.default.APP.FULL_MOMENT_FORMAT,
    classNames: ['notification-list']
  });
});