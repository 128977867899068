define('im-webapp/components/status-form', ['exports', 'im-webapp/validations/status'], function (exports, _status) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['form'],
        statusValidation: _status.default,
        status: null,

        actions: {
            save: function save(changeset) {

                var changes = changeset.get('changes');
                changeset.validate();
                var isValid = changeset.get('isValid');
                var status = this.get('status');
                var self = this;

                if (isValid) {
                    changes.forEach(function (change) {
                        status.set(change.key, change.value);
                    });

                    self.sendAction('save', status);
                }
            },
            rollback: function rollback(changeset) {
                var status = this.get('status');
                this.get('closeModal')(status);
                return changeset.rollback();
            }
        }
    });
});