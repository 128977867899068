define('im-webapp/locales/it/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'general': {
      'send': 'Invia',
      'email': 'Indirizzo email',
      'confirm': 'Conferma',
      'save': 'Salva',
      'delete': 'Elimina',
      'cancel': 'Annulla',
      'error': "Errore",
      'invalid': "Invalido",
      'successful': "Riuscito",
      'active': "Attivo",
      'homepage': 'Homepage',
      'are_you_sure': 'Sei sicuro di voler eliminare',
      'search': 'Ricerca',
      'add': 'Aggiungi',
      'edit': 'Modifica',
      'period': 'Periodo',
      'welcome': 'Benvenuti',
      'title': 'Titolo',
      'desc': 'Descrizione',
      'categories': 'Categorie',
      'tags': 'Insegne',
      'date': 'Data',
      'av_rate': 'Voto medio',
      'read_more': 'Leggi di più',
      'read_less': 'Leggi di meno',
      'close': 'Chiudi',
      'follow': 'Segui',
      'unfollow': 'Non seguire',
      'prev': 'Precedentemente',
      'next': 'Seguente',
      'records_per_page': 'Record per pagina',
      'current_page': 'Pagina attuale',
      'show': 'Mostra',
      'of': 'di',
      'columns': 'Colonne',
      'show_all': 'Mostra tutto',
      'hide_all': 'Nascondi tutto',
      'restore_defaults': 'Ripristina impostazioni predefinite',
      'no_data': 'Nessun record da mostrare',
      'see_all': 'Visualizza tutto',
      'home': 'Casa'
    },
    'auth': {
      'username': 'Nome utente',
      'password': 'Password',
      'forgot_password': 'Hai dimenticato la password',
      'login': 'Accedi',
      'logout': 'Esci',
      'back_to_login': 'Torna all\'acesso',
      'confirm_password': 'Conferma password',
      'recovery_password': 'Ripristinamento della password dimenticata',
      'password_changed': 'Password modificata',
      'change_password': 'Cambia password',
      'new_password': 'Nuova password',
      'successful_update_password': 'Password modificata correttamente',
      'password_is_incorrect': 'Password non corretta',
      'remember_me': 'Salva i miei dati',
      'email_with_instructions': 'Il messaggio con le istruzioni è stato inviato al tuo indirizzo e-mail'
    },
    'error': {
      'bad_request': 'Richiesta non corretta',
      'resource_not_found': 'Nessuna risorsa trovata',
      'unauthorized': 'Non sei autorizzato',
      'no_permission': 'Non sei autorizzato a eseguire questa azione',
      'not_allowed': 'Questa azione non è consentita',
      'time_out': 'Richiedi interruzione',
      'too_many': 'Troppe richieste simultanee',
      'server': 'Errore del server',
      'refresh_token': 'Il token di accesso non può essere ripristinato',
      'start_end_period': 'Seleziona le date di inizio e fine',
      'start_before_expired': 'La data di fine deve essere successiva alla data di inizio'
    },
    'notify': {
      'updated': "modificato con successo",
      'added': "aggiunto con successo",
      'deleted': "Record eliminato correttamente",
      'tags': 'Insegna',
      'categories': 'Categoria',
      'users': 'Utente',
      'companies': 'Società',
      'statuses': 'Stato',
      'groups': 'Gruppa',
      'initiatives': 'Iniziativa',
      'idea': 'Idea',
      'comments': 'Commento',
      'timeline-statuses': 'Stato del periodo'
    },
    'category': {
      'category': 'Categoria',
      'category_management': 'Gestione delle categorie',
      'name': 'Nome della categoria',
      'desc': 'Descrizione della categoria',
      'add_category': 'Aggiungi categoria',
      'select_categories': 'Seleziona categoria...'
    },
    'tag': {
      'tag': 'Insegna',
      'tag_management': 'Gestione delle insegne',
      'name': 'Nome della insegna',
      'add_tag': 'Aggiungi insegna',
      'select_tags': 'Seleziona insegne...'
    },
    'user': {
      'user': 'Utente',
      'first_name': 'Nome',
      'last_name': 'Cognome',
      'full_name': 'Nome e cognome',
      'group': 'Gruppo',
      'my_profile': 'Il mio profilo',
      'my_ideas': 'Le mie idee',
      'my_initiatives': 'Le mie iniziative',
      'my_feed': 'Il mio feed',
      'user_management': 'Gestione degli utenti',
      'roles': 'Ruoli dell\'utente',
      'add_user': 'Aggiungi utente',
      'admin_role': 'Ruolo dell\'amministratore',
      'user_role': 'Ruolo dell\'utente',
      'active': 'Attivo',
      'inactive': 'Non attivo'
    },
    'group': {
      'group': 'Gruppo',
      'group_management': 'Gestione dei gruppi',
      'name': 'Nome del gruppo',
      'desc': 'Descrizione del gruppo',
      'add_group': 'Aggiungi gruppo'
    },
    'status': {
      'status': 'Stato',
      'status_management': 'Gestione degli stati',
      'name': 'Nome dello stato',
      'desc': 'Descrizione dello stato',
      'active': 'Stato attivo',
      'add_status': 'Aggiungi stato',
      'color': 'Colore dello stato',
      'select_statuses': 'Seleziona stati...',
      'expire_date': 'Data di fine',
      'start_date': 'Data d\'inizio',
      'voting': 'Stato della votazione',
      'final_status': 'Stato finale',
      'pending': 'Attesa',
      'approved': 'Approvato',
      'declined': 'Rifiutato'
    },
    'initiative': {
      'initiative': 'Iniziativa',
      'initiative_management': 'Gestione delle iniziative',
      'title': 'Titolo dell\'iniziativa',
      'desc': 'Descrizione dell\'iniziativa',
      'categories': 'Categorie delle iniziative',
      'tags': 'Insegne delle iniziative',
      'add_initiative': 'Aggiungi iniziativa',
      'follow_initiative': 'Segui iniziativa',
      'unfollow_initiative': 'Non seguire più iniziativa',
      'followers': 'Follower',
      'latest': 'Le ultime iniziative',
      'available_statuses': 'Stati disponibili',
      'current_status': 'Stato attuale',
      'unrated': 'senza punteggio',
      'like': 'Iniziative piaciute',
      'created': 'Iniziative create',
      'follow': 'Iniziative seguite',
      'unfollow': 'Non seguire iniziativa',
      'comment': 'iniziative commentate',
      'dislike': 'iniziative non piaciute più',
      'attachment': 'allegato caricato all\'iniziativa',
      'feed': 'Notizie sulle iniziative'
    },
    'idea': {
      'idea': 'Idea',
      'ideas': 'idee',
      'idea_management': 'Gestione delle idee',
      'add_idea': 'Aggiungi idea',
      'follow_idea': 'Segui idea',
      'unfollow_idea': 'Non seguire più idea',
      'idea_feed': 'Notizie sulle idee',
      'like': 'Idea piaciuta',
      'created': 'Idea creata',
      'follow': 'Ha seguito idea',
      'unfollow': 'l\'idea non seguita più',
      'comment': 'ha commentato idea',
      'dislike': 'Idea non piaciuta più',
      'attachment': 'ha caricato allegato all\'idea',
      'feed': 'notizie sulle idee'
    },
    'upload': {
      'drag_to_upload': 'Trascina per caricare',
      'drag_and_drop_area': 'Area di trascinamento con mouse',
      'upload_new_file': 'Carica un nuovo file'
    },
    'comments': {
      'comments': 'Commenti',
      'comment': 'Commento',
      'required_field': 'Il commento non può essere vuoto',
      'comment_text': 'Pubblica il tuo commento...'
    },
    'media': {
      'media': 'I media',
      'no_media_added': 'Nessun media aggiunti',
      'media_library': 'Libreria multimediale',
      'media_avatar': 'Visualizzazione dei file',
      'media_name': 'Nome del media',
      'media_author': 'Autore',
      'media_actions': 'Azioni',
      'initiative_idea': 'Iniziativa / idea'
    },
    'languages': {
      'language': 'Lingua',
      'en': 'En',
      'rs': 'Sr',
      'el': 'El',
      'es': 'Es',
      'fr': 'Fr',
      'it': 'It',
      'pt': 'Pt',
      'ru': 'Ru'
    },
    'messages': {
      'messages': 'Messaggi',
      'type_message': 'Scrivi messaggio...',
      'new_message': 'Nuovo messaggio',
      'welcome': 'Benvenuti nei messaggi!',
      'select_user': 'Seleziona utente'
    },
    'notifications': {
      'notifications': 'Notifiche'
    },
    'image': {
      'change_image': 'Cambia immagine',
      'confirm_image_note': 'Se vuoi conservare questa immagine, si prega di confermare.'
    },
    'like': {
      'likes': 'Like',
      'dislikes': 'Dislike',
      'voting_finished': 'La votazione è finita'
    },
    'validations': {
      'password_must_match': 'La password deve corrispondere',
      'email': 'Deve essere un indirizzo email valido',
      'not_a_number': 'Deve essere un numero',
      'present': 'Non può essere vuoto',
      'too_short': 'Troppo breve (richiede almeno [min] caratteri)',
      'invalid': 'Invalido',
      'password_visible': 'Nascondi la password',
      'password_invisible': 'Mostra la password'
    },
    'table': {
      'title': 'Titolo',
      'edit': 'Modifica',
      'delete': 'Elimina',
      'fullname': 'Nome e cognome',
      'name': 'Nome',
      'desc': 'Descrizione',
      'tag': 'Insegna',
      'status': 'Stato'
    },
    'slider': {
      'empty_slider': 'Nessun iniziativa'
    },
    'timeline': {
      'already_selected_days': 'Hai già selezionato questi giorni',
      'change_available_days': 'Si prega di modificare prima gli altri stati e abilita questi giorni'
    },
    'activity': {
      'like': 'piaciuto',
      'created': 'creato',
      'follow': 'seguito',
      'unfollow': 'non seguito più',
      'comment': 'ha commentato',
      'dislike': 'non piaciuto più',
      'attachment': 'Allegato aggiunto',
      'status changed': 'Stato cambiato',
      'delete': 'elimina',
      'remove like': 'like rimosso',
      'remove dislike': 'dislike rimosso',
      'remove comment': 'commento rimosso',
      'remove attachment': 'allegato rimosso',
      'feed': 'Notizie delle attività',
      'my_feed': 'Il mio feed'
    },
    'statistics': {
      'statistics': 'Statistiche',
      'ideas_per_status': 'Idee per stato',
      'initiatives_per_status': 'Iniziative per stato',
      'number_of_ideas': 'Numero di idee',
      'number_of_initiatives': 'Numero di iniziative',
      'initiatives_per_idea': 'Iniziative per numero di idee',
      'likes_dislikes_chart': 'Like, dislike, follower e commenti',
      'likes': 'Like',
      'dislikes': 'Dislike',
      'comments': 'Commenti',
      'followers': 'Follower',
      'total_number': 'Numero totale'
    },
    'message': {
      'new': 'invia un nuovo messaggio'
    }
  };
});