define('im-webapp/locales/es/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'general': {
      'send': 'Enviar',
      'email': 'Correo electrónico',
      'confirm': 'Confirmar',
      'save': 'Guardar',
      'delete': 'Borrar',
      'cancel': 'Cancelar',
      'error': "Error",
      'invalid': "No válido",
      'successful': "Con éxito",
      'active': "Activo",
      'homepage': 'Página de inicio',
      'are_you_sure': '¿Está seguro de que desea borrar? ',
      'search': 'Búsqueda',
      'add': 'Añadir',
      'edit': 'Editar',
      'period': 'Periodo',
      'welcome': 'Bienvenido',
      'title': 'Título',
      'desc': 'Descripción',
      'categories': 'Categorías',
      'tags': 'Etiquetas',
      'date': 'Fecha',
      'av_rate': 'Promedio',
      'read_more': 'Leer más',
      'read_less': 'Leer menos',
      'close': 'Cerrar',
      'follow': 'Seguir',
      'unfollow': 'Dejar de seguir',
      'prev': 'Previo',
      'next': 'Siguiente',
      'records_per_page': 'Registros por página',
      'current_page': 'Página actual',
      'show': 'Mostrar',
      'of': 'de',
      'columns': 'Columnas',
      'show_all': 'Mostrar todo',
      'hide_all': 'Ocultar todo',
      'restore_defaults': 'Restablecer los valores predeterminados',
      'no_data': 'No hay registros para mostrar',
      'see_all': 'Ver todo',
      'home': 'Inicio'
    },
    'auth': {
      'username': 'Nombre de usuario',
      'password': 'Contraseña',
      'forgot_password': 'Contraseña olvidada',
      'login': 'Iniciar sesión',
      'logout': 'Cerrar sesión',
      'back_to_login': 'Volver al inicio de sesión',
      'confirm_password': 'Confirmar la contraseña',
      'recovery_password': 'Recuperar la contraseña',
      'password_changed': 'Contraseña cambiada',
      'change_password': 'Cambiar la contraseña',
      'new_password': 'Contraseña nueva',
      'successful_update_password': 'Contraseña actualizada con éxito',
      'password_is_incorrect': 'Contraseña incorrecta',
      'remember_me': 'Guardar',
      'email_with_instructions': 'El mensaje con las instrucciones se ha enviado a su correo electrónico'
    },
    'error': {
      'bad_request': 'Solicitud incorrecta',
      'resource_not_found': 'Recurso no encontrado',
      'unauthorized': 'No tiene autorización',
      'no_permission': 'No tiene permiso para esta acción',
      'not_allowed': 'Acción no permitida',
      'time_out': 'Solicitud ha expirado',
      'too_many': 'Demasiadas solicitudes al mismo tiempo',
      'server': 'Error del servidor',
      'refresh_token': 'El acceso no puede refrescarse',
      'start_end_period': 'Por favor, elija las fechas de inicio y finalización',
      'start_before_expired': 'Fecha de finalización debe ser posterior a la fecha de inicio'
    },
    'notify': {
      'updated': "actualizado con éxito",
      'added': " añadido con éxito",
      'deleted': "Registro borrado con éxito",
      'tags': 'Etiqueta',
      'categories': 'Categoría',
      'users': 'Usuario',
      'companies': 'Compañía',
      'statuses': 'Estado',
      'groups': 'Grupo',
      'initiatives': 'Iniciativa',
      'idea': 'Idea',
      'comments': 'Comentario',
      'timeline-statuses': 'Estado de periodo'
    },
    'category': {
      'category': 'Categoría',
      'category_management': 'Gestión por categorías',
      'name': 'Nombre de categoría',
      'desc': 'Descripción de categoría',
      'add_category': 'Añadir categoría',
      'select_categories': 'Seleccionar categoría…'
    },
    'tag': {
      'tag': 'Etiqueta',
      'tag_management': 'Gestionar de etiquetas',
      'name': 'Nombre de etiqueta',
      'add_tag': 'Añadir etiqueta',
      'select_tags': 'Seleccionar etiqueta…'
    },
    'user': {
      'user': 'Usuario',
      'first_name': 'Nombre',
      'last_name': 'Apellido',
      'full_name': 'Nombre y apellido',
      'group': 'Grupo',
      'my_profile': 'Mi perfil',
      'my_ideas': 'Mis ideas',
      'my_initiatives': 'Mis iniciativas',
      'my_feed': 'Mis noticias',
      'user_management': 'Gestión de usuarios',
      'roles': 'Roles de usuarios',
      'add_user': 'Añadir usuario',
      'admin_role': 'Rol de administrador',
      'user_role': 'Rol de usuario',
      'active': 'Activo',
      'inactive': 'No activo'
    },
    'group': {
      'group': 'Grupo',
      'group_management': 'Gestión de grupos',
      'name': 'Nombre del grupo',
      'desc': 'Descripción del grupo',
      'add_group': 'Añadir grupo'
    },
    'status': {
      'status': 'Estado',
      'status_management': 'Gestión de estado',
      'name': 'Nombre de estado',
      'desc': 'Descripción de estado',
      'active': 'Estado activo',
      'add_status': 'Añadir estado',
      'color': 'Color de estado',
      'select_statuses': 'Seleccionar estado',
      'expire_date': 'Fecha de finalización',
      'start_date': 'Fecha de inicio',
      'voting': 'Estado de votación',
      'final_status': 'Estado final',
      'pending': 'Pendiente',
      'approved': 'Aprobado',
      'declined': 'Denegado'
    },
    'initiative': {
      'initiative': 'Iniciativa',
      'initiative_management': 'Gestión de iniciativas',
      'title': 'Título de la iniciativa',
      'desc': 'Descripción de iniciativa',
      'categories': 'Categorías de iniciativa',
      'tags': 'Etiquetas de iniciativa',
      'add_initiative': 'Añadir iniciativa',
      'follow_initiative': 'Seguir iniciativa',
      'unfollow_initiative': 'Dejar de seguir iniciativa',
      'followers': 'Seguidores',
      'latest': 'Las últimas iniciativas',
      'available_statuses': 'Los estados disponibles',
      'current_status': 'Estado actual',
      'unrated': 'Sin calificar',
      'like': 'iniciativas gustadas',
      'created': 'iniciativas creadas',
      'follow': 'iniciativas seguidas',
      'unfollow': 'iniciativas no seguidas',
      'comment': 'iniciativas comentadas',
      'dislike': 'iniciativas no gustadas',
      'attachment': 'Añadir archivo adjunto de iniciativa',
      'feed': 'Notificaciones de iniciativas'
    },
    'idea': {
      'idea': 'Idea',
      'ideas': 'Ideas',
      'idea_management': 'Gestión de ideas',
      'add_idea': 'Añadir ideas',
      'follow_idea': 'Seguir idea',
      'unfollow_idea': 'Dejar de seguir idea',
      'idea_feed': 'Notificaciones de ideas',
      'like': 'ideas gustadas',
      'created': 'ideas creadas',
      'follow': ' ideas seguidas',
      'unfollow': 'Ideas no seguidas',
      'comment': 'comentarios sobre ideas',
      'dislike': 'ideas no gustadas',
      'attachment': 'Adjuntar archivo de idea',
      'feed': 'Notificaciones de ideas'
    },
    'upload': {
      'drag_to_upload': 'Arrastre para subir',
      'drag_and_drop_area': 'Área de arrastrar y soltar',
      'upload_new_file': 'Subir archivo nuevo'
    },
    'comments': {
      'comments': 'Comentarios',
      'comment': 'Comentario',
      'required_field': 'El campo Comentario no puede quedar vacío',
      'comment_text': 'Escribir un comentario…'
    },
    'media': {
      'media': 'Media',
      'no_media_added': 'No hay media añadidas',
      'media_library': 'Biblioteca multimedia',
      'media_avatar': 'Vista previa de archivo',
      'media_name': 'Nombre de media',
      'media_author': 'Autor',
      'media_actions': 'Acciones',
      'initiative_idea': 'Iniciativa/idea'
    },
    'languages': {
      'language': 'Idioma',
      'en': 'En',
      'rs': 'Sr',
      'el': 'El',
      'es': 'Es',
      'fr': 'Fr',
      'it': 'It',
      'pt': 'Pt',
      'ru': 'Ru'
    },
    'messages': {
      'messages': 'Mensajes',
      'type_message': 'Escribir un mensaje…...',
      'new_message': 'Mensaje nuevo',
      'welcome': '¡Bienvenido a los mensajes!!',
      'select_user': 'Seleccionar usuario'
    },
    'notifications': {
      'notifications': 'Notificaciones'
    },
    'image': {
      'change_image': 'Cambiar la imagen',
      'confirm_image_note': 'Si desea guardar esta imagen, por favor, confirme.'
    },
    'like': {
      'likes': 'Me gusta',
      'dislikes': 'No me gusta',
      'voting_finished': 'Votación finalizada'
    },
    'validations': {
      'password_must_match': 'Las contraseñas deben coincidir',
      'email': 'Debe ser la dirección de correo electrónico válida',
      'not_a_number': 'Debe contener números',
      'present': 'No puede quedar vacío',
      'too_short': 'Muy corto (el mínimo es [min] caracteres)',
      'invalid': 'No válido',
      'password_visible': 'Ocultar contraseña',
      'password_invisible': 'Mostrar contraseña'
    },
    'table': {
      'title': 'Título',
      'edit': 'Editar',
      'delete': 'Borrar',
      'fullname': 'Nombre completo',
      'name': 'Nombre',
      'desc': 'Descripción',
      'tag': 'Etiqueta',
      'status': 'Estado'
    },
    'slider': {
      'empty_slider': 'Sin iniciativas'
    },
    'timeline': {
      'already_selected_days': 'Ya ha seleccionado estas fechas',
      'change_available_days': ' Por favor, primero cambie otros estados y haga disponibles estas fechas'
    },
    'activity': {
      'like': 'gustado',
      'created': 'creado',
      'follow': 'seguido',
      'unfollow': 'no seguido',
      'comment': 'comentado',
      'dislike': 'no gustado',
      'attachment': 'archivo adjunto',
      'status changed': 'Estado ha sido cambiado',
      'delete': 'borrado',
      'remove like': 'Me gusta eliminado',
      'remove dislike': 'No me gusta eliminado',
      'remove comment': 'comentario eliminado',
      'remove attachment': 'archivo adjunto eliminado',
      'feed': 'Noticias sobre la actividades',
      'my_feed': 'Mis noticias'
    },
    'statistics': {
      'statistics': 'Estadísticas',
      'ideas_per_status': 'Ideas por estado',
      'initiatives_per_status': 'Iniciativas por estado',
      'number_of_ideas': 'Número de ideas',
      'number_of_initiatives': 'Número de iniciativas',
      'initiatives_per_idea': 'Iniciativas por número de ideas',
      'likes_dislikes_chart': 'Los me gusta, no me gusta, seguidores y comentarios',
      'likes': 'Los me gusta',
      'dislikes': 'Los no me gusta',
      'comments': 'Comentarios',
      'followers': 'Seguidores',
      'total_number': 'Número total'
    },
    'message': {
      'new': 'mensajes nuevos enviados'
    }
  };
});