define('im-webapp/routes/protected/group-management/group', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Route.extend({
        routeCrud: service('routeCrud'),

        model: function model(params) {
            var routeCrud = this.get('routeCrud');
            var group = routeCrud.getRecord(params, 'group');
            return Ember.RSVP.hash({
                group: group.record,
                randomId: group.randomId
            });
        }

    });
});