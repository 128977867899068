define('im-webapp/components/custom-pagination', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        classNames: ['custom-pagination'],

        itemsPerPage: Ember.computed('itemsPerPage', function () {
            return this.get('itemsPerPage') ? this.get('itemsPerPage') : 10;
        }),

        init: function init() {
            this._super.apply(this, arguments);
            this.set('currentPageNumber', 1);
            this.set('pageView', ['5', '10', '30']);
        },
        didReceiveAttrs: function didReceiveAttrs() {
            var numberOfPages = Math.ceil(this.totalItems / this.get('itemsPerPage'));
            this.set('numberOfPages', numberOfPages);
            this.generateNumbers(numberOfPages);
        },


        generateNumbers: function generateNumbers(lastNumber) {
            var arrayOfPages = [];
            var currentPageNumber = this.get('currentPageNumber');

            for (var i = 0; i < lastNumber; i++) {
                var value = i;

                arrayOfPages[i] = {
                    value: ++value,
                    active: currentPageNumber === value
                };
            }
            this.set('arrayOfPageNumbers', arrayOfPages);
        },

        actions: {
            fetchData: function fetchData(pageNumber, nextOrPrevious) {
                var itemsPerPage = this.get('itemsPerPage');
                var numberOfPages = this.get('numberOfPages');
                var currentPageNumber = this.get('currentPageNumber');
                var additionalFilters = this.get('additionalFilters') ? this.get('additionalFilters') : {};
                var self = this;
                var page = nextOrPrevious === 'previous' ? --currentPageNumber : nextOrPrevious === 'next' ? ++currentPageNumber : pageNumber;

                if (page < 1 || page > numberOfPages) {
                    return false;
                }

                self.set('currentPageNumber', page);
                additionalFilters.itemsPerPage = itemsPerPage;
                additionalFilters.page = page;

                this.get('store').query(this.get('resource'), additionalFilters).then(function (response) {
                    self.get('loadNewData')(response);
                });
            },
            changeDataDisplay: function changeDataDisplay(dataView) {
                var self = this;
                var additionalFilters = this.get('additionalFilters') ? this.get('additionalFilters') : {};
                additionalFilters.itemsPerPage = dataView;
                additionalFilters.page = 1;
                this.set('itemsPerPage', dataView);
                this.set('currentPageNumber', 1);

                this.get('store').query(this.get('resource'), additionalFilters).then(function (response) {
                    self.get('loadNewData')(response);
                });
            },
            loadNewData: function loadNewData(response) {
                this.get('loadNewData')(response);
            }
        }
    });
});