define('im-webapp/components/change-password-form', ['exports', 'im-webapp/validations/change-password', 'im-webapp/config/environment'], function (exports, _changePassword, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    toast: Ember.inject.service(),
    i18n: Ember.inject.service(),
    customAjax: Ember.inject.service('customAjax'),

    changePasswordValidation: _changePassword.default,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('changePassword', {
        password: '',
        newPassword: '',
        passwordConfirmation: ''
      });
    },


    actions: {
      changePassword: function changePassword(changeset) {
        var toast = this.get('toast');
        var i18n = this.get('i18n');
        changeset.validate();
        var isValid = changeset.get('isValid');
        var self = this;

        if (isValid) {
          var url = _environment.default.APP.API_HOST + "/change-password";
          var data = {
            "changePassword": {
              "password": changeset.get('password'),
              "newPassword": changeset.get('newPassword'),
              "confirmPassword": changeset.get('passwordConfirmation')
            }
          };

          var customAjax = self.get('customAjax');

          customAjax.request('POST', url, data).then(function () {
            self.sendAction('changePassword');
            self.set('wrongPassword', false);
            toast.success(i18n.t('auth.successful_update_password'), i18n.t('general.successful'));
          }).catch(function () {
            self.set('wrongPassword', i18n.t('auth.password_is_incorrect'));
          });
        }
      }
    }

  });
});