define('im-webapp/locales/ru/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'general': {
      'send': 'отправить',
      'email': 'Электронная почта',
      'confirm': 'Подтвердить',
      'save': 'Сохранить',
      'delete': 'Удалить',
      'cancel': 'Отмена',
      'error': "Ошибка ",
      'invalid': "Недействительно",
      'successful': "Успешно",
      'active': "Активно",
      'homepage': 'Главная страница',
      'are_you_sure': 'Вы уверены, что хотите удалить',
      'search': 'Поиск',
      'add': 'Добавление',
      'edit': 'Изменение',
      'period': 'Период',
      'welcome': 'Добро пожаловать',
      'title': 'Название',
      'desc': 'Описание',
      'categories': 'Категории',
      'tags': 'Знаки',
      'date': 'Дата',
      'av_rate': 'среднее значение',
      'read_more': 'Читать дальше',
      'read_less': 'Читать меньше',
      'close': 'Закрыть',
      'follow': 'Следовать',
      'unfollow': 'Прекратить мониторинг',
      'prev': 'Предварительно',
      'next': 'Следующее',
      'records_per_page': 'Записей на странице',
      'current_page': 'Текущая страница',
      'show': 'Показать',
      'of': 'от',
      'columns': 'Колонны',
      'show_all': 'Показать все',
      'hide_all': 'Скрыть все',
      'restore_defaults': 'Восстановить по умолчанию',
      'no_data': 'Нет записей для отображения',
      'see_all': 'Посмотреть все',
      'home': 'Начало'
    },
    'auth': {
      'username': 'Имя пользователя',
      'password': 'Пароль',
      'forgot_password': 'Забытый пароль',
      'login': 'Логин',
      'logout': 'Выйти ',
      'back_to_login': 'Вернуться к входу',
      'confirm_password': 'Подтверждение пароля',
      'recovery_password': 'Восстановление пароля',
      'password_changed': 'Пароль изменен',
      'change_password': 'Сменить пароль',
      'new_password': 'Новый пароль',
      'successful_update_password': 'Пароль успешно обновлен',
      'password_is_incorrect': 'Неверный пароль',
      'remember_me': 'Запомнить меня',
      'email_with_instructions': 'Сообщение с инструкцией отправлено на вашу электронную почту'
    },
    'error': {
      'bad_request': 'Неверный запрос',
      'resource_not_found': 'Ресурс не найден',
      'unauthorized': 'У вас нет полномочий',
      'no_permission': 'У вас нет разрешения на это действие',
      'not_allowed': 'Действие не разрешено',
      'time_out': 'Время ожидания запроса истекло',
      'too_many': 'Слишком много одновременных запросов',
      'server': 'Ошибка сервера',
      'refresh_token': 'Доступ не может быть обновлен',
      'start_end_period': 'Пожалуйста, выберите даты начала и окончания',
      'start_before_expired': 'Дата окончания должна быть позже даты начала'
    },
    'notify': {
      'updated': "успешно обновлено",
      'added': "успешно добавлено",
      'deleted': "Запись успешно удалена",
      'tags': 'Тег',
      'categories': 'Категория',
      'users': 'Пользователь',
      'companies': 'Компания',
      'statuses': 'Статус',
      'groups': 'Группа',
      'initiatives': 'Инициатива',
      'idea': 'Идея',
      'comments': 'Комментарий',
      'timeline-statuses': 'Статус периода'
    },
    'category': {
      'category': 'Категория',
      'category_management': 'Управление категориями',
      'name': 'Название категории',
      'desc': 'Описание категории',
      'add_category': 'Добавление категории',
      'select_categories': 'Выберите категорию ...'
    },
    'tag': {
      'tag': 'Тег',
      'tag_management': 'Управление тегами',
      'name': 'Название тега',
      'add_tag': 'Добавление тега',
      'select_tags': 'Выберите тег ...'
    },
    'user': {
      'user': 'Пользователь',
      'first_name': 'Имя',
      'last_name': 'Фамилия',
      'full_name': 'Имя и фамилия',
      'group': 'Группа',
      'my_profile': 'Мой профиль',
      'my_ideas': 'Мои идеи',
      'my_initiatives': 'Мои инициативы',
      'my_feed': 'Мои новости',
      'user_management': 'Управление пользователями ',
      'roles': 'Роли пользователей',
      'add_user': 'Добавление пользователей',
      'admin_role': 'администратор',
      'user_role': 'Роль пользователя',
      'active': 'Активно',
      'inactive': 'Неактивно'
    },
    'group': {
      'group': 'Группа',
      'group_management': 'Управление группами',
      'name': 'Название  группы',
      'desc': 'Описание группы',
      'add_group': 'Добавление группы'
    },
    'status': {
      'status': 'Статус',
      'status_management': 'Управление статусами',
      'name': 'Наименование статуса',
      'desc': 'Описание статуса',
      'active': 'Активный статус',
      'add_status': 'Добавление статуса',
      'color': 'Цвет статуса',
      'select_statuses': 'Выберите статусы ...',
      'expire_date': 'Дата окончания',
      'start_date': 'Дата начала',
      'voting': 'Статус голосования',
      'final_status': 'Окончательный статус',
      'pending': 'Ожидание',
      'approved': 'Утверждено ',
      'declined': 'Отклонено'
    },
    'initiative': {
      'initiative': 'Инициатива',
      'initiative_management': 'Управление инициативами',
      'title': 'Название инициативы',
      'desc': 'Описание инициативы',
      'categories': 'Категории инициатив',
      'tags': 'Тег нициативы',
      'add_initiative': 'Добавление инициативы',
      'follow_initiative': 'Следуй инициативе',
      'unfollow_initiative': 'Прекратить следовать инициативе',
      'followers': 'Подписчики',
      'latest': 'Недавние инициативы',
      'available_statuses': 'Доступные статусы',
      'current_status': 'Текущий статус',
      'unrated': 'Без рейтинга',
      'like': 'утвержденные инициативы',
      'created': '"созданная инициатива"',
      'follow': 'сопровождаемые инициативы инициативы',
      'unfollow': 'несопровождаемые инициативы',
      'comment': 'комментируемые инициативы',
      'dislike': 'нелюбимые инициативы',
      'attachment': 'добавление вклада в инициативу',
      'feed': 'новости об инициативе'
    },
    'idea': {
      'idea': 'Идея',
      'ideas': 'Идеи',
      'idea_management': 'Управление идеями',
      'add_idea': 'Добавление идеи',
      'follow_idea': 'Сопровождаемые идеи',
      'unfollow_idea': 'Несопровождаемые идеи ',
      'idea_feed': 'Новости об идеях',
      'like': 'Утвержденные идеи',
      'created': 'Созданные идеи ',
      'follow': 'Сопровождаемые идеи',
      'unfollow': 'Несопровождаемые идеи ',
      'comment': 'комментарии для идей',
      'dislike': 'идеи, которые не нравятся',
      'attachment': ' добавление вложения к идее',
      'feed': 'Новости об идее'
    },
    'upload': {
      'drag_to_upload': 'Перетащите, чтобы загрузить',
      'drag_and_drop_area': 'область перетаскивания мышью',
      'upload_new_file': 'Загрузить новый файл'
    },
    'comments': {
      'comments': 'Комментарии',
      'comment': 'Комментарий',
      'required_field': 'Комментарий не может быть пустым',
      'comment_text': 'Оставьте свой комментарий ...'
    },
    'media': {
      'media': 'Медиа ',
      'no_media_added': 'СМИ не добавлены',
      'media_library': 'Медиатека',
      'media_avatar': 'Предварительный просмотр файла',
      'media_name': 'Название медиа',
      'media_author': 'Автор',
      'media_actions': 'Акции',
      'initiative_idea': 'Инициатива / идея'
    },
    'languages': {
      'language': 'язык',
      'en': 'En',
      'rs': 'Sr',
      'el': 'El',
      'es': 'Es',
      'fr': 'Fr',
      'it': 'It',
      'pt': 'Pt',
      'ru': 'Ru'
    },
    'messages': {
      'messages': 'Сообщения',
      'type_message': 'Введите сообщение ...',
      'new_message': 'Новое сообщение',
      'welcome': 'Добро пожаловать в сообщения!',
      'select_user': 'Выберите пользователя'
    },
    'notifications': {
      'notifications': 'Уведомления'
    },
    'image': {
      'change_image': 'Изменить изображение',
      'confirm_image_note': 'Если вы хотите сохранить это изображение, пожалуйста, подтвердите.'
    },
    'like': {
      'likes': 'Мне это нравится',
      'dislikes': 'Мне это не нравится',
      'voting_finished': 'Голосование окончено'
    },
    'validations': {
      'password_must_match': 'Пароль должен совпадать',
      'email': 'Адрес электронной почты должен быть действующим ',
      'not_a_number': 'Должно быть число',
      'present': 'Не может быть пустым',
      'too_short': 'Слишком коротко (минимум [мин] символов)',
      'invalid': 'недействительный',
      'password_visible': 'Скрыть пароль',
      'password_invisible': 'Показать пароль'
    },
    'table': {
      'title': 'Название',
      'edit': 'Изменить изображение',
      'delete': 'Удалить',
      'fullname': 'Полное имя',
      'name': 'Имя',
      'desc': 'Описание',
      'tag': 'Тег',
      'status': 'Статус'
    },
    'slider': {
      'empty_slider': 'Нет инициатив'
    },
    'timeline': {
      'already_selected_days': 'Вы уже выбрали эти дни',
      'change_available_days': 'Пожалуйста, сначала измените другие статусы и включите эти дни'
    },
    'activity': {
      'like': 'нравится',
      'created': 'создано',
      'follow': 'сопровождающийся',
      'unfollow': 'не сопровождающийся',
      'comment': 'прокомментировано',
      'dislike': 'не нравится',
      'attachment': 'вложение добавлено',
      'status changed': 'Статус изменен',
      'delete': 'удалить',
      'remove like': 'удаленные лайки',
      'remove dislike': 'удаленный хейт',
      'remove comment': ' удален комментарий',
      'remove attachment': 'вложение удалено',
      'feed': 'Лента новостей',
      'my_feed': 'Мои новости'
    },
    'statistics': {
      'statistics': 'Статистика',
      'ideas_per_status': 'Идеи по статусу',
      'initiatives_per_status': 'Инициативы по статусу',
      'number_of_ideas': 'Количество идей',
      'number_of_initiatives': 'Количество инициатив',
      'initiatives_per_idea': 'Инициативы по количеству идей',
      'likes_dislikes_chart': 'Нравится, не нравится, подписчики и комментарии',
      'likes': 'Понравилось',
      'dislikes': 'Не понравилось:',
      'comments': 'Комментарии',
      'followers': 'Подписчики',
      'total_number': 'Общее количество'
    },
    'message': {
      'new': 'отправлено новых сообщений'
    }
  };
});