define('im-webapp/locales/en/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    // "some.translation.key": "Text for some.translation.key",
    //
    // "a": {
    //   "nested": {
    //     "key": "Text for a.nested.key"
    //   }
    // },
    //
    // "key.with.interpolation": "Text with {{anInterpolation}}"

    'general': {
      'send': 'Send',
      'email': 'Email',
      'confirm': 'Confirm',
      'save': 'Save',
      'delete': 'Delete',
      'cancel': 'Cancel',
      'error': "Error",
      'invalid': "Invalid",
      'successful': "Successful",
      'active': "Active",
      'homepage': 'Homepage',
      'are_you_sure': 'Are you sure you want to delete ',
      'search': 'Search',
      'add': 'Add',
      'edit': 'Edit',
      'period': 'Period',
      'welcome': 'Welcome',
      'title': 'Title',
      'desc': 'Description',
      'categories': 'Categories',
      'tags': 'Tags',
      'date': 'Date',
      'av_rate': 'avg.rate',
      'read_more': 'Read more',
      'read_less': 'Read less',
      'close': 'Close',
      'follow': 'Follow',
      'unfollow': 'Unfollow',
      'prev': 'Prev',
      'next': 'Next',
      'records_per_page': 'Records per page',
      'current_page': 'Current page',
      'show': 'Show',
      'of': 'of',
      'columns': 'Columns',
      'show_all': 'Show all',
      'hide_all': 'Hide all',
      'restore_defaults': 'Restore defaults',
      'no_data': 'No records to show',
      'see_all': 'See all',
      'home': 'Home'
    },
    'auth': {
      'username': 'Username',
      'password': 'Password',
      'forgot_password': 'Forgot password',
      'login': 'Login',
      'logout': 'Logout',
      'back_to_login': 'Back to login',
      'confirm_password': 'Confirm password',
      'recovery_password': 'Recovery password',
      'password_changed': 'Password changed',
      'change_password': 'Change password',
      'new_password': 'New password',
      'successful_update_password': 'Password updated successfully',
      'password_is_incorrect': 'Password is incorrect',
      'remember_me': 'Remember me',
      'email_with_instructions': 'Email with instructions is sent to your email'
    },
    'error': {
      'bad_request': 'Bad request',
      'resource_not_found': 'Resource not found',
      'unauthorized': 'Unauthorized',
      'no_permission': 'You don\'t have permission for this action',
      'not_allowed': 'Action is not allowed',
      'time_out': 'Request time out',
      'too_many': 'Too many request at the same time',
      'server': 'Server error',
      'refresh_token': 'Access token could not be refreshed',
      'start_end_period': 'Please select start and expire date',
      'start_before_expired': 'Expire date must be after start date'
    },
    'notify': {
      'updated': " successfully updated",
      'added': " successfully added",
      'deleted': "Record successfully deleted",
      'tags': 'Tag',
      'categories': 'Category',
      'users': 'User',
      'companies': 'Company',
      'statuses': 'Status',
      'groups': 'Group',
      'initiatives': 'Initiative',
      'idea': 'Idea',
      'comments': 'Comment',
      'timeline-statuses': 'Period status'
    },
    'category': {
      'category': 'Category',
      'category_management': 'Category management',
      'name': 'Category name',
      'desc': 'Category description',
      'add_category': 'Add category',
      'select_categories': 'Select categories...'
    },
    'tag': {
      'tag': 'Tag',
      'tag_management': 'Tag management',
      'name': 'Tag name',
      'add_tag': 'Add tag',
      'select_tags': 'Select tags...'
    },
    'user': {
      'user': 'User',
      'first_name': 'First name',
      'last_name': 'Last name',
      'full_name': 'Full name',
      'group': 'Group',
      'my_profile': 'My profile',
      'my_ideas': 'My ideas',
      'my_initiatives': 'My initiatives',
      'my_feed': 'My feed',
      'user_management': 'User management',
      'roles': 'User roles',
      'add_user': 'Add user',
      'admin_role': 'Admin role',
      'user_role': 'User role',
      'active': 'Active',
      'inactive': 'Inactive',
      'my_init_and_ideas': 'My initiatives and ideas statistics'
    },
    'group': {
      'group': 'Group',
      'group_management': 'Group management',
      'name': 'Group name',
      'desc': 'Group description',
      'add_group': 'Add group'
    },
    'status': {
      'status': 'Status',
      'status_management': 'Status management',
      'name': 'Status name',
      'desc': 'Status description',
      'active': 'Active status',
      'add_status': 'Add status',
      'color': 'Status color',
      'select_statuses': 'Select statuses...',
      'expire_date': 'Expire date',
      'start_date': 'Start date',
      'voting': 'Voting status',
      'final_status': 'Final status',
      'pending': 'Pending',
      'approved': 'Approved',
      'declined': 'Declined'
    },
    'initiative': {
      'initiative': 'Initiative',
      'initiative_management': 'Initiative management',
      'title': 'Initiative title',
      'desc': 'Initiative description',
      'categories': 'Initiative categories',
      'tags': 'Initiative tags',
      'add_initiative': 'Add initiative',
      'follow_initiative': 'Follow Initiative',
      'unfollow_initiative': 'Unfollow initiative',
      'followers': 'Followers',
      'latest': 'Latest initiatives',
      'available_statuses': 'Available statuses',
      'current_status': 'Current status',
      'unrated': 'Unrated',
      'like': 'liked initiative',
      'created': 'created initiative',
      'follow': 'followed initiative',
      'unfollow': 'unfollow initiative',
      'comment': 'commented initiative',
      'dislike': 'disliked initiative',
      'attachment': 'uploaded attachment on initiative',
      'feed': 'Initiative feed'
    },
    'idea': {
      'idea': 'Idea',
      'ideas': 'Ideas',
      'idea_management': 'Idea management',
      'add_idea': 'Add idea',
      'follow_idea': 'Follow idea',
      'unfollow_idea': 'Unfollow idea',
      'idea_feed': 'Idea feed',
      'like': 'liked Idea',
      'created': 'created idea',
      'follow': ' has followed idea',
      'unfollow': 'unfollowed idea',
      'comment': 'commented on idea',
      'dislike': 'disliked idea',
      'attachment': ' uploaded attachment on idea',
      'feed': 'Idea feed'
    },
    'upload': {
      'drag_to_upload': 'Drag to upload',
      'drag_and_drop_area': 'Drag and drop area',
      'upload_new_file': 'Upload new file'
    },
    'comments': {
      'comments': 'Comments',
      'comment': 'Comment',
      'required_field': 'Comment can\'t be blank',
      'comment_text': 'Give your comment...'
    },
    'media': {
      'media': 'Media',
      'no_media_added': 'No media added',
      'media_library': 'Media library',
      'media_avatar': 'File preview',
      'media_name': 'Media name',
      'media_author': 'Author',
      'media_actions': 'Actions',
      'initiative_idea': 'Initiative/idea'
    },
    'languages': {
      'language': 'Language',
      'en': 'En',
      'rs': 'Sr',
      'el': 'El',
      'es': 'Es',
      'fr': 'Fr',
      'it': 'It',
      'pt': 'Pt',
      'ru': 'Ru'
    },
    'messages': {
      'messages': 'Messages',
      'type_message': 'Type a message...',
      'new_message': 'New message',
      'welcome': 'Welcome to messages!',
      'select_user': 'Select user'
    },
    'notifications': {
      'notifications': 'Notifications'
    },
    'image': {
      'change_image': 'Change image',
      'confirm_image_note': 'If you want to keep this image please confirm.'
    },
    'like': {
      'likes': 'Likes',
      'dislikes': 'Dislikes',
      'voting_finished': 'Voting is finished'
    },
    'validations': {
      'password_must_match': 'Password must match',
      'email': 'Must be a valid email address',
      'not_a_number': 'Must be a number',
      'present': 'Can not be blank',
      'too_short': 'Too short (minimum is {min} characters)',
      'invalid': 'Invalid',
      'password_visible': 'Hide password',
      'password_invisible': 'Show password'
    },
    'table': {
      'title': 'Title',
      'edit': 'Edit',
      'delete': 'Delete',
      'fullname': 'Full name',
      'name': 'Name',
      'desc': 'Description',
      'tag': 'Tag',
      'status': 'Status'
    },
    'slider': {
      'empty_slider': 'No initiatives'
    },
    'timeline': {
      'already_selected_days': 'You have already selected these days',
      'change_available_days': ' Please first change other statuses and make these days available'
    },
    'activity': {
      'like': 'liked',
      'created': 'created',
      'follow': 'followed',
      'unfollow': 'unfollowed',
      'comment': 'commented on',
      'dislike': 'disliked',
      'attachment': 'added attachment',
      'status changed': 'Status has changed',
      'delete': 'delete',
      'remove like': 'removed like',
      'remove dislike': 'removed dislike',
      'remove comment': 'removed comment',
      'remove attachment': 'removed attachment',
      'feed': 'Activity feed',
      'my_feed': 'My feed'
    },
    'statistics': {
      'statistics': 'Statistics',
      'ideas_per_status': 'Ideas per status',
      'initiatives_per_status': 'Initiatives per status',
      'number_of_ideas': 'Number of ideas',
      'number_of_initiatives': 'Number of initiatives',
      'initiatives_per_idea': 'Initiatives per number of ideas',
      'likes_dislikes_chart': 'Likes, dislikes, followers and comments',
      'likes': 'Likes',
      'dislikes': 'Dislikes',
      'comments': 'Comments',
      'followers': 'Followers',
      'total_number': 'Total number'
    },
    'message': {
      'new': 'sent new message'
    }
  };
});