define('im-webapp/services/cover-photo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    setCoverPhoto: function setCoverPhoto() {
      var min = 0;
      var max = 29;
      var randNumber = Math.floor(Math.random() * (max - min + 1) + min);
      var storedImageLinks = JSON.parse(localStorage.getItem("im_img_links"));
      return storedImageLinks ? storedImageLinks[randNumber] : '/assets/images/default-cover.jpg';
    }
  });
});