define('im-webapp/components/search-bar', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['search-bar'],

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            var textInput = document.getElementById('search-bar-input');
            var timeout = void 0;
            var self = this;

            function nullTimeout() {
                timeout = null;
            }

            Ember.run(function () {
                Ember.run.scheduleOnce('afterRender', this, nullTimeout);
            });

            textInput.onkeydown = function () {
                clearTimeout(timeout);
                timeout = setTimeout(function () {
                    self.get('onsearch')(self.searchTerm);
                }, 800);
            };
        },


        actions: {
            search: function search(searchTerm) {
                if (searchTerm) {
                    this.get('onsearch')(searchTerm);
                }
            },
            reset: function reset() {
                this.set('searchTerm', null);
                this.get('onsearch')(null);
            }
        }
    });
});