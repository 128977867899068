define('im-webapp/components/regular-progress-status', ['exports', 'im-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['progress-status-wrapper'],
    momentFormat: _environment.default.APP.MOMENT_FORMAT,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var self = this;

      function hexToRgba(hex) {
        var c = void 0;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
          c = hex.substring(1).split('');
          if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
          }
          c = '0x' + c.join('');
          return [c >> 16 & 255, c >> 8 & 255, c & 255].join(',');
        } else {
          return '255,255,255';
        }
      }

      var availableStatus = this.get('availableStatus');
      var currentStatus = this.get('currentStatus');
      var currentStatusName = currentStatus.get('name');

      if (currentStatusName === 'pending' || currentStatusName === 'approved' || currentStatusName === 'declined') {
        self.set('currentStatusTranslate', 'status.' + currentStatusName);
      }

      if (availableStatus && currentStatus) {
        var activeStatusIndex = void 0;

        availableStatus.forEach(function (status, index) {
          if (status.get('status.name') === currentStatus.get('name')) {
            activeStatusIndex = index;
          }
        });

        var currentColor = hexToRgba(currentStatus.get('color') ? currentStatus.get('color') : '#212429');

        self.set('currentStatusColor', Ember.String.htmlSafe("background:rgba(" + currentColor + ",1);"));
        self.set('otherStatusColor', Ember.String.htmlSafe("background:rgba(" + currentColor + ",0.4);"));
        self.set('activeStatusIndex', activeStatusIndex);
        self.set('activeStatus', currentStatus);
      }
    }
  });
});