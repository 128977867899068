define('im-webapp/components/filter-bar', ['exports', 'moment', 'im-webapp/config/environment'], function (exports, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['filter-bar'],
    dropdownOpen: false,
    momentFormat: _environment.default.APP.MOMENT_FORMAT,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedPeriod', {
        start: (0, _moment.default)().startOf('month'),
        end: (0, _moment.default)().endOf('month')
      });
      this.set('resetEnabled', false);
    },


    actions: {
      didSelectedPeriod: function didSelectedPeriod(selected) {
        var selectedPeriod = {};
        if (selected.start && !selected.end) {
          selectedPeriod.start = (0, _moment.default)(selected.start);
          selectedPeriod.end = null;
        } else {
          selectedPeriod.start = (0, _moment.default)(selected.start);
          selectedPeriod.end = (0, _moment.default)(selected.end);
        }
        this.set('selectedPeriod', selectedPeriod);
        if (this.get('selectedPeriod').start && this.get('selectedPeriod').end) {
          this.set('resetEnabled', true);
          this.send('updateInitiatives');
        }
      },
      didSelectTag: function didSelectTag(tag) {
        this.set('selectedTags', tag);
        this.send('updateInitiatives');
      },
      didSelectCategory: function didSelectCategory(category) {
        this.set('selectedCategories', category);
        this.send('updateInitiatives');
      },
      didSelectStatus: function didSelectStatus(status) {
        this.set('selectedStatuses', status);
        this.send('updateInitiatives');
      },
      dropdownOpen: function dropdownOpen() {
        this.toggleProperty('dropdownOpen');
      },
      resetPeriod: function resetPeriod() {
        this.set('selectedPeriod.start', (0, _moment.default)().startOf('month'));
        this.set('selectedPeriod.end', (0, _moment.default)().endOf('month'));
        this.set('resetEnabled', false);
        this.send('updateInitiatives');
      },


      /**
       * updateInitiatives creates params object with selected tags, categories, statuses and date period,
       * used for initiatives filtering.
       * @method updateInitiatives
       */
      updateInitiatives: function updateInitiatives() {
        var _this = this;

        var params = {
          'tags.name': [],
          'category.name': [],
          'status.name': []
        };
        var filters = ['selectedPeriod', 'selectedTags', 'selectedCategories', 'selectedStatuses'];

        filters.forEach(function (param, index) {
          if (!_this.get(param)) return;
          // Add start and end date to params
          if (param === 'selectedPeriod') {
            if (_this.get(param).start && _this.get(param).end) {
              params["created[after]"] = _this.get(param).start.format(_environment.default.APP.FILTER_FORMAT);
              params["created[before]"] = _this.get(param).end.format(_environment.default.APP.FILTER_FORMAT);
              return;
            }
            return;
          }
          // Add selected tags, categories and statuses to params.filter
          _this.get(param).forEach(function (elem) {
            var names = ['created', 'tags', 'category', 'status'];
            params[names[index] + ".name"].push(elem.get('name'));
          });
        });

        this.get('updateInitiatives')(params);
      }
    }
  });
});