define('im-webapp/controllers/protected/category-management', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        categoryTable: Ember.computed('model.{categories,categories.[]}', function () {

            var categories = this.get('model').categories;

            return {
                header: [{ title: 'Name', propertyName: 'name', routeName: "protected.category-management.category" }, {
                    title: 'Edit',
                    component: "table-button",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-button",
                    isDelete: true,
                    disableSorting: true
                }],
                data: categories
            };
        }),

        actions: {
            closeModal: function closeModal() {
                this.set('deleteModal', false);
            },
            deleteCategory: function deleteCategory(id) {
                var category = this.get('store').peekRecord('category', id);
                this.set('currentCategory', category);
                this.set('deleteModal', true);
            },
            confirmDeleteCategory: function confirmDeleteCategory() {
                var category = this.get('currentCategory');
                category.deleteRecord();
                category.save();
                this.send('closeModal');
            },
            addCategory: function addCategory() {
                this.transitionToRoute('protected.category-management.category', 'new');
            },
            editCategory: function editCategory(id) {
                this.transitionToRoute('protected.category-management.category', id);
            }
        }
    });
});