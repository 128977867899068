define('im-webapp/helpers/average-rate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),

    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 3),
          _ref2$ = _ref2[0],
          likes = _ref2$ === undefined ? 0 : _ref2$,
          _ref2$2 = _ref2[1],
          dislikes = _ref2$2 === undefined ? 0 : _ref2$2,
          additionalText = _ref2[2];

      var sum = likes + dislikes;
      var i18n = this.get('i18n');
      var percentRate = Math.round(likes * 100 / sum);

      if (!sum) {
        return i18n.t('initiative.unrated');
      }

      var rate = (5 * percentRate / 100).toFixed(1);
      var checkedRate = rate[0] === '0' ? '1.0' : rate;
      return checkedRate + (additionalText ? ' ' + i18n.t('general.av_rate') : '');
    }
  });
});