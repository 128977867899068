define('im-webapp/components/table-button', ['exports', 'im-webapp/templates/components/table-button'], function (exports, _tableButton) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _tableButton.default,
        actions: {
            sendAction: function sendAction(actionName, recordId, event) {
                this.get('sendAction')(actionName, recordId);
                event.stopPropagation();
            }
        }
    });
});