define('im-webapp/controllers/protected/initiative/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Controller.extend({
        initiativeIdeas: Ember.computed('filteredIdeas', function () {
            if (this.get('filteredIdeas')) {
                return this.get('filteredIdeas');
            }
            return this.get('model.initiative.ideas');
        }),

        actions: {
            updateInitiativeIdeas: function updateInitiativeIdeas() {
                var _this = this;

                var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

                var additionalFilters = this.get('model.additionalFilters');
                //removing init period filters
                delete additionalFilters['created[after]'];
                delete additionalFilters['created[before]'];

                Object.assign.apply(Object, [params].concat(_toConsumableArray(params), [additionalFilters]));
                this.get('store').query('initiative', params).then(function (res) {
                    return _this.set('filteredIdeas', res);
                });
            }
        }
    });
});