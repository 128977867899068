define('im-webapp/models/user', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        username: (0, _attr.default)('string'),
        password: (0, _attr.default)('string'),
        firstName: (0, _attr.default)('string'),
        lastName: (0, _attr.default)('string'),
        email: (0, _attr.default)('string'),
        roles: (0, _attr.default)(''),
        avatar: (0, _attr.default)('string'),
        group: (0, _relationships.belongsTo)('group'),
        active: (0, _attr.default)('boolean'),
        locale: (0, _attr.default)('string'),
        fullName: Ember.computed('firstName', 'lastName', function () {
            var name = void 0;

            if (this.get('firstName') || this.get('lastName')) {
                name = (this.get('firstName') || "") + " " + (this.get('lastName') || "");
            } else {
                name = this.get('username');
            }

            return name;
        })
    });
});