define('im-webapp/components/initiative-form', ['exports', 'im-webapp/validations/initiative', 'im-webapp/validations/idea', 'im-webapp/config/environment', 'moment'], function (exports, _initiative, _idea, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['form'],
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    initiative: null,
    momentFormat: _environment.default.APP.MOMENT_FORMAT,

    initiativeIdeaValidation: Ember.computed('isIdea', function () {
      return this.get('isIdea') ? _idea.default : _initiative.default;
    }),

    init: function init() {
      this._super.apply(this, arguments);

      //sorted array by start date
      var timelineStatuses = this.get('initiative.timelineStatuses');

      this.checkStatuses(timelineStatuses);

      //set only default statuses for final status
      var allStatuses = this.get('allStatus');
      var defaultStatuses = allStatuses.filter(function (status) {
        var statusName = status.get('name');
        if (statusName === 'pending' || statusName === 'approved' || statusName === 'declined') {
          return status;
        }
      });

      this.set('defaultStatuses', defaultStatuses);
    },
    checkStatuses: function checkStatuses(timelineStatuses) {
      var self = this;
      if (timelineStatuses) {
        var sortedStatuses = timelineStatuses.sortBy('expireDate');
        this.set('formattedStatuses', sortedStatuses);

        if (sortedStatuses.length > 0) {
          var lastStatus = sortedStatuses[sortedStatuses.length - 1];
          //turn on/off final status select - active only if current date is after last
          self.set('allStatusesPassed', (0, _moment.default)().diff(lastStatus.get('expireDate'), 'days') > 0);
        }
      }
    },


    actions: {
      save: function save(changeset) {

        var changes = changeset.get('changes');
        changeset.validate();
        var isValid = changeset.get('isValid');
        var initiative = this.get('initiative');
        var isDefaultActive = this.get('isDefaultActive');
        var self = this;

        if (isValid) {

          changes.forEach(function (change) {
            initiative.set(change.key, change.value);
          });

          //new idea is always active
          if (isDefaultActive) {
            initiative.set('active', true);
          }

          if (self.get('randomId')) {
            //add current user
            var userId = this.get('session.data.authenticated.user_id');
            var currentUser = this.get('store').peekRecord('user', userId);
            initiative.set('owner', currentUser);
            //set date
            initiative.set('created', new Date());
          }

          self.sendAction('save', initiative);
        }
      },
      rollback: function rollback(changeset) {
        var initiative = this.get('initiative');

        //in case user add timeline statuses and then closes dialog
        //removing new unused timeline statuses from server
        var self = this;
        if (this.get('randomId')) {
          var timelineStatuses = self.get('initiative.timelineStatuses');
          timelineStatuses.forEach(function (status) {
            status.deleteRecord();
            status.save();
          });
        }

        this.get('closeModal')(initiative);
        return changeset.rollback();
      },
      addStatus: function addStatus() {
        var newStatus = this.get('store').createRecord('timeline-status');

        //if does not exist create empty array
        if (!this.get('initiative.timelineStatuses')) {
          this.set('initiative.timelineStatuses', []);
        }

        this.get('initiative.timelineStatuses').pushObject(newStatus);

        //update sorted array
        var timelineStatuses = this.get('initiative.timelineStatuses');
        this.set('formattedStatuses', timelineStatuses.sortBy('startDate'));

        //remove conflict days message
        this.set('conflictArray', false);
      },
      removeStatus: function removeStatus(status) {
        //removing from array
        this.get('initiative.timelineStatuses').removeObject(status);

        //update sorted array
        var timelineStatuses = this.get('initiative.timelineStatuses');
        this.set('formattedStatuses', timelineStatuses.sortBy('startDate'));

        //deleting from server if exists (not new or empty)
        if (status.get('id')) {
          var statusRecord = this.get('store').peekRecord('timeline-status', status.get('id'));
          statusRecord.deleteRecord();
          statusRecord.save();
        }

        //remove conflict days message
        this.set('conflictArray', false);
      },
      editTimelineStatus: function editTimelineStatus() {
        //remove conflict days message
        this.set('conflictArray', false);
      },
      confirmTimelineStatus: function confirmTimelineStatus(timelineStatus) {

        function generateDays(startDate, endDate) {
          var id = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
          var statusName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';

          var dates = [];

          var currDate = (0, _moment.default)(startDate).startOf('day');
          var lastDate = (0, _moment.default)(endDate).startOf('day');

          //add first day
          dates.push({
            date: currDate.format('DD/MM/YYYY'),
            id: id,
            statusName: statusName
          });

          while (currDate.add(1, 'days').diff(lastDate) <= 0) {
            dates.push({
              date: currDate.clone().format('DD/MM/YYYY'),
              id: id,
              statusName: statusName
            });
          }

          return dates;
        }

        //all days from timeline statuses
        var timelineStatuses = this.get('initiative.timelineStatuses');

        var conflictArray = [];
        var allTimelineDays = [];

        //checking duplicates and creating array if days exist
        timelineStatuses.forEach(function (status) {
          var singleStatusArray = generateDays(status.get('startDate'), status.get('expireDate'), status.get('id'), status.get('status.name'));
          allTimelineDays = allTimelineDays.concat(singleStatusArray);
        });

        //days in changed interval
        var newTimelineDays = generateDays(timelineStatus.get('startDate'), timelineStatus.get('expireDate'), timelineStatus.get('id'), timelineStatus.get('status.name'));

        //days changed and already exists
        var conflictDays = {};
        allTimelineDays.forEach(function (timelineStatus) {
          newTimelineDays.forEach(function (status) {
            if (timelineStatus.date === status.date && timelineStatus.id !== status.id) {

              if (conflictDays.hasOwnProperty(timelineStatus.statusName)) {
                conflictDays[timelineStatus.statusName] = conflictDays[timelineStatus.statusName].concat([timelineStatus.date]);
              } else {
                conflictDays[timelineStatus.statusName] = [timelineStatus.date];
              }
            }
          });
        });

        //formatting for template
        for (var key in conflictDays) {
          conflictArray.push({
            name: key,
            days: conflictDays[key]
          });
        }

        var self = this;
        if (conflictArray.length > 0) {
          self.set('conflictArray', conflictArray);
        } else {
          self.set('conflictArray', false);

          //do not add initiative as relationship in new initiative case
          if (!self.get('randomId')) {
            timelineStatus.set('initiative', this.get('initiative'));
          }

          // update current status
          timelineStatus.save().then(function () {
            timelineStatus.set('isConfirmed', true);
            var timelineStatuses = self.get('initiative.timelineStatuses');
            self.set('formattedStatuses', timelineStatuses.sortBy('startDate'));

            var initiative = self.get('initiative');

            if (initiative && !self.get('randomId')) {
              self.get('store').findRecord('initiative', initiative.get('id'), { include: 'status,timelineStatuses' }).then(function (initiative) {
                self.checkStatuses(initiative.get('timelineStatuses'));
                self.set('initiative', initiative);
              });
            }
          });
        }
      }
    }
  });
});