define('im-webapp/controllers/protected/initiative/comments', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            updateComments: function updateComments() {
                var self = this;
                this.get('store').query('comment', { initiative: self.get('model.initiativeId') }).then(function (comments) {
                    self.set('model.comments', comments);
                });
            }
        }
    });
});