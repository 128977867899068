define('im-webapp/components/scroll-data-load', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Component.extend({
        classNames: ['scroll-data-load'],
        store: Ember.inject.service(),
        triggerData: true,
        currentPage: 1,
        padding: 45,
        bottomOffset: 50,

        didReceiveAttrs: function didReceiveAttrs() {
            if (!this.get('renderData') && this.get('data.content')) {
                var renderData = [];
                renderData[0] = this.get('data').toArray();
                this.set('renderData', renderData);
                this.set('borderUp', true);
            }
            this._super.apply(this, arguments);
        },
        didRender: function didRender() {
            var totalItems = this.get('queryFilter.totalItems') ? this.get('queryFilter.totalItems') : this.get('totalItems');
            var padding = this.get('padding');
            var height = totalItems * padding;
            this.set('height', height);

            if (this.get('inverse') && !this.get('initScrollPosition') && this.get('renderData')) {
                this.initInverseScrollPosition();
            }

            this.set('pages', Math.ceil(Number(totalItems) / Number(this.get('queryFilter.itemsPerPage'))));
            this._super.apply(this, arguments);
        },
        didInsertElement: function didInsertElement() {
            var self = this;
            var body = Ember.$('#' + this.elementId).find('.scroll-data-load-body');

            body.scroll(function (e) {
                e.preventDefault();
                e.currentTarget.clientTop !== 0 ? e.currentTarget.clientTop = 30 : e.currentTarget.clientTop;
                var triggerData = self.get("triggerData");
                var queryFilter = self.get('queryFilter');
                var data = self.get('data');
                var model = self.get('model');
                var pages = self.get('pages');
                queryFilter.page = self.get('currentPage');
                var inverse = self.get('inverse');

                if (Ember.$(this).scrollTop() == 0 && data.get('content')) {
                    if (queryFilter.page <= 1 && !inverse || queryFilter.page >= pages && inverse || !triggerData) {
                        return;
                    }
                    inverse ? ++queryFilter.page : --queryFilter.page;
                    self.queryData(model, queryFilter, 'up', body);
                }

                if (Ember.$(this).scrollTop() + Ember.$(this).innerHeight() >= Ember.$(this)[0].scrollHeight - 10) {
                    if (queryFilter.page <= 1 && inverse || queryFilter.page >= pages && !inverse || !triggerData) {
                        return;
                    }
                    self.set('triggerData', false);
                    inverse ? --queryFilter.page : ++queryFilter.page;
                    self.queryData(model, queryFilter, 'down', body);
                }
            });
        },
        queryData: function queryData(model, queryFilter, direction, body) {
            var self = this;
            var inverse = this.get('inverse');
            var lastDirection = this.get('lastDirection');
            var renderData = this.get('renderData');

            if (lastDirection && direction !== lastDirection && queryFilter.page >= 2) {
                switch (direction) {
                    case 'up':
                        inverse ? ++queryFilter.page : --queryFilter.page;
                        break;
                    case 'down':
                        inverse ? --queryFilter.page : ++queryFilter.page;
                        break;
                }
            }

            this.get('store').query(model, queryFilter).then(function (response) {
                var newArray = [];

                switch (direction) {
                    case 'down':
                        newArray[0] = [];
                        newArray[0] = renderData[1] ? renderData[1] : renderData[0];
                        newArray[1] = [];
                        newArray[1].pushObjects(response.toArray());
                        break;
                    case 'up':
                        newArray[1] = [];
                        newArray[1] = renderData[0] ? renderData[0] : renderData[1];
                        newArray[0] = [];
                        newArray[0].pushObjects(response.toArray());
                        break;
                }

                self.animate(body, queryFilter.itemsPerPage);
                self.set('renderData', newArray);
                self.set('currentPage', response.get('meta.currentPage'));
                self.set('queryFilter', Object.assign.apply(Object, [queryFilter].concat(_toConsumableArray(queryFilter), [response.get('meta')])));
                self.set('lastDirection', direction);
            });
        },
        animate: function animate(body) {
            var scrollTop = body[0].scrollHeight / 2;
            body.animate({ scrollTop: scrollTop }, 100);
            this.set('triggerData', true);
        },
        initInverseScrollPosition: function initInverseScrollPosition() {
            var body = Ember.$('#' + this.elementId).find('.scroll-data-load-body');
            var bottomOffset = this.get('bottomOffset');
            body.scrollTop(body[0].scrollHeight - body.innerHeight() - bottomOffset);
            this.set('initScrollPosition', true);
        },


        actions: {
            refreshModel: function refreshModel() {
                this.initInverseScrollPosition();
                this.set('initScrollPosition', false);
                this.set('pages', null);
                this.set('currentPage', 1);
                this.set('renderData', false);
                this.set('lastDirection', false);
                this.sendAction('refreshModel', this.get('callingFromParent'));
            }
        }
    });
});