define('im-webapp/routes/protected/initiative/feeds', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function model(params, history) {
            var initiativeId = history.state.params['protected.initiative'].id;
            return Ember.RSVP.hash({
                feeds: this.get('store').query('activity', { initiative: initiativeId, include: 'creator' }),
                initiativeId: initiativeId
            });
        },

        actions: {
            refreshModel: function refreshModel(callingFromParent) {
                if (!callingFromParent) this.refresh();
            }
        }
    });
});