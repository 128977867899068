define('im-webapp/models/comment', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        text: (0, _attr.default)('string'),
        creator: (0, _relationships.belongsTo)('user'),
        created: (0, _attr.default)('date'),
        initiative: (0, _relationships.belongsTo)('initiative')
    });
});