define('im-webapp/controllers/protected/status-management', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        statusTable: Ember.computed('model.{status,status.[]}', function () {

            var status = this.get('model').status;

            return {
                header: [{ title: 'Name', propertyName: 'name', routeName: "protected.status-management.status" }, {
                    title: 'Edit',
                    component: "table-button",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-button",
                    isDelete: true,
                    disableSorting: true
                }],
                data: status
            };
        }),

        actions: {
            closeModal: function closeModal() {
                this.set('deleteModal', false);
            },
            deleteStatus: function deleteStatus(id) {
                var status = this.get('store').peekRecord('status', id);
                this.set('currentStatus', status);
                this.set('deleteModal', true);
            },
            confirmDeleteStatus: function confirmDeleteStatus() {
                var status = this.get('currentStatus');
                status.deleteRecord();
                status.save();
                this.send('closeModal');
            },
            addStatus: function addStatus() {
                this.transitionToRoute('protected.status-management.status', 'new');
            },
            editStatus: function editStatus(id) {
                this.transitionToRoute('protected.status-management.status', id);
            }
        }
    });
});