define('im-webapp/locales/el/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'general': {
      'send': 'Αποστολή',
      'email': 'Email',
      'confirm': 'Επιβεβαίωση',
      'save': 'Αποθήκευση',
      'delete': 'Διαγραφή',
      'cancel': 'Ακύρωση',
      'error': "Σφάλμα",
      'invalid': "Μη έγκυρο",
      'successful': "Επιτυχώς",
      'active': "Ενεργό",
      'homepage': 'Αρχική σελίδα',
      'are_you_sure': 'Σίγουρα επιθυμείτε να γίνει διαγραφή',
      'search': 'Αναζήτηση',
      'add': 'Προσθήκη',
      'edit': 'Επεξεργασία',
      'period': 'Περίοδος',
      'welcome': 'Καλωσορίσατε',
      'title': 'Τίτλος',
      'desc': 'Περιγραφή',
      'categories': 'Κατηγορίες',
      'tags': 'Ετικέτες',
      'date': 'Ημερομηνία',
      'av_rate': 'avg.rate',
      'read_more': 'Διαβάστε περισσότερα',
      'read_less': 'Διαβάστε λιγότερα',
      'close': 'Κλείσιμο',
      'follow': 'Ακολούθησε',
      'unfollow': 'Κατάργηση παρακολούθησης',
      'prev': 'Προηγ',
      'next': 'Επόμενο',
      'records_per_page': 'Εγγραφές ανά σελίδα',
      'current_page': 'Τρέχουσα σελίδα',
      'show': 'Προβολή',
      'of': 'από',
      'columns': 'Στήλες',
      'show_all': 'Εμφάνιση όλων',
      'hide_all': 'Απόκρυψη όλων',
      'restore_defaults': 'Επαναφορά προκαθορισμένων',
      'no_data': 'Δεν υπάρχουν εγγραφές',
      'see_all': 'Προβολή όλων',
      'home': 'αρχική σελίδα'
    },
    'auth': {
      'username': 'Όνομα χρήστη',
      'password': 'Κωδικός πρόσβασης',
      'forgot_password': 'Ξέχασα τον κωδικό πρόσβασης',
      'login': 'Σύνδεση',
      'logout': 'Αποσυνδέση',
      'back_to_login': 'Επιστροφή στη σελίδα σύνδεσης',
      'confirm_password': 'Επιβεβαίωση κωδικού πρόσβασης',
      'recovery_password': 'Κωδικός ανάκτησης',
      'password_changed': 'Ο κωδικός τροποποιήθηκε',
      'change_password': 'Αλλαξε κωδικό πρόσβασης',
      'new_password': 'Νέος κωδικός πρόσβασης',
      'successful_update_password': 'Ο κωδικό πρόσβασης ενημερώθηκε με επιτυχία',
      'password_is_incorrect': 'Ο κωδικός πρόσβασης είναι εσφαλμένος',
      'remember_me': 'Αποθήκευσέ με',
      'email_with_instructions': 'Το email  με οδηγίες στάλθηκε στο email σας'
    },
    'error': {
      'bad_request': 'Λανθασμένο αίτημα',
      'resource_not_found': 'Δε βρέθηκαν πόροι',
      'unauthorized': 'Μη εξουσιοδοτημένος',
      'no_permission': 'Δεν έχετε άδεια για αυτήν την ενέργεια',
      'not_allowed': 'Η ενέργεια δεν επιτρέπεται',
      'time_out': 'Το αίτημα έληξε',
      'too_many': 'Πάρα πολλά αιτήματα ταυτόχρονα',
      'server': 'Σφάλμα διακομιστή',
      'refresh_token': 'Δεν ήταν δυνατή η ανανέωση του διακριτικού πρόσβασης',
      'start_end_period': 'Παρακαλώ επιλέξτε την ημερομηνία έναρξης και λήξης',
      'start_before_expired': 'Η ημερομηνία λήξης πρέπει να είναι μετά την ημερομηνία έναρξης'
    },
    'notify': {
      'updated': "Επιτυχής ενημέρωση",
      'added': "Προστέθηκε με επιτυχία",
      'deleted': "Εγγραφή διαγράφηκε με επιτυχία",
      'tags': 'Ετικέτα',
      'categories': 'Κατηγορία',
      'users': 'Χρήστης',
      'companies': 'Εταιρία',
      'statuses': 'Κατάσταση',
      'groups': 'Ομάδα',
      'initiatives': 'Πρωτοβουλία',
      'idea': 'Πρόταση',
      'comments': 'Σχόλιο',
      'timeline-statuses': 'Κατάσταση περιόδου'
    },
    'category': {
      'category': 'Κατηγορία',
      'category_management': 'Διαχείριση κατηγοριών',
      'name': 'Ονομα κατηγορίας',
      'desc': 'Περιγραφή κατηγορίας',
      'add_category': 'Προσθήκη κατηγορίας',
      'select_categories': 'Επιλέξτε κατηγορίες...'
    },
    'tag': {
      'tag': 'Ετικέτα',
      'tag_management': 'Διαχείριση ετικετών',
      'name': 'Όνομα ετικέτας',
      'add_tag': 'Προσθήκη ετικέτας',
      'select_tags': 'Επιλέξτε ετικέτες...'
    },
    'user': {
      'user': 'Χρήστης',
      'first_name': 'Όνομα',
      'last_name': 'Επώνυμο',
      'full_name': 'Ονοματεπώνυμο',
      'group': 'Ομάδα',
      'my_profile': 'Το προφίλ μου',
      'my_ideas': 'Οι προτάσεις μου',
      'my_initiatives': 'Οι πρωτοβουλίες μου',
      'my_feed': 'Μετάδοσή μου',
      'user_management': 'Διαχείριση χρηστών',
      'roles': 'Ρόλοι χρηστών',
      'add_user': 'Προσθήκη χρήστη',
      'admin_role': 'Ρόλος διαχειριστή',
      'user_role': 'Ρόλος χρήστη',
      'active': 'Ενεργό',
      'inactive': 'Ανενεργό'
    },
    'group': {
      'group': 'Ομάδα',
      'group_management': 'Διαχείρηση της ομάδας',
      'name': 'Όνομα ομάδας',
      'desc': 'Περιγραφή ομάδας',
      'add_group': 'Προσθήκη ομάδας'
    },
    'status': {
      'status': 'Κατάσταση',
      'status_management': 'Διαχείριση κατάστασης',
      'name': 'Τίτλος κατάστασης',
      'desc': 'Περιγραφή κατάστασης',
      'active': 'Διαθεσιμότητα',
      'add_status': 'Προσθήκη κατάστασης',
      'color': 'Χρώμα κατάστασης',
      'select_statuses': 'Επιλέξτε καταστάσεις ...',
      'expire_date': 'Ημερομηνία λήξης',
      'start_date': 'Ημερομηνία έναρξης',
      'voting': 'Κατάσταση ψηφοφορίας',
      'final_status': 'Τελική κατάσταση',
      'pending': 'Εκκρεμεί',
      'approved': 'Εγκεκριμένο',
      'declined': 'Απορρίφθηκε'
    },
    'initiative': {
      'initiative': 'Πρωτοβουλία',
      'initiative_management': 'Διαχείριση πρωτοβουλιών',
      'title': 'Τίτλος πρωτοβουλίας',
      'desc': 'Περιγραφή της πρωτοβουλίας',
      'categories': 'Κατηγορίες πρωτοβουλίας',
      'tags': 'Ετικέτες πρωτοβουλίας',
      'add_initiative': 'Προσθήκη πρωτοβουλίας',
      'follow_initiative': 'Ακολούθησε την πρωτοβουλία',
      'unfollow_initiative': 'Ακύρωση παρακολούθησης της πρωτοβουλίας',
      'followers': 'Φόλοουερ',
      'latest': 'Τελευταίες πρωτοβουλίες',
      'available_statuses': 'Διαθέσιμες καταστάσεις',
      'current_status': 'Τρέχουσα κατάσταση',
      'unrated': 'Χωρίς αξιολόγηση',
      'like': 'Μου άρεσε η πρωτοβουλία',
      'created': 'δημιούργήθηκε πρωτοβουλία',
      'follow': 'ακολούθησε την πρωτοβουλία',
      'unfollow': 'ακύρωση παρακολούθησης της πρωτοβουλίας',
      'comment': 'σχολίασε την πρωτοβουλία',
      'dislike': 'δεν μου αρέσει η πρωτοβουλία',
      'attachment': 'ανέβασμα συνημμένου στην αρχική σελίδα',
      'feed': 'Εμφάνηση πρωτοβουλιών'
    },
    'idea': {
      'idea': 'Πρόταση',
      'ideas': 'Προτάσεις',
      'idea_management': 'Διαχείρηση προτάσεων',
      'add_idea': 'Προσθήκη πρότασης',
      'follow_idea': 'Ακολούθησε πρόταση',
      'unfollow_idea': 'Ακύρωση παρακολούθησης της πρότασης',
      'idea_feed': 'Εμφάνηση προτάσεων',
      'like': 'Μου αρέσει η πρόταση',
      'created': 'Δημιουργήθηκε η πρόταση',
      'follow': 'έχει παρακολουθήσει την πρόταση',
      'unfollow': 'ακύρωση παρακολούθησης της πρότασης',
      'comment': 'σχολίασε την πρόταση',
      'dislike': 'δεν μου αρέσει η πρόταση',
      'attachment': 'Ανέβασμα συνημμένου στην πρόταση',
      'feed': 'Εμφάνηση προτάσεων'
    },
    'upload': {
      'drag_to_upload': 'Σείρετε για να ανεβάσετε',
      'drag_and_drop_area': 'Σύρετε και ρίξτε: περιοχή',
      'upload_new_file': 'Ανέβασμα νέου αρχείου'
    },
    'comments': {
      'comments': 'Σχόλια',
      'comment': 'Σχόλιο',
      'required_field': 'Το σχόλιο δεν μπορεί να είναι κενό',
      'comment_text': 'Κάντε σχόλιο...'
    },
    'media': {
      'media': 'Μέσα',
      'no_media_added': 'Δεν έχουν προστέθει μέσα ακόμα',
      'media_library': 'Βιβλιοθήκη μέσων',
      'media_avatar': 'Προεπισκόπηση αρχείου',
      'media_name': 'Τίτλος μέσου',
      'media_author': 'Δημιουργός',
      'media_actions': 'Ενέργειες',
      'initiative_idea': 'Πρωτοβουλία / ιδέα'
    },
    'languages': {
      'language': 'Γλώσσα',
      'en': 'En',
      'rs': 'Sr',
      'el': 'El',
      'es': 'Es',
      'fr': 'Fr',
      'it': 'It',
      'pt': 'Pt',
      'ru': 'Ru'
    },
    'messages': {
      'messages': 'Μηνύματα',
      'type_message': 'Πληκτρολογήστε το μήνυμά σας...',
      'new_message': 'Νέο μήνυμα',
      'welcome': 'Καλωσορίστε στα μηνύματα!',
      'select_user': 'Επιλογή χρήστη'
    },
    'notifications': {
      'notifications': 'Ειδοποιήσεις'
    },
    'image': {
      'change_image': 'Αλλαγή εικόνας',
      'confirm_image_note': 'Αν θέλετε να διατηρήσετε αυτήν την εικόνα, επιβεβαιώστε.'
    },
    'like': {
      'likes': '«Μου αρέσει»',
      'dislikes': '"Δεν μου αρέσει"',
      'voting_finished': 'Η ψηφοφορία έχει τελειώσει'
    },
    'validations': {
      'password_must_match': 'Ο κωδικός πρόσβασης πρέπει να ταιριάζει',
      'email': 'Πρέπει να είναι έγκυρη η διεύθυνση email',
      'not_a_number': 'Πρέπει να είναι αριθμός',
      'present': 'Δεν μπορεί να είναι κενό',
      'too_short': 'Πολύ σύντομο (ελάχιστο είναι {ελαχ.} χαρακτήρες)',
      'invalid': 'Μη έγκυρο',
      'password_visible': 'Απόκριψη κωδικού πρόσβασης',
      'password_invisible': 'Προβολή κωδικού πρόσβασης'
    },
    'table': {
      'title': 'Τίτλος',
      'edit': 'Επεξεργασία',
      'delete': 'Διαγραφή',
      'fullname': 'Ονοματεπώνυμο',
      'name': 'Όνομα',
      'desc': 'Περιγραφή',
      'tag': 'Ετικέτα',
      'status': 'Κατάσταση'
    },
    'slider': {
      'empty_slider': 'Δεν υπάρχουν πρωτοβουλίες'
    },
    'timeline': {
      'already_selected_days': 'Έχετε ήδη επιλέξει αυτές τις μέρες',
      'change_available_days': ' Παρακαλώ αλλάξτε πρώτα τις άλλες καταστάσεις και κάντε διαθέσιμες αυτές τις ημέρες'
    },
    'activity': {
      'like': 'άρεσε',
      'created': 'δημιουργήθηκε',
      'follow': 'παρακολουθήθηκε',
      'unfollow': 'ακυρώθηκε η παρακολούθηση',
      'comment': 'σχολιάστηκε',
      'dislike': 'δεν άρεσε',
      'attachment': 'πρόσθετο συνημμένο',
      'status changed': 'Η κατάσταση έχει αλλάξει',
      'delete': 'διαγραφή',
      'remove like': 'Αφαιρέθηκε "μου αρέσει"',
      'remove dislike': 'Αφαιρέθηκε "δε μου αρέσει"',
      'remove comment': 'Αφαιρέθηκε το σχόλιο',
      'remove attachment': 'Αφαιρέθηκε το συννημένο',
      'feed': 'Μετάδοση δραστηριότητας',
      'my_feed': 'Μετάδοσή μου'
    },
    'statistics': {
      'statistics': 'Στατιστικά',
      'ideas_per_status': 'Προτάσεις ανά κατάσταση',
      'initiatives_per_status': 'Πρωτοβουλίες ανά κατάσταση',
      'number_of_ideas': 'Αριθμός προτάσεων',
      'number_of_initiatives': 'Αριθμός πρωτοβουλιών',
      'initiatives_per_idea': 'Πρωτοβουλίες ανά αριθμό προτάσεων',
      'likes_dislikes_chart': 'Μου αρέσει, Δεν μου αρέσει, φόλουερς και σχόλια',
      'likes': '«Μου αρέσει»',
      'dislikes': '"Δεν μου αρέσει"',
      'comments': 'Σχόλια',
      'followers': 'Φόλοουερ',
      'total_number': 'Συνολικός αριθμός'
    },
    'message': {
      'new': 'Νέο μήνυμα εστάλη'
    }
  };
});