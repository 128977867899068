define('im-webapp/components/layout-changer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['layout-changer'],
        init: function init() {
            this._super.apply(this, arguments);
            this.set('gridView', true);
        },


        actions: {
            toggleLayout: function toggleLayout(layoutName) {
                var self = this;
                var gridView = this.get("gridView");

                if (layoutName === 'grid' && !gridView || layoutName !== 'grid' && gridView) {
                    self.toggleProperty('gridView');
                    this.get('toggleLayout')();
                }
            }
        }
    });
});