define('im-webapp/validations/change-password', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        password: [(0, _validators.validatePresence)(true)],
        newPassword: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 3 })],
        passwordConfirmation: [(0, _validators.validatePresence)(true), (0, _validators.validateConfirmation)({ on: 'newPassword' })]
    };
});