define('im-webapp/routes/login', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        session: Ember.inject.service(),

        beforeModel: function beforeModel() {
            if (this.get('session').get('isAuthenticated')) {
                this.transitionTo('protected');
            }
        }
    });
});