define('im-webapp/components/image-changer', ['exports', 'im-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['image-changer'],
    store: Ember.inject.service(''),
    coverPhoto: Ember.inject.service(),

    classNameBindings: ['small:image-changer--small', 'medium:image-changer--medium', 'large:image-changer--large', 'extraLarge:image-changer--extra-large', 'shadow:image-changer--shadow'],

    init: function init() {
      this._super.apply(this, arguments);
      this.setImage();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.setImage();
    },
    setImage: function setImage() {
      var coverPhoto = this.get('coverPhoto');
      var defaultPath = this.fileKey === 'avatar' ? '/assets/images/default-avatar.png' : coverPhoto.setCoverPhoto();
      this.set('fullImagePath', this.imagePath ? (this.fileKey === 'avatar' ? _environment.default.APP.HOST + '/' : _environment.default.APP.HOST) + this.imagePath : defaultPath);
    },


    actions: {
      changeImage: function changeImage() {
        this.set('imageModal', true);
      },
      confirmImage: function confirmImage(filePath, recordId) {
        var self = this;

        if (this.fileKey === 'avatar') {
          self.get('store').findRecord('user', recordId).then(function (user) {
            user.set('avatar', filePath);
            user.save().then(function () {
              self.set('imageModal', false);
            });
          });
        } else {
          //cover image case
          var initiative = this.get('store').peekRecord('initiative', recordId);
          initiative.set('cover', filePath);
          self.set('imageModal', false);
        }
      },
      imageUploaded: function imageUploaded(path) {
        this.set('filePath', path);
        this.set('fullFilePath', _environment.default.APP.HOST + '/' + path);
      },
      closeModal: function closeModal() {
        this.set('imageModal', false);
        this.set('filePath', null);
        this.setImage();
      }
    }
  });
});