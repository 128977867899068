define('im-webapp/routes/protected/initiative-management', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    model: function model() {
      return Ember.RSVP.hash({
        initiatives: this.get('store').query('initiative', { 'initiative[exists]': false, include: 'status,followers,comments,timelineStatuses' }),
        tags: this.get('store').findAll('tag'),
        categories: this.get('store').findAll('category'),
        status: this.get('store').findAll('status')
      });
    },

    actions: {
      loading: function loading() {
        this.controllerFor('protected').set('isLoading', true);
        this.router.on('didTransition', this, function () {
          this.controllerFor('protected').set('isLoading', false);
        });
      }
    }
  });
});