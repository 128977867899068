define('im-webapp/components/initiative-box', ['exports', 'im-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['initiative-box col-sm-12  col-md-6 col-xl-4'],
        momentFormat: _environment.default.APP.MOMENT_FORMAT,
        store: Ember.inject.service(),
        session: Ember.inject.service(),
        counter: Ember.inject.service(),
        coverPhoto: Ember.inject.service(),

        isFollowed: Ember.computed('initiative.{followers,followers.[]}', function () {
            var currentUserId = this.get('session.data.authenticated.user_id');
            var followers = this.get('initiative.followers');
            var isFollowed = false;

            followers.forEach(function (follower) {
                if (+follower.get('id') === currentUserId) {
                    isFollowed = true;
                }
            });

            return isFollowed;
        }),

        backgroundImage: Ember.computed('initiative.cover', function () {
            var coverPhoto = this.get('coverPhoto');
            var imgUrl = this.initiative.get('cover') ? _environment.default.APP.HOST + this.initiative.get('cover') : coverPhoto.setCoverPhoto();
            return Ember.String.htmlSafe("background-image: url('" + imgUrl + "')");
        }),

        ideas: Ember.computed('initiative.{ideas,ideas.[]}', function () {
            return this.get('counter').checkArray(this.get('initiative'), 'ideas');
        }),

        followers: Ember.computed('initiative.{followers,followers.[]}', function () {
            return this.get('counter').checkArray(this.get('initiative'), 'followers');
        }),

        comments: Ember.computed('initiative.{comments,comments.[]}', function () {
            return this.get('counter').checkArray(this.get('initiative'), 'comments');
        }),

        attachments: Ember.computed('initiative.{attachments,attachments.[]}', function () {
            return this.get('counter').checkArray(this.get('initiative'), 'attachments');
        }),

        likes: Ember.computed('initiative.{likes,likes.[]}', function () {
            return this.get('counter').checkArray(this.get('initiative'), 'likes');
        }),

        dislikes: Ember.computed('initiative.{dislikes,dislikes.[]}', function () {
            return this.get('counter').checkArray(this.get('initiative'), 'dislikes');
        }),

        actions: {
            follow: function follow(initiative, _follow) {
                var currentUser = this.get('store').peekRecord('user', this.get('session.data.authenticated.user_id'));

                if (_follow) {
                    initiative.get('followers').pushObject(currentUser);
                } else {
                    initiative.get('followers').removeObject(currentUser);
                }

                initiative.save();
            },


            /**
             * like
             * @method like
             * @param isLike (boolean) - defines is like or dislike
             * @param liked (boolean) - defines is adding or removing like/dislike
             */
            like: function like(isLike, liked) {
                var currentUser = this.get('store').peekRecord('user', this.get('session.data.authenticated.user_id'));
                var initiative = this.get('initiative');

                if (isLike) {
                    if (liked) {
                        initiative.get('likes').removeObject(currentUser);
                    } else {
                        initiative.get('likes').pushObject(currentUser);
                    }
                } else {
                    if (liked) {
                        initiative.get('dislikes').removeObject(currentUser);
                    } else {
                        initiative.get('dislikes').pushObject(currentUser);
                    }
                }

                initiative.save();
            }
        }
    });
});