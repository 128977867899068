define('im-webapp/components/media-library', ['exports', 'im-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['media-library'],
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    customAjax: Ember.inject.service('customAjax'),
    toast: Ember.inject.service(),
    i18n: Ember.inject.service(),

    sourcedAttachments: Ember.computed('attachments', function () {
      var attachments = this.get('attachments');
      var customAjax = this.get('customAjax');

      attachments.map(function (attachment) {
        if (!attachment.get('isPdf')) {
          var url = _environment.default.APP.API_HOST + "/download-attachment/" + attachment.get('id');
          customAjax.request('GET', url).then(function (response) {
            attachment.set('src', response.data);
          });
        } else {
          attachment.set('src', false);
        }
      });
      return attachments;
    }),

    actions: {
      addAttachment: function addAttachment(attachmentId) {
        var initiative = this.get('store').peekRecord('initiative', this.get('initiativeId'));
        var self = this;
        this.get('store').findRecord('attachment', attachmentId).then(function (attachment) {
          initiative.get('attachments').pushObject(attachment);
          initiative.save().then(function () {
            self.sendAction('updateAttachments');
          });
        });
      },
      deleteAttachment: function deleteAttachment(attachment) {
        this.set('deleteModal', true);
        this.set('currentAttachment', attachment);
      },
      confirmDeleteAttachment: function confirmDeleteAttachment(attachmentId) {
        var self = this;
        var attachment = this.get('store').peekRecord('attachment', attachmentId);
        attachment.deleteRecord();
        attachment.save().then(function () {
          self.send('closeModal');
        });
      },
      downloadAttachment: function downloadAttachment(attachment) {
        var i18n = this.get('i18n');
        var toast = this.get('toast');
        var customAjax = this.get('customAjax');
        var session = this.get('session').get('session');
        var url = _environment.default.APP.API_HOST + "/download-attachment/" + attachment.get('id');

        if (attachment.get('isPdf')) {

          var xhr = new XMLHttpRequest();
          xhr.open('GET', url, true);
          xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
          xhr.setRequestHeader('authorization', "Bearer " + session.content.authenticated.access_token);
          xhr.responseType = 'arraybuffer';
          xhr.onload = function () {
            if (200 === this.status) {
              var blob = new Blob([this.response], { type: "application/pdf" });
              var anchor = Ember.$("<a>").attr("href", window.URL.createObjectURL(blob)).attr("download", attachment.get('name')).appendTo("body");
              anchor[0].click();
              anchor.remove();
            } else {
              toast.error(i18n.t('general.error'), i18n.t('general.error'));
            }
          };
          xhr.send('type=pdf');
        } else {
          customAjax.request('GET', url).then(function (response) {
            var anchor = Ember.$("<a>").attr("href", response.data).attr("download", attachment.get('name')).appendTo("body");
            anchor[0].click();
            anchor.remove();
          }).catch(function (error) {
            toast.error(error, i18n.t('general.error'));
          });
        }
      },
      closeModal: function closeModal() {
        this.set('deleteModal', false);
      }
    }

  });
});