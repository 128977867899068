define('im-webapp/controllers/protected/idea-management', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        ideasTable: Ember.computed('model.{ideas,ideas.[]}', function () {
            var ideas = this.get('model').ideas || [];
            return {
                header: [{ title: 'Title', propertyName: 'title', routeName: "protected.idea-management.idea" }, {
                    title: 'Edit',
                    component: "table-button",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-button",
                    isDelete: true,
                    disableSorting: true
                }],
                data: ideas
            };
        }),

        customFilters: Ember.computed('model.{ideas,ideas.[]}', function () {
            return this.get('model').ideas.meta;
        }),

        actions: {
            closeModal: function closeModal() {
                this.set('deleteModal', false);
            },
            deleteIdea: function deleteIdea(id) {
                var idea = this.get('store').peekRecord('initiative', id);
                this.set('currentIdea', idea);
                this.set('deleteModal', true);
            },
            confirmDeleteIdea: function confirmDeleteIdea() {
                var idea = this.get('currentIdea');
                idea.deleteRecord();
                idea.save();
                this.send('closeModal');
            },


            //adding idea
            addIdea: function addIdea() {
                this.set('newIdea', true);

                var randomId = Math.floor(Math.random() * 10000) + 1000;
                var record = this.get('store').createRecord('initiative', {
                    id: randomId
                });

                this.set('newIdea', {
                    initiative: record,
                    randomId: randomId
                });
            },
            closeAddModal: function closeAddModal() {
                //unload record if adding is canceled
                var initiative = this.get('store').peekRecord('initiative', this.get('newIdea').randomId);
                initiative.unloadRecord();
                this.set('newIdea', false);
            },
            saveIdea: function saveIdea(idea) {
                var _this = this;

                var self = this;
                var customFilters = this.get('customFilters');
                customFilters['initiative[exists]'] = true;
                customFilters['order'] = { 'created': 'DESC' };

                //set id to null before saving to server
                idea.set('id', null);
                idea.save().then(function () {

                    //updating initiatives on add
                    self.get('store').query('initiative', customFilters).then(function (ideas) {
                        _this.set('model.ideas', ideas);
                        self.set('newIdea', false);
                    });
                });
            },
            editIdea: function editIdea(id) {
                this.transitionToRoute('protected.idea-management.idea', id);
            },
            updateIdeas: function updateIdeas(params) {
                var _this2 = this;

                params['initiative[exists]'] = true;
                this.get('store').query('initiative', params).then(function (res) {
                    return _this2.set('model.ideas', res);
                });
            }
        }
    });
});