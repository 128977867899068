define('im-webapp/routes/protected/index', ['exports', 'moment', 'im-webapp/config/environment'], function (exports, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    model: function model() {

      var filter = {
        'initiative[exists]': false,
        include: 'status,followers,comments,timelineStatuses',
        order: {
          created: 'DESC'
        },
        'active': true,
        "created[after]": (0, _moment.default)().startOf('month').format(_environment.default.APP.FILTER_FORMAT),
        "created[before]": (0, _moment.default)().endOf('month').format(_environment.default.APP.FILTER_FORMAT)
      };

      var latestFilter = {
        'initiative[exists]': false,
        order: {
          created: 'DESC'
        },
        'active': true,
        itemsPerPage: 10,
        page: 1
      };

      return Ember.RSVP.hash({
        latestInitiatives: this.get('store').query('initiative', latestFilter),
        initiatives: this.get('store').query('initiative', filter),
        tags: this.get('store').findAll('tag'),
        categories: this.get('store').findAll('category'),
        status: this.get('store').findAll('status'),
        additionalFilters: filter
      });
    },
    actions: {
      loading: function loading() {
        this.controllerFor('protected').set('isLoading', true);
        this.router.on('didTransition', this, function () {
          this.controllerFor('protected').set('isLoading', false);
        });
      }
    }
  });
});