define('im-webapp/routes/protected/tag-management/tag', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Route.extend({
        routeCrud: service('routeCrud'),

        model: function model(params) {
            var routeCrud = this.get('routeCrud');
            var tag = routeCrud.getRecord(params, 'tag');

            return Ember.RSVP.hash({
                tag: tag.record,
                randomId: tag.randomId
            });
        }

    });
});