define('im-webapp/routes/protected/notifications', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),

        model: function model() {
            return {
                notifications: this.get('store').query('notification', {
                    includes: 'users'
                })
            };
        }
    });
});