define('im-webapp/components/validation-field', ['exports', 'moment', 'im-webapp/config/environment'], function (exports, _moment, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Component.extend({
        classNames: ['validation-field'],
        eyeIcon: true,
        momentFormat: _environment.default.APP.MOMENT_FORMAT,

        init: function init() {
            this._super.apply(this, arguments);
            //starting year for date picker
            var startYear = this.startYear || 2000;
            var currentYear = Number((0, _moment.default)().format('YYYY')) + 10;
            var totalYears = currentYear - startYear;

            this.set('years', Array.apply(undefined, _toConsumableArray(Array(totalYears))).map(function (_, i) {
                return '' + (i + startYear);
            }));
            this.set('months', _moment.default.months());

            if (this.get('isCheckbox') || this.get('isSwitch')) {
                this.set('disableLabel', true);
            }
        },


        selectedValue: Ember.computed('value', function () {
            return this.value;
        }),

        actions: {
            //toggle password and text
            toggleInputType: function toggleInputType() {
                var type = this.type;
                type === 'password' ? this.set('type', 'text') : this.set('type', 'password');
                this.toggleProperty('eyeIcon');
            },


            //select field
            didSelectValue: function didSelectValue(value) {
                this.set('selectedValue', value); //for select
                this.set('value', value); //for changeset
            },


            //datepicker
            changeCenter: function changeCenter(unit, calendar, val) {
                var newCenter = calendar.center.clone()[unit](val);
                calendar.actions.changeCenter(newCenter);
            },
            didSelectDate: function didSelectDate(date) {
                this.set('value', (0, _moment.default)(date, _environment.default.APP.MOMENT_FORMAT, true).format()); //for changeset
                this.set('selectedValue', date); //for input field and calendar
            },


            //switch and checkbox
            toggleValue: function toggleValue() {
                var self = this;
                this.set('selectedValue', !self.get('selectedValue'));
                this.set('value', self.get('selectedValue'));
            }
        }
    });
});