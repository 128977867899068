define('im-webapp/models/activity', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        creator: (0, _relationships.belongsTo)('user', { inverse: null }),
        created: (0, _attr.default)('string'),
        initiative: (0, _relationships.belongsTo)('initiative', { inverse: null }),
        event: (0, _attr.default)('string')
    });
});