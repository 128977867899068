define('im-webapp/routes/protected/initiative-management/initiative', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    routeCrud: Ember.inject.service('routeCrud'),

    model: function model(params) {
      var routeCrud = this.get('routeCrud');
      var initiative = routeCrud.getRecord(params, 'initiative', 'id', ['status,followers,comments,timelineStatuses']);

      return Ember.RSVP.hash({
        initiative: initiative.record,
        initiativeTimelineStatuses: this.get('store').query('timeline-status', { 'initiative': params.id, include: 'status' }),
        randomId: initiative.randomId,
        categories: this.get('store').findAll('category'),
        tags: this.get('store').findAll('tag'),
        status: this.get('store').findAll('status')
      });
    }

  });
});