define('im-webapp/components/regular-checkbox', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'label',
        classNames: ['checkbox-container'],
        actions: {
            toggled: function toggled() {
                this.get('ontoggle')();
            }
        }
    });
});