define('im-webapp/controllers/protected/idea/media', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            updateAttachments: function updateAttachments() {
                var self = this;
                var initiativeId = this.get('model.initiativeId');
                this.get('store').query('attachment', { initiative: initiativeId }).then(function (attachments) {
                    self.set('model.attachments', attachments);
                });
            }
        }
    });
});