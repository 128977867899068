define('im-webapp/controllers/protected/my-profile', ['exports', 'im-webapp/validations/user', 'im-webapp/config/environment'], function (exports, _user, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    userValidation: _user.default,
    customAjax: Ember.inject.service('customAjax'),
    i18n: Ember.inject.service(),
    toast: Ember.inject.service(),

    perStatusIdeas: Ember.computed('model.{perStatusIdeas,perStatusIdeas.[]}', 'i18n.locale', function () {

      var i18n = this.get('i18n');
      var perStatusIdeas = this.get('content').perStatusIdeas || [];

      var formattedIdea = perStatusIdeas.map(function (record) {
        return {
          name: record.status,
          y: record.count
        };
      });

      var data = [{
        name: i18n.t('statistics.number_of_ideas').string,
        colorByPoint: true,
        data: formattedIdea
      }];

      var options = {
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true //labels on chart
            },
            showInLegend: false //legend
          }
        }
      };

      return {
        options: options,
        data: data
      };
    }),

    perStatusInitiative: Ember.computed('model.{perStatusInitiatives,perStatusInitiatives.[]}', 'i18n.locale', function () {

      var i18n = this.get('i18n');

      var perStatusInitiatives = this.get('content').perStatusInitiatives || [];
      var formattedInitiative = perStatusInitiatives.map(function (record) {
        return {
          name: record.status,
          y: record.count
        };
      });

      var data = [{
        name: i18n.t('statistics.number_of_initiatives').string,
        colorByPoint: true,
        data: formattedInitiative
      }];

      var options = {
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true //labels on chart
            },
            showInLegend: false //legend
          }
        }
      };

      return {
        options: options,
        data: data
      };
    }),

    compareIdeas: Ember.computed('model.{comparingIdeas,comparingIdeas.[]}', 'i18n.locale', function () {

      var i18n = this.get('i18n');
      var comparingIdeas = this.get('content').comparingIdeas || [];
      var formattedData = comparingIdeas.map(function (record) {

        var arrayOfValues = [];

        for (var key in record) {
          if (key !== 'title') {
            arrayOfValues.push(record[key] ? record[key] : 0);
          }
        }
        return {
          name: record.title,
          data: arrayOfValues
        };
      });

      var options = {
        chart: {
          type: 'column'
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: [i18n.t('statistics.comments').string, i18n.t('statistics.dislikes').string, i18n.t('statistics.followers').string, i18n.t('statistics.likes').string]
        },
        yAxis: {
          title: {
            text: i18n.t('statistics.total_number').string
          }
        }
      };

      return {
        options: options,
        data: formattedData
      };
    }),

    compareInitiatives: Ember.computed('model.{comparingInitiatives,comparingInitiatives.[]}', 'i18n.locale', function () {

      var i18n = this.get('i18n');
      var compareInitiatives = this.get('content').comparingInitiatives || [];
      var formattedData = compareInitiatives.map(function (record) {
        var arrayOfValues = [];

        for (var key in record) {
          if (key !== 'title') {
            arrayOfValues.push(record[key] ? record[key] : 0);
          }
        }
        return {
          name: record.title,
          data: arrayOfValues
        };
      });

      var options = {
        chart: {
          type: 'column'
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: [i18n.t('statistics.comments').string, i18n.t('statistics.dislikes').string, i18n.t('statistics.followers').string, i18n.t('statistics.likes').string]
        },
        yAxis: {
          title: {
            text: i18n.t('statistics.total_number').string
          }
        }
      };

      return {
        options: options,
        data: formattedData
      };
    }),

    actions: {
      save: function save(changeset) {
        var changes = changeset.get('changes');
        changeset.validate();
        var isValid = changeset.get('isValid');
        var user = this.get('user');

        if (isValid) {
          changes.forEach(function (change) {
            user.set(change.key, change.value);
          });
          user.save();
        }
      },
      rollback: function rollback(changeset) {
        return changeset.rollback();
      },
      updateFiltering: function updateFiltering(filter) {
        var customAjax = this.get('customAjax');
        var toast = this.get('toast');
        var i18n = this.get('i18n');
        var self = this;
        var currentUserId = this.get('content').currentUser.get('id');

        var timeFilter = {
          start: filter['created[after]'],
          end: filter['created[before]'],
          owner: currentUserId
        };

        var ideasFilter = {
          start: timeFilter.start,
          end: timeFilter.end,
          owner: timeFilter.owner,
          initiative: true
        };

        var initiativesFilter = {
          start: timeFilter.start,
          end: timeFilter.end,
          owner: timeFilter.owner,
          initiative: false
        };

        //per status
        var urlPerStatus = _environment.default.APP.API_HOST + '/count-initiatives-per-status';

        //comparison
        var urlCompare = _environment.default.APP.API_HOST + '/compare-initiatives';

        customAjax.request('GET', urlPerStatus, ideasFilter, false).then(function (data) {
          self.set('model.perStatusIdeas', data);
        }).catch(function () {
          toast.error(i18n.t('error.server'), i18n.t('general.error'));
        });

        customAjax.request('GET', urlPerStatus, initiativesFilter, false).then(function (data) {
          self.set('model.perStatusInitiatives', data);
        }).catch(function () {
          toast.error(i18n.t('error.server'), i18n.t('general.error'));
        });

        customAjax.request('GET', urlCompare, ideasFilter, false).then(function (data) {
          self.set('model.comparingIdeas', data);
        }).catch(function () {
          toast.error(i18n.t('error.server'), i18n.t('general.error'));
        });

        customAjax.request('GET', urlCompare, initiativesFilter, false).then(function (data) {
          self.set('model.comparingInitiatives', data);
        }).catch(function () {
          toast.error(i18n.t('error.server'), i18n.t('general.error'));
        });
      }
    }
  });
});