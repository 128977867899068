define('im-webapp/models/notification', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        belongs: (0, _relationships.belongsTo)('user'),
        creator: (0, _relationships.belongsTo)('user'),
        created: (0, _attr.default)(''),
        message: (0, _attr.default)('string'),
        read: (0, _attr.default)('boolean'),
        link: (0, _attr.default)('string'),
        formattedLink: Ember.computed('link', function () {

            var link = this.get('link');
            var route = link.replace(/\//g, '.');

            return {
                url: route.replace(/.\d+/, ''),
                id: link.split('/').pop().trim()
            };
        })
    });
});