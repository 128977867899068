define('im-webapp/routes/protected/messages/private', ['exports', 'im-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        session: Ember.inject.service(),
        customAjax: Ember.inject.service('custom-ajax'),

        model: function model(params) {
            var currentUserId = this.get('session.data.authenticated.user_id');
            var chatUserId = params.id;

            if (chatUserId !== 'new') this.get('customAjax').request('GET', _environment.default.APP.API_HOST + '/check-messages-conversation/' + chatUserId);

            return Ember.RSVP.hash({
                messages: params.id !== 'new' && this.get('store').query('message', {
                    'from[]': [currentUserId, chatUserId],
                    'to[]': [currentUserId, chatUserId]
                }),
                isNew: params.id === 'new',
                users: params.id === 'new' && this.get('store').findAll('user'),
                chatUserId: Number(chatUserId),
                chatUser: params.id !== 'new' && this.get('store').findRecord('user', chatUserId)
            });
        }

    });
});