define('im-webapp/components/regular-dialog', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['dialog'],
    classNameBindings: ['isLarge:dialog--large', 'isSmall:dialog--small', 'isUpload:dialog--upload'],

    escapeToClose: true,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var self = this;
      if (this.get('escapeToClose')) {
        Ember.$(document).on('keydown', function (e) {
          e = e || window.event;
          if (e.keyCode === 27) {
            self.send('closeModal');
          }
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.get('escapeToClose')) {
        Ember.$(document).off('keydown');
      }
    },
    mouseUp: function mouseUp(ev) {
      var self = this;
      if (Ember.$(ev.target).parent().attr('id') === this.elementId) {
        ev.stopPropagation();
        ev.preventDefault();
        self.send('closeModal');
      }
    },


    actions: {
      closeModal: function closeModal() {
        this.get('closeModal')();
      }
    }

  });
});