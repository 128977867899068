define('im-webapp/components/color-picker', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['color-picker'],

        init: function init() {
            this._super.apply(this, arguments);
            var hexColors = ["#f03434", "#ec644b", "#db0a5b", "#d2527f", "#663399", "#aea8d3", "#bf55ec", "#19b5fe", "#3d6cae", "#1f3a93", "#2ecc71", "#03a678", "#f7ca18", "#e87e04", "#da561f"];
            var styled = hexColors.map(function (color) {
                return Ember.String.htmlSafe("background-color:" + color);
            });
            this.set('colorBoxStyles', styled);
        },


        currentColorBoxStyle: Ember.computed('currentColor', function () {
            return this.currentColor ? Ember.String.htmlSafe("background-color:" + this.currentColor) : Ember.String.htmlSafe("background-color:#1f3a93");
        }),

        currentColorBorderStyle: Ember.computed('currentColor', function () {
            return this.currentColor ? Ember.String.htmlSafe('border: 1px solid' + this.currentColor) : Ember.String.htmlSafe('border: 1px solid #1f3a93');
        }),

        actions: {
            selectColor: function selectColor(color) {
                this.set('currentColor', color.substring(17));
            }
        }

    });
});