define('im-webapp/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'im-webapp/config/environment', 'fetch'], function (exports, _oauth2PasswordGrant, _environment, _fetch) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var assign = Ember.assign || Ember.merge;

    exports.default = _oauth2PasswordGrant.default.extend({
        toast: Ember.inject.service('toast'),
        serverTokenEndpoint: _environment.default.APP.API_HOST + '/login_check',
        tokenRefreshOffset: 10000,
        i18n: Ember.inject.service('i18n'),

        makeRequest: function makeRequest(url, data) {
            var headers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};


            //custom headers and body
            headers['Content-Type'] = 'application/json';
            var body = JSON.stringify(data);

            var options = {
                body: body,
                headers: headers,
                method: 'POST'
            };

            var clientIdHeader = this.get('_clientIdHeader');
            if (!Ember.isEmpty(clientIdHeader)) {
                Ember.merge(options.headers, clientIdHeader);
            }
            //TODO avoid faking access token in response object
            return new Ember.RSVP.Promise(function (resolve, reject) {
                (0, _fetch.default)(url, options).then(function (response) {
                    response.text().then(function (text) {
                        try {
                            var json = JSON.parse(text);
                            if (!response.ok) {
                                response.responseJSON = json;
                                reject(response);
                            } else {
                                json['access_token'] = json['token'];
                                delete json['token'];
                                return resolve(json);
                            }
                        } catch (SyntaxError) {
                            response.responseText = text;
                            reject(response);
                        }
                    });
                }).catch(reject);
            });
        },
        _refreshAccessToken: function _refreshAccessToken(expiresIn, refreshToken) {
            var _this = this;

            var i18n = this.get('i18n');
            var userId = JSON.parse(localStorage['ember_simple_auth-session']).authenticated.user_id;
            var data = { 'grant_type': 'refresh_token', 'refresh_token': refreshToken };
            var serverTokenEndpoint = _environment.default.APP.API_HOST + '/token/refresh';

            return new Ember.RSVP.Promise(function (resolve, reject) {
                _this.makeRequest(serverTokenEndpoint, data).then(function (response) {
                    Ember.run(function () {
                        expiresIn = response['expires_in'] || expiresIn;
                        refreshToken = response['refresh_token'] || refreshToken;
                        var expiresAt = _this._absolutizeExpirationTime(expiresIn);
                        var data = assign(response, { 'expires_in': expiresIn, 'expires_at': expiresAt, 'refresh_token': refreshToken, 'user_id': userId });
                        _this._scheduleAccessTokenRefresh(expiresIn, null, refreshToken);
                        _this.trigger('sessionDataUpdated', data);
                        resolve(data);
                    });
                }, function () {
                    _this.get('toast').error(i18n.t('error.refresh_token'), i18n.t('general.error'));
                    reject();
                });
            });
        }
    });
});