define('im-webapp/components/activity-feed-row', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    classNames: ['activity-feed-row'],

    didReceiveAttrs: function didReceiveAttrs() {
      this.set('timeAgo', (0, _moment.default)(this.get('activity.created')).fromNow());
    },


    eventText: Ember.computed('activity.text', function () {
      return 'activity.' + this.get('activity.event');
    })

  });
});