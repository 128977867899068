define('im-webapp/models/initiative', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'moment'], function (exports, _model, _attr, _relationships, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        title: (0, _attr.default)('string'),
        content: (0, _attr.default)('string'),
        created: (0, _attr.default)('date'),
        owner: (0, _relationships.belongsTo)('user'), //author
        cover: (0, _attr.default)('string'),
        category: (0, _relationships.belongsTo)('category'),
        tags: (0, _relationships.hasMany)('tag', { inverse: null }),
        status: (0, _relationships.belongsTo)('status', { inverse: null }), //current status
        timelineStatuses: (0, _relationships.hasMany)('timeline-status', { inverse: null }),
        sortedStatuses: Ember.computed.sort('timelineStatuses', function (a, b) {
            return new Date(a.get('startDate')) - new Date(b.get('startDate'));
        }),
        comments: (0, _relationships.hasMany)('comments'),
        initiative: (0, _relationships.belongsTo)('initiative', { inverse: null }), //parent initiative (for idea)
        ideas: (0, _relationships.hasMany)('initiative', { inverse: null }), //children initiatives (for initiative)
        followers: (0, _relationships.hasMany)('user'),
        attachments: (0, _relationships.hasMany)('attachment', { inverse: null }),
        likes: (0, _relationships.hasMany)('user'),
        dislikes: (0, _relationships.hasMany)('user'),
        active: (0, _attr.default)('boolean'),
        votingPassed: Ember.computed('sortedStatuses.[]', function () {
            var timelineStatuses = this.get('sortedStatuses');
            var votingPassed = false;

            var filteredStatuses = timelineStatuses.filter(function (status) {
                if (status.get('status.voting')) {
                    return status;
                }
            });

            filteredStatuses.forEach(function (status, index) {
                var nextStatusIndex = ++index;
                if ((0, _moment.default)().diff(status.get('expireDate'), 'days') > 0 && !filteredStatuses[nextStatusIndex]) {
                    votingPassed = true;
                }
            });

            return votingPassed;
        })
    });
});