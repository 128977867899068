define('im-webapp/routes/protected', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'im-webapp/config/environment'], function (exports, _authenticatedRouteMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),
    session: Ember.inject.service('session'),
    customAjax: Ember.inject.service('customAjax'),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      this._loadCurrentUser();
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var _this = this;

      return this.get('currentUser').load().catch(function () {
        return _this.get('session').invalidate();
      });
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      this._loadCurrentUser();
    },


    model: function model() {
      var customAjax = this.get('customAjax');
      var toast = this.get('toast');
      var i18n = this.get('i18n');
      var userId = this.get('session.data.authenticated.user_id');
      var url = _environment.default.APP.API_HOST + "/get-messages-conversation/" + userId;

      var filter = {
        itemsPerPage: 10,
        include: 'creator,initiative',
        order: {
          created: 'DESC'
        },
        page: 1
      };

      return Ember.RSVP.hash({
        currentUser: this.get('currentUser').load(),
        activityFeed: this.get('store').query('activity', filter),
        unreadMessages: this.get('store').query('message', {
          read: false,
          belongs: userId
        }).map(function (msg) {
          if (msg.get('to.id') == userId) {
            return msg;
          }
        }),
        unreadNotifications: this.get("store").query('notification', {
          read: false,
          belongs: userId
        }),
        lastConversations: customAjax.request('GET', url).then(function (conversations) {
          return conversations;
        }).catch(function () {
          toast.error(i18n.t('error.server'), i18n.t('general.error'));
        }),
        filter: filter
      });
    },

    actions: {
      refreshModel: function refreshModel(callingFromParent) {
        if (callingFromParent) this.refresh();
      }
    }
  });
});