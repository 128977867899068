define('im-webapp/components/timeline-status-form', ['exports', 'im-webapp/validations/timeline', 'moment', 'im-webapp/config/environment'], function (exports, _timeline, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['timeline-status-form'],
    classNameBindings: ['isConfirmed:confirmed-status'],
    timelineStatusValidation: _timeline.default,
    momentFormat: _environment.default.APP.MOMENT_FORMAT,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('selectedPeriod', {
        start: (0, _moment.default)().startOf('week'),
        end: (0, _moment.default)().endOf('week')
      });

      if (this.get('timelineStatus.startDate') && this.get('timelineStatus.expireDate')) {
        this.set('timelineStatus.isConfirmed', true);

        this.set('selectedPeriod', {
          start: this.get('timelineStatus.startDate'),
          end: this.get('timelineStatus.expireDate')
        });
      }
      this.set('wrongPeriod', false);
    },


    actions: {
      changeCenter: function changeCenter(unit, calendar, val) {
        var newCenter = calendar.center.clone()[unit](val);
        calendar.actions.changeCenter(newCenter);
      },
      confirmTimelineStatus: function confirmTimelineStatus(changeset) {

        var changes = changeset.get('changes');
        changeset.validate();
        var isValid = changeset.get('isValid');
        var timelineStatus = this.get('timelineStatus');
        var self = this;

        var selectedPeriod = this.get('selectedPeriod');

        if (isValid) {

          if (selectedPeriod.start && selectedPeriod.end) {
            changes.forEach(function (change) {
              timelineStatus.set(change.key, change.value);
            });

            //set values from period select
            timelineStatus.set('startDate', (0, _moment.default)(selectedPeriod.start).format());
            timelineStatus.set('expireDate', (0, _moment.default)(selectedPeriod.end).format());

            self.get('confirmTimelineStatus')(timelineStatus);
            self.set('wrongPeriod', false);
          } else {
            self.set('wrongPeriod', true);
          }
        }
      },
      removeStatus: function removeStatus(timelineStatus) {
        this.set('confirmDeleteDialog', true);
        this.set('currentStatus', timelineStatus);
      },
      confirmDeleteStatus: function confirmDeleteStatus() {
        this.get('confirmDeleteStatus')(this.get('currentStatus'));
        this.set('confirmDeleteDialog', false);
      },
      closeModal: function closeModal() {
        this.set('confirmDeleteDialog', false);
      },
      editTimelineStatus: function editTimelineStatus() {
        this.set('timelineStatus.isConfirmed', false);
        this.set('editState', true);
        this.get('editTimelineStatus')();
      }
    }
  });
});