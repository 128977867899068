define('im-webapp/components/feedback-reactions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['feedback-wrapper'],

    init: function init() {
      this._super.apply(this, arguments);
      this.feedbackItems = [{
        icon: 'comment',
        reaction: '2'
      }, {
        icon: 'user',
        reaction: '1'
      }, {
        icon: 'thumbs-up-1',
        reaction: '7'
      }, {
        icon: 'thumbs-down-1',
        reaction: '5'
      }];
    }
  });
});