define('im-webapp/services/toast', ['exports', 'ember-toastr/services/toast'], function (exports, _toast) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _toast.default;
    }
  });
});