define('im-webapp/services/counter', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        checkArray: function checkArray(initiative, prop) {
            var data = initiative.get(prop);
            var array = data.get('content').currentState;
            return {
                number: array && array.length > 0 ? array.length : 0,
                availableModal: array && array.length > 0
            };
        }
    });
});