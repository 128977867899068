define('im-webapp/services/custom-ajax', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Service.extend({
        session: service('session'),

        request: function request(requestType, url) {
            var jsonData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
            var stringify = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

            var session = this.get('session').get('session');

            var data = void 0;

            if (jsonData) {
                if (stringify) {
                    data = JSON.stringify(jsonData);
                } else {
                    data = jsonData;
                }
            }

            var accessToken = void 0;

            if (session) {
                accessToken = "Bearer " + session.content.authenticated.access_token;
            }

            var contentType = 'application/vnd.api+json';
            return new Ember.RSVP.Promise(function (resolve, reject) {
                Ember.$.ajax({
                    type: requestType,
                    url: url,
                    data: data,
                    contentType: contentType,

                    beforeSend: function beforeSend(xhr) {
                        if (accessToken) {
                            xhr.setRequestHeader("authorization", accessToken);
                        }
                    },

                    /**
                     * Put "status" in success functions is something wrong
                     */
                    success: function success(response) {
                        return resolve(response);
                    },
                    /**
                     * Put "textStatus, errorThrown" in success functions is something wrong
                     */
                    error: function error(_error) {
                        return reject(_error);
                    }
                });
            });
        }
    });
});