define('im-webapp/routes/protected/idea-management/idea', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    routeCrud: Ember.inject.service('routeCrud'),

    model: function model(params) {

      var routeCrud = this.get('routeCrud');
      var idea = routeCrud.getRecord(params, 'initiative', 'id', ['status,followers,comments,timelineStatuses']);

      return Ember.RSVP.hash({
        idea: idea.record,
        randomId: idea.randomId,
        ideaTimelineStatuses: this.get('store').query('timeline-status', { 'initiative': params.id, include: 'status' }),
        categories: this.get('store').findAll('category'),
        initiatives: this.get('store').query('initiative', {
          pagination: false,
          'initiative[exists]': false
        }),
        tags: this.get('store').findAll('tag'),
        status: this.get('store').findAll('status')
      });
    }

  });
});