define('im-webapp/components/regular-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['btn'],
    classNameBindings: ['primary:btn--primary', 'secondary:btn--secondary', 'simple:btn--simple', 'simpleReverse:btn--simpleReverse', 'circle:btn--circle', 'circleSimple:btn--circleSimple', 'danger:btn--danger', 'small:btn--small', 'large:btn--large', 'warning:btn--warning'],
    attributeBindings: ['type', 'disabled']
  });
});