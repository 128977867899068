define('im-webapp/controllers/protected/initiative', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        actions: {
            addIdea: function addIdea() {
                this.set('newIdea', true);

                var randomId = Math.floor(Math.random() * 10000) + 1000;
                var record = this.get('store').createRecord('initiative', {
                    id: randomId
                });

                this.set('newIdea', {
                    idea: record,
                    randomId: randomId
                });
            },
            closeModal: function closeModal() {
                //unload record if adding is canceled
                var idea = this.get('store').peekRecord('initiative', this.get('newIdea').randomId);
                idea.unloadRecord();
                this.set('newIdea', false);
            },
            saveIdea: function saveIdea(idea) {
                var self = this;
                var initiative = this.get('model.initiative');
                //set id to null before saving to server
                idea.set('id', null);
                //set parent initiative
                idea.set('initiative', initiative);
                idea.save().then(function () {
                    //update initiative
                    self.get('store').findRecord('initiative', initiative.get('id'), { include: 'status,followers,comments,timelineStatuses' });
                    self.set('newIdea', false);
                });
            }
        }

    });
});