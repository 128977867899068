define('im-webapp/components/filter-wrapper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    classNames: ['filter-wrapper row'],

    actions: {
      updateInitiatives: function updateInitiatives(type, params) {
        var filter = this.get('filter') ? this.get('filter') : {};
        if (type === 'search') {
          Object.assign.apply(Object, [filter].concat(_toConsumableArray(filter), [{ search: params }]));
          //filter case
        } else {
          Object.assign.apply(Object, [filter].concat(_toConsumableArray(filter), [params]));
        }

        this.set('filter', filter);
        this.get('updateInitiatives')(filter);
      },
      toggleLayout: function toggleLayout() {
        this.get('toggleLayout')();
      }
    }
  });
});