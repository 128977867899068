define('im-webapp/components/user-form', ['exports', 'im-webapp/validations/user'], function (exports, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['form'],
    userValidation: _user.default,
    user: null,
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),

    init: function init() {
      this._super.apply(this, arguments);

      //all languages
      var locales = this.get('i18n.locales').map(function (loc) {
        return { value: loc, text: 'languages.' + loc };
      });

      this.set('allLanguages', locales);

      //current user language
      var currentLocale = this.get('user.locale') || 'en';

      var self = this;
      locales.forEach(function (locale) {
        if (locale.value === currentLocale) {
          self.set('selectedLanguage', {
            value: locale.value,
            text: locale.text
          });
        }
      });

      var roleObject = {
        'ROLE_USER': {
          label: 'user.user_role',
          value: 'ROLE_USER'
        },
        'ROLE_ADMIN': {
          label: 'user.admin_role',
          value: 'ROLE_ADMIN'
        }
      };

      //all available roles
      var allRoles = [];
      for (var key in roleObject) {
        allRoles.push(roleObject[key]);
      }
      this.set('allRoles', allRoles);

      //select roles formatting
      var roles = this.get('user.roles');
      var formattedRoles = [];

      if (roles && roles.length > 0) {
        formattedRoles = roles.map(function (role) {
          return roleObject[role];
        });
      } else {
        //default is user role
        formattedRoles = [{
          label: 'user.user_role',
          value: 'ROLE_USER'
        }];
      }
      this.set('selectedRoles', formattedRoles);
    },


    actions: {
      save: function save(changeset) {
        var changes = changeset.get('changes');
        changeset.validate();
        var isValid = changeset.get('isValid');
        var user = this.get('user');
        var self = this;
        var selectedLanguage = this.get('selectedLanguage').value;
        var selectedRoles = this.get('selectedRoles');

        var formattedRoles = selectedRoles.map(function (role) {
          return role.value;
        });

        if (isValid) {
          changes.forEach(function (change) {
            user.set(change.key, change.value);
          });

          user.set('roles', formattedRoles);
          user.set('locale', selectedLanguage);

          //case if current user is changing language in user management
          if (+user.get('id') === this.get('session.data.authenticated.user_id')) {
            self.set('i18n.locale', selectedLanguage);
            //update selected language in top-bar select
            self.get('eventBus').publish('updateSelectedLanguage');
          }

          self.sendAction('save', user);
        }
      },
      rollback: function rollback(changeset) {
        var user = this.get('user');
        this.get('closeModal')(user);
        return changeset.rollback();
      }
    }
  });
});