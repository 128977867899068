define('im-webapp/controllers/protected/messages/private', ['exports', 'im-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        momentFormat: _environment.default.APP.FULL_MOMENT_FORMAT,
        session: Ember.inject.service(),
        ws: Ember.inject.service('ws'),

        actions: {
            didSelectUser: function didSelectUser(selectedUser) {
                this.set('selectedUser', selectedUser);
                var currentUserId = this.get('session.data.authenticated.user_id');
                var self = this;

                self.get('store').query('message', {
                    'from[]': [currentUserId, selectedUser.get('id')],
                    'to[]': [currentUserId, selectedUser.get('id')]
                }).then(function (messages) {
                    if (messages.content && messages.content.length > 0) {
                        self.set('model.messages', messages);
                        self.set('model.chatUserId', selectedUser.get('id'));
                    } else {
                        self.set('model.messages', []);
                    }
                });
            },
            sendMessage: function sendMessage(content, selectedUser) {
                var self = this;

                if (content && !this.get('sendingMessage')) {

                    //prevent sending multiple messages before server response
                    self.set('sendingMessage', true);

                    var currentUserId = this.get('session.data.authenticated.user_id');
                    var currentUser = this.get('store').peekRecord('user', currentUserId);
                    var chatUserId = this.get('model.isNew') ? selectedUser.get('id') : this.get('model.chatUserId');
                    var isNew = this.get('model.isNew');
                    var message = this.get('store').createRecord('message');
                    message.set('from', currentUser);
                    message.set('content', content);

                    if (!isNew) {
                        self.get('store').findRecord('user', chatUserId).then(function (chatUser) {
                            message.set('to', chatUser);
                            message.save().then(function () {
                                self.set('sendingMessage', false);
                            });
                        });
                    } else {
                        message.set('to', selectedUser);
                        message.save().then(function () {
                            self.set('sendingMessage', false);
                        });
                    }
                    self.set('newMessage', null);
                    this.get('ws').createUserSocketMessage(content, chatUserId);
                }
            }
        }
    });
});