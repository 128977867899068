define('im-webapp/components/login-form', ['exports', 'im-webapp/validations/login'], function (exports, _login) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['login-form'],
        session: Ember.inject.service('session'),
        toast: Ember.inject.service(),
        i18n: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);
            this.set('user', {
                username: '',
                password: ''
            });
        },


        loginValidation: _login.default,

        actions: {
            authenticateWithToken: function authenticateWithToken(changeset) {
                changeset.validate();
                var isValid = changeset.get('isValid');
                if (isValid) {
                    var toast = this.get('toast');
                    var i18n = this.get('i18n');
                    var identification = changeset.get('username');
                    var password = changeset.get('password');
                    this.get('session').authenticate('authenticator:oauth2', identification, password).catch(function (error) {
                        //TODO Add translations after back error is known
                        toast.error(error || error ? error.message : i18n.t('general.error'), i18n.t('general.error'));
                    });

                    //add images on load
                    var itemsPerPage = 30;
                    var page = 30;
                    var category = 'idea';
                    var ACCESS_KEY = 'b01c45a4cf4681962173b43144baf022d0065973f5e0f795a2610d095472e54e';
                    var apiPhotosUrl = 'https://api.unsplash.com/search/collections?page=' + page + '&per_page=' + itemsPerPage + '&client_id=' + ACCESS_KEY + '&query=' + category;
                    var xhr = new XMLHttpRequest();
                    xhr.open('GET', apiPhotosUrl, true);
                    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                    xhr.onload = function () {
                        if (200 === this.status) {
                            var formattedResponse = JSON.parse(this.response);
                            var imageLinks = [];
                            formattedResponse.results.forEach(function (img) {
                                imageLinks.push(img.cover_photo.urls.regular);
                            });
                            localStorage.setItem("im_img_links", JSON.stringify(imageLinks));
                        } else {
                            toast.error(i18n.t('error.server'), i18n.t('general.error'));
                        }
                    };
                    xhr.send();
                }
            }
        }
    });
});