define('im-webapp/routes/protected/category-management/category', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Route.extend({
        routeCrud: service('routeCrud'),

        model: function model(params) {
            var routeCrud = this.get('routeCrud');
            var category = routeCrud.getRecord(params, 'category');
            return Ember.RSVP.hash({
                category: category.record,
                randomId: category.randomId
            });
        }

    });
});