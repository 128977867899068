define('im-webapp/controllers/protected/initiative-management/initiative', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        openModal: true,

        actions: {
            closeModal: function closeModal() {
                //unload record if adding is canceled
                var initiative = this.get('model.initiative');
                if (this.get('model.randomId')) {
                    initiative.unloadRecord();
                }
                this.transitionToRoute('protected.initiative-management');
            },
            save: function save(initiative) {
                var self = this;
                //set id to null before saving to server
                if (this.get('model.randomId')) {
                    initiative.set('id', null);
                }
                initiative.save().then(function () {
                    self.transitionToRoute('protected.initiative-management');
                });
            }
        }

    });
});