define('im-webapp/controllers/protected/user-management', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        i18n: Ember.inject.service(),

        usersTable: Ember.computed('model.{users}', '18n.locale', function () {

            var users = this.get('model').users;

            return {
                header: [{ title: 'Full name', propertyName: 'fullName', routeName: "protected.user-management.user" }, {
                    title: 'Edit',
                    component: "table-button",
                    disableSorting: true
                }, {
                    title: 'Status',
                    component: "table-button",
                    isActive: true,
                    disableSorting: true
                }],
                data: users
            };
        }),

        actions: {
            addUser: function addUser() {
                this.transitionToRoute('protected.user-management.user', 'new');
            },
            editUser: function editUser(id) {
                this.transitionToRoute('protected.user-management.user', id);
            }
        }
    });
});