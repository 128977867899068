define('im-webapp/components/file-uploader', ['exports', 'im-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service('session'),
        toast: Ember.inject.service('toast'),
        i18n: Ember.inject.service(),
        classNames: ['file-upload-wrapper'],

        init: function init() {
            this._super.apply(this, arguments);
            this.set('fileList', []);
        },


        actions: {

            /**
             * uploadFile uploads image on server. Uses response link for sending it to controller. Error handling included
             * @method uploadFile
             * @param file
             */
            uploadFile: function uploadFile(file) {
                var toast = this.get('toast');
                var i18n = this.get('i18n');
                var session = this.get('session').get('session');
                var accessToken = "Bearer " + session.content.authenticated.access_token;
                var url = _environment.default.APP.API_HOST + '/upload';
                var self = this;
                var type = this.get('type');

                var options = {
                    url: url,
                    headers: {
                        "authorization": accessToken
                    },
                    accepts: ['application/json', 'text/javascript', 'text/plain'],
                    fileKey: type === 'cover' ? 'avatar' : type
                };

                file.upload(options).then(function (response) {
                    if (type === 'avatar' || type === 'cover') {
                        self.set('filePath', response.body.data.path);
                        self.sendAction('imageUploaded', response.body.data.path);
                    } else {
                        self.sendAction('addAttachment', response.body.data.id);
                    }
                }, function () {
                    toast.error(i18n.t('error.server'), i18n.t('general.error'));
                });
            }
        }

    });
});