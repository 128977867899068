define('im-webapp/locales/fr/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'general': {
      'send': 'Envoyer',
      'email': 'Email',
      'confirm': 'Confirmer',
      'save': 'Enregistrer',
      'delete': 'Supprimer',
      'cancel': 'Annuler',
      'error': "Erreur",
      'invalid': "Invalide",
      'successful': "Réussi",
      'active': "Actif",
      'homepage': 'Page d\'accueil',
      'are_you_sure': 'Êtes-vous sûr de vouloir supprimer ',
      'search': 'Recherche',
      'add': 'Ajouter',
      'edit': 'Éditer',
      'period': 'Période',
      'welcome': 'Bienvenue',
      'title': 'Titre',
      'desc': 'Description',
      'categories': 'Catégories',
      'tags': 'Marqueurs',
      'date': 'Date',
      'av_rate': 'taux moyen',
      'read_more': 'Lire plus',
      'read_less': 'Lire moins',
      'close': 'Fermer',
      'follow': 'Suivre',
      'unfollow': 'Arrêtez la surveillance',
      'prev': 'Précédent',
      'next': 'Suivant',
      'records_per_page': 'Enregistrements par page',
      'current_page': 'Page actuelle',
      'show': 'Afficher',
      'of': 'de',
      'columns': 'Colonnes',
      'show_all': 'Montrer tout',
      'hide_all': 'Tout cacher',
      'restore_defaults': 'Restaurer les valeurs par défaut',
      'no_data': 'Aucun enregistrement à afficher',
      'see_all': 'Voir tout',
      'home': 'début'
    },
    'auth': {
      'username': 'Nom d\'utilisateur',
      'password': 'Mot de passe',
      'forgot_password': 'Mot de passe oublié',
      'login': 'Connexion',
      'logout': 'Déconnexion',
      'back_to_login': 'Retour à la connexion',
      'confirm_password': 'Confirmation du mot de passe',
      'recovery_password': 'Récupération de mot de passe',
      'password_changed': 'Mot de passe changé',
      'change_password': 'Changer le mot de passe',
      'new_password': 'Nouveau mot de passe',
      'successful_update_password': 'Mot de passe mis à jour avec succès',
      'password_is_incorrect': 'Le mot de passe est incorrect',
      'remember_me': 'Se souvenir de moi',
      'email_with_instructions': 'Un email avec les instructions est envoyé à votre email'
    },
    'error': {
      'bad_request': 'Mauvaise demande',
      'resource_not_found': 'Ressource non trouvée',
      'unauthorized': 'Non autorisé',
      'no_permission': 'Vous n\'avez pas la permission pour cette action',
      'not_allowed': 'L\'action n\'est pas autorisée',
      'time_out': 'Temps expiré',
      'too_many': 'Trop de demandes en même temps',
      'server': 'Erreur de serveur',
      'refresh_token': 'Le jeton d\'accès n\'a pas pu être actualisé',
      'start_end_period': 'Veuillez sélectionner la date de début et la date de fin',
      'start_before_expired': 'La date de fin doit être postérieure à la date de début'
    },
    'notify': {
      'updated': "  mis à jour avec succès",
      'added': " ajouté avec succès",
      'deleted': "Enregistrement supprimé avec succès",
      'tags': 'Marqueur',
      'categories': 'Catégorie',
      'users': 'Utilisateur',
      'companies': 'Entreprise',
      'statuses': 'Statut',
      'groups': 'Groupe',
      'initiatives': 'Initiative',
      'idea': 'Idée',
      'comments': 'Commentaire',
      'timeline-statuses': 'Statut de période'
    },
    'category': {
      'category': 'Catégorie',
      'category_management': 'Gestion des catégories',
      'name': 'Nom de la catégorie',
      'desc': 'Description de la catégorie',
      'add_category': 'Ajouter une catégorie',
      'select_categories': 'Sélectionnez une catégories ...'
    },
    'tag': {
      'tag': 'Marqueur',
      'tag_management': 'Gestion des marqueurs',
      'name': 'Nom du marqueur',
      'add_tag': 'Ajouter un marqueur',
      'select_tags': 'Sélectionnez un marqueur ...'
    },
    'user': {
      'user': 'Utilisateur',
      'first_name': 'Prénom',
      'last_name': 'Nom de famille',
      'full_name': 'Nom complet',
      'group': 'Groupe',
      'my_profile': 'Mon profil',
      'my_ideas': 'Mes idées',
      'my_initiatives': 'Mes initiatives',
      'my_feed': 'Mes nouvelles',
      'user_management': 'Gestion des utilisateurs',
      'roles': 'Rôles d\'utilisateur',
      'add_user': 'Ajouter un utilisateur',
      'admin_role': 'Administrateur',
      'user_role': 'Rôle d\'utilisateur',
      'active': 'Actif',
      'inactive': 'Inactif'
    },
    'group': {
      'group': 'Groupe',
      'group_management': 'Gestion de groupe',
      'name': 'Nom du groupe',
      'desc': 'Description du groupe',
      'add_group': 'Ajouter un groupe'
    },
    'status': {
      'status': 'Statut',
      'status_management': 'Gestion du statut',
      'name': 'Nom de statut',
      'desc': 'Description du statut',
      'active': 'Statut actif',
      'add_status': 'Ajouter un statut',
      'color': 'Couleur de statut',
      'select_statuses': 'Sélectionner les statuts ...',
      'expire_date': 'Date de fin',
      'start_date': 'Date de début',
      'voting': 'Statut de vote',
      'final_status': 'Statut final',
      'pending': 'En attente',
      'approved': 'Approuvé',
      'declined': 'Refusé'
    },
    'initiative': {
      'initiative': 'Initiative',
      'initiative_management': 'Gestion d\'initiative',
      'title': 'Titre de l\'initiative',
      'desc': 'Description de l\'initiative',
      'categories': 'Catégories d\'initiative',
      'tags': 'Marqueur d\'initiative',
      'add_initiative': 'Ajouter une initiative',
      'follow_initiative': 'Suivre l\'initiative',
      'unfollow_initiative': 'Arrête de suivre l\'initiative',
      'followers': 'Suiveurs',
      'latest': 'Dernières initiatives',
      'available_statuses': 'Statuts disponibles',
      'current_status': 'Statut actuel',
      'unrated': 'Non évalué',
      'like': 'initiatives approuvées',
      'created': 'Initiatives créés',
      'follow': 'initiatives suivies',
      'unfollow': 'initiatives non suivies',
      'comment': 'initiatives commentées',
      'dislike': 'Initiatives qui ne sont pas aimées',
      'attachment': 'pièce jointe téléchargée sur initiative',
      'feed': 'nouvelles de l\'initiative'
    },
    'idea': {
      'idea': 'Idée',
      'ideas': 'Idées',
      'idea_management': 'Gestion des idées',
      'add_idea': 'Ajouter une idée',
      'follow_idea': 'Suivre l\'idée',
      'unfollow_idea': 'Idées non suivies',
      'idea_feed': 'Nouvelles d\'idées',
      'like': 'idées approuvées',
      'created': 'idées créées',
      'follow': 'suivi d\'idées',
      'unfollow': 'Idées qui ne sont pas suivies',
      'comment': 'commentaires pour idées',
      'dislike': 'idées qui ne sont pas aimées',
      'attachment': 'ajout d\'une pièce jointe à une idée',
      'feed': 'nouvelles de l\'idée'
    },
    'upload': {
      'drag_to_upload': 'glisser pour télécharger',
      'drag_and_drop_area': 'Zone de glisser-déposer',
      'upload_new_file': 'Télécharger un nouveau fichier'
    },
    'comments': {
      'comments': 'Commentaires',
      'comment': 'Commentaire',
      'required_field': 'Le commentaire ne peut pas être vide',
      'comment_text': 'Donnez votre commentaire ...'
    },
    'media': {
      'media': 'Médium',
      'no_media_added': 'Aucun médium ajouté',
      'media_library': 'Médiathèque',
      'media_avatar': 'Aperçu du fichier',
      'media_name': 'Nom du média',
      'media_author': 'Auteur',
      'media_actions': 'Actions',
      'initiative_idea': 'Initiative / idée'
    },
    'languages': {
      'language': 'Langue',
      'en': 'En',
      'rs': 'Sr',
      'el': 'El',
      'es': 'Es',
      'fr': 'Fr',
      'it': 'It',
      'pt': 'Pt',
      'ru': 'Ru'
    },
    'messages': {
      'messages': 'Messages',
      'type_message': 'Tapez un message ...',
      'new_message': 'Nouveau message',
      'welcome': 'Bienvenue aux messages!',
      'select_user': 'Sélectionner un utilisateur'
    },
    'notifications': {
      'notifications': 'Notifications'
    },
    'image': {
      'change_image': 'Changer l\'image',
      'confirm_image_note': 'Si vous souhaitez conserver cette image, veuillez confirmer.'
    },
    'like': {
      'likes': 'Aime',
      'dislikes': 'N\'aime pas',
      'voting_finished': 'Le vote est terminé'
    },
    'validations': {
      'password_must_match': 'Le mot de passe doit correspondre',
      'email': 'Doit être une adresse email valide',
      'not_a_number': 'Doit être un nombre',
      'present': 'Ne peut pas être vide',
      'too_short': 'Trop court (minimum [min] caractères)',
      'invalid': 'Invalide',
      'password_visible': 'Masquer le mot de passe',
      'password_invisible': 'Afficher le mot de passe'
    },
    'table': {
      'title': 'Titre',
      'edit': 'Changer l\'image',
      'delete': 'Supprimer',
      'fullname': 'Nom complet',
      'name': 'Nom',
      'desc': 'Description',
      'tag': 'Marqueur',
      'status': 'Statut'
    },
    'slider': {
      'empty_slider': 'Aucune initiative'
    },
    'timeline': {
      'already_selected_days': 'Vous avez déjà été sélectionné ces jours-ci',
      'change_available_days': '  Veuillez d\'abord modifier les autres statuts et rendre ces jours disponibles'
    },
    'activity': {
      'like': 'aimé',
      'created': 'créé',
      'follow': 'suivi',
      'unfollow': 'non suivi',
      'comment': 'commenté',
      'dislike': 'N\'aime pas',
      'attachment': 'pièce jointe ajoutée',
      'status changed': 'Statut changé',
      'delete': 'effacer',
      'remove like': 'aime supprimé',
      'remove dislike': 'n\'aime pas  supprimé',
      'remove comment': 'commentaire supprimé',
      'remove attachment': 'pièce jointe supprimée',
      'feed': 'nouvelles de l\'activité',
      'my_feed': 'Mes nouvelles'
    },
    'statistics': {
      'statistics': 'Les statistiques',
      'ideas_per_status': 'Idées par statut',
      'initiatives_per_status': 'Initiatives par statut',
      'number_of_ideas': 'Nombre d\'idées',
      'number_of_initiatives': 'Nombre d\'initiatives',
      'initiatives_per_idea': 'Initiatives par nombre d\'idées',
      'likes_dislikes_chart': 'Aime, n\'aime pas, suiveurs et commentaires',
      'likes': 'Aime',
      'dislikes': 'N\'aime pas',
      'comments': 'Commentaires',
      'followers': 'Suiveurs',
      'total_number': 'Nombre total'
    },
    'message': {
      'new': 'nouveaux messages envoyés'
    }
  };
});