define('im-webapp/components/regular-liker', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['liker'],
        session: Ember.inject.service(),
        counter: Ember.inject.service(),

        liked: Ember.computed('initiative.{likes,likes.[],dislikes,dislikes.[]}', function () {
            var currentUserId = this.get('session.data.authenticated.user_id');
            var likers = this.isLike ? this.get('initiative.likes') : this.get('initiative.dislikes');
            var liked = false;

            likers.forEach(function (liker) {
                if (+liker.get('id') === currentUserId) {
                    liked = true;
                }
            });

            return liked;
        }),

        likes: Ember.computed('isLike', 'initiative.{likes,likes.[],dislikes,dislikes.[]}', function () {
            var isLike = this.isLike ? 'likes' : 'dislikes';
            var count = this.get('counter').checkArray(this.get('initiative'), isLike);

            return {
                number: count.number,
                disableModal: !count.availableModal
            };
        }),
        actions: {
            /**
             * like
             * @method like
             * @param isLike (boolean) - defines is like or dislike
             * @param liked (boolean) - defines is adding or removing like/dislike
             */
            like: function like(isLike, liked) {
                this.get('like')(isLike, liked);
            }
        }
    });
});