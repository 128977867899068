define('im-webapp/models/attachment', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    uploader: (0, _relationships.belongsTo)('user', { inverse: null }),
    initiative: (0, _relationships.belongsTo)('initiative', { inverse: null }),
    icon: Ember.computed('name', function () {
      var name = this.get('name').toLowerCase();

      if (name.includes(".png") || name.includes(".jpg")) {
        return 'perm_media';
      } else if (name.includes(".pdf")) {
        return 'library_books';
      } else {
        return 'warning';
      }
    }),
    isPdf: Ember.computed('name', function () {
      return this.get('name').includes(".pdf");
    })
  });
});