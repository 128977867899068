define('im-webapp/services/ws', ['exports', 'im-webapp/config/environment', 'moment'], function (exports, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    i18n: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var webSocket = WS.connect(_environment.default.APP.SOCKET_API);
      var self = this;
      var secretToken = void 0;

      webSocket.on("socket/connect", function (session) {

        var localStorageData = JSON.parse(localStorage['ember_simple_auth-session']);

        if (localStorageData) {
          secretToken = localStorageData["authenticated"]['socket_token'];
        }
        if (secretToken) {
          session.subscribe("api/socket/chat/" + secretToken, function (uri, payload) {
            var event = payload.msg.translation.split('.')[0];

            switch (event) {
              case 'message':
                self.set('newMessage', {
                  user: payload.msg.user_id,
                  content: payload.msg.content,
                  isOtherUser: true,
                  avatar: payload.msg.avatar,
                  fullName: payload.msg.fullname,
                  created: (0, _moment.default)(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                  from: {
                    avatar: payload.msg.avatar,
                    id: payload.msg.user_id
                  },
                  get: function get(propertyName) {
                    return this[propertyName];
                  }
                });
                self.setupProperty('unreadMessages', self.get('unreadMessages') + 1);
                if (!window.location.href.includes('messages')) {
                  self.displayNotification(payload.msg.link, payload.msg);
                }
                break;
              case 'initiative':
              case 'idea':
                self.setupProperty('unreadNotifications', self.get('unreadNotifications') + 1);
                self.displayNotification(payload.msg.link, payload.msg);
                break;
            }
          });
        }
      });
    },
    createUserSocketMessage: function createUserSocketMessage(data, chatUserId) {
      var currentUser = this.get('currentUser.user');
      var chatUser = this.get('store').peekRecord('user', chatUserId);

      this.set('newMessage', {
        user: chatUserId,
        content: data,
        isOtherUser: false,
        avatar: chatUser.get('avatar'),
        fullName: chatUser.get('fullName'),
        created: (0, _moment.default)(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        from: {
          user: currentUser,
          avatar: currentUser.get('avatar')
        },
        to: {
          chatUser: chatUser
        },
        get: function get(propertyName) {
          return this[propertyName];
        }
      });
    },
    setupProperty: function setupProperty(property, value) {
      this.set(property, value);
    },
    displayNotification: function displayNotification(link, payload) {
      var i18n = this.get('i18n');
      var notifications = this.get('notifications') ? this.get('notifications') : [];

      notifications.pushObject({
        img: payload.avatar,
        fullName: payload.fullname,
        content: i18n.t(payload.translation),
        url: link.replace(/\//g, '.').replace(/.\d+/, ''),
        urlId: link.split('/').pop().trim()
      });

      this.set('notifications', notifications);
    }
  });
});