define('im-webapp/locales/pt/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'general': {
      'send': 'Mande',
      'email': 'E-mail',
      'confirm': 'confirme',
      'save': 'Salve',
      'delete': 'Elimine ',
      'cancel': 'Cancele',
      'error': "Erro",
      'invalid': "Inválido",
      'successful': "Bem sucedido",
      'active': "Ativo",
      'homepage': 'Pagina inicial',
      'are_you_sure': 'Tem certeza de que deseja eliminar',
      'search': 'Procure',
      'add': 'Adicione',
      'edit': 'Edite',
      'period': 'Período',
      'welcome': 'Bem vindo',
      'title': 'Título',
      'desc': 'Descrição',
      'categories': 'Categorias',
      'tags': 'Tag',
      'date': 'Data',
      'av_rate': 'Taxa média',
      'read_more': 'Leia mais ',
      'read_less': 'Leia menos',
      'close': 'Feche',
      'follow': 'Segue',
      'unfollow': 'Deixe de seguir',
      'prev': 'Anterior',
      'next': 'Próximo',
      'records_per_page': 'Registros por página',
      'current_page': 'Pagina atual',
      'show': 'Mostre ',
      'of': 'de',
      'columns': 'Colunas',
      'show_all': 'Mostre tudo',
      'hide_all': 'Esconda tudo',
      'restore_defaults': 'Restaure  padrão',
      'no_data': 'Nenhum registro para mostrar',
      'see_all': 'Ver tudo',
      'home': 'Home'
    },
    'auth': {
      'username': 'Nome de usuário',
      'password': 'Senha',
      'forgot_password': 'Esqueceu a senha',
      'login': 'Login',
      'logout': 'Logout',
      'back_to_login': 'Volte ao login',
      'confirm_password': 'Confirme a Senha',
      'recovery_password': 'Senha de recuperação',
      'password_changed': 'Senha alterada',
      'change_password': 'Mudar senha',
      'new_password': 'Nova senha',
      'successful_update_password': 'Senha atualizada com sucesso',
      'password_is_incorrect': 'Senha é incorreta',
      'remember_me': 'Lembre-se  de mim',
      'email_with_instructions': 'E-mail com instruções é enviado para o seu e-mail'
    },
    'error': {
      'bad_request': 'Pedido ruim',
      'resource_not_found': 'Recurso não encontrado',
      'unauthorized': 'Não autorizado',
      'no_permission': 'Você não tem permissão para esta ação',
      'not_allowed': 'Ação não é permitida',
      'time_out': 'Tempo expirou',
      'too_many': 'Muitos pedidos ao mesmo tempo',
      'server': 'Erro de servidor',
      'refresh_token': 'Não foi possível atualizar o token de acesso',
      'start_end_period': 'Selecione a data de início e validade',
      'start_before_expired': 'A data de validade deve ser posterior à data de início'
    },
    'notify': {
      'updated': " Atualizado com sucesso",
      'added': " Adicionado com sucesso",
      'deleted': "Registro eliminado  com sucesso",
      'tags': 'Tag',
      'categories': 'Categoria',
      'users': 'Utilizador',
      'companies': 'Companhia',
      'statuses': 'Status',
      'groups': 'Grupo',
      'initiatives': 'Iniciativa',
      'idea': 'Idéia',
      'comments': 'Comentário',
      'timeline-statuses': 'Status do período'
    },
    'category': {
      'category': 'Categoria',
      'category_management': 'Gerenciamento de categoria',
      'name': 'Nome da categoria',
      'desc': 'Descrição da categoria',
      'add_category': 'Adicionar categoria',
      'select_categories': 'Selecionar categorias ...'
    },
    'tag': {
      'tag': 'Tag',
      'tag_management': 'Gerenciamento de tags',
      'name': 'Nome do tag',
      'add_tag': 'Adicionar tag',
      'select_tags': 'Selecionar tags ...'
    },
    'user': {
      'user': 'Usuário',
      'first_name': 'Primeiro nome',
      'last_name': 'Sobrenome',
      'full_name': 'Nome completo',
      'group': 'Grupo',
      'my_profile': 'Meu perfil',
      'my_ideas': 'Minhas idéias',
      'my_initiatives': 'Minhas iniciativas',
      'my_feed': 'Minhas notícias',
      'user_management': 'Gerenciamento de usuários',
      'roles': 'Funções de usuário',
      'add_user': 'Adicionar usuário',
      'admin_role': 'Funções  do administrador',
      'user_role': 'Funções de usuário',
      'active': 'Ativo',
      'inactive': 'Inativo'
    },
    'group': {
      'group': 'Grupo',
      'group_management': 'Gerenciamento de grupo',
      'name': 'Nome do grupo',
      'desc': 'Descrição do grupo',
      'add_group': 'Adicione  grupo'
    },
    'status': {
      'status': 'Status',
      'status_management': 'Gerenciamento de status',
      'name': 'Nome do status',
      'desc': 'Descrição do status',
      'active': 'Status ativo',
      'add_status': 'Adicionar status',
      'color': 'Cor do status',
      'select_statuses': 'Selecione  status ...',
      'expire_date': 'Data do fim ',
      'start_date': 'Data de início',
      'voting': 'Status da votação',
      'final_status': 'Status final',
      'pending': 'Pendente',
      'approved': 'Aprovado',
      'declined': 'Recusado'
    },
    'initiative': {
      'initiative': 'Iniciativa',
      'initiative_management': 'Gerenciamento de iniciativas',
      'title': 'Título da iniciativa',
      'desc': 'Descrição da iniciativa',
      'categories': 'Categorias de iniciativa',
      'tags': 'Tags de iniciativa',
      'add_initiative': 'Adicione iniciativa',
      'follow_initiative': 'Siga a Iniciativa',
      'unfollow_initiative': 'Deixe de seguir a iniciativa',
      'followers': 'Seguidores',
      'latest': 'Últimas iniciativas',
      'available_statuses': 'Status disponíveis',
      'current_status': 'Status atual',
      'unrated': 'Sem classificação',
      'like': 'iniciativas gostadas ',
      'created': 'iniciativas criadas ',
      'follow': 'Iniciativas seguidas ',
      'unfollow': 'deixar de seguir iniciante',
      'comment': 'iniciativas comentadas',
      'dislike': 'Iniciativa não gostadas ',
      'attachment': 'uploaded anexo  na iniciativa ',
      'feed': 'Notícias sobre a  iniciativa'
    },
    'idea': {
      'idea': 'Ideia',
      'ideas': 'Ideias',
      'idea_management': 'Gerenciamento de idéias',
      'add_idea': 'Adicione ideia',
      'follow_idea': 'Siga a ideia',
      'unfollow_idea': 'Deixe  de seguir a ideia',
      'idea_feed': 'Notícias sobre a  ideia',
      'like': 'Idea gostada',
      'created': 'ideia criada',
      'follow': 'ideia seguida',
      'unfollow': 'ideia não seguida',
      'comment': 'comentou a ideia',
      'dislike': 'ideia não gostada',
      'attachment': ' uploaded  anexo  na ideia ',
      'feed': 'Notícias sobre a  ideia'
    },
    'upload': {
      'drag_to_upload': 'Arraste para fazer upload',
      'drag_and_drop_area': 'Arraste e solte a área',
      'upload_new_file': 'Upload novo arquivo'
    },
    'comments': {
      'comments': 'Comentários',
      'comment': 'Comentário',
      'required_field': 'O comentário não pode ficar em branco',
      'comment_text': 'Deixe seu comentário ...'
    },
    'media': {
      'media': 'Mídia',
      'no_media_added': 'Nenhuma mídia adicionada',
      'media_library': 'Biblioteca de mídia',
      'media_avatar': 'Visualização do arquivo',
      'media_name': 'Nome da mídia',
      'media_author': 'Autor',
      'media_actions': 'Acções',
      'initiative_idea': 'Iniciativa / ideia'
    },
    'languages': {
      'language': 'Língua',
      'en': 'En',
      'rs': 'Sr',
      'el': 'El',
      'es': 'Es',
      'fr': 'Fr',
      'it': 'It',
      'pt': 'Pt',
      'ru': 'Ru'
    },
    'messages': {
      'messages': 'Mensagens',
      'type_message': 'Digite uma mensagem ...',
      'new_message': 'Nova mensagem',
      'welcome': 'Bem-vindo às mensagens!',
      'select_user': 'Selecione o  usuário'
    },
    'notifications': {
      'notifications': 'Notificações'
    },
    'image': {
      'change_image': 'Altere imagem',
      'confirm_image_note': 'Se você deseja manter esta imagem, confirme.'
    },
    'like': {
      'likes': 'Likes ',
      'dislikes': 'Dislikes',
      'voting_finished': 'A votação terminou'
    },
    'validations': {
      'password_must_match': 'A senha deve corresponder',
      'email': 'Deve ser um endereço de email válido',
      'not_a_number': 'Deve ser um número',
      'present': 'Não pode ficar em branco',
      'too_short': 'Muito curto (o mínimo é {min} caracteres)',
      'invalid': 'Inválido',
      'password_visible': 'Esconda a  senha',
      'password_invisible': 'Mostre  senha'
    },
    'table': {
      'title': 'Título',
      'edit': 'Edite',
      'delete': 'elimine ',
      'fullname': 'Nome completo',
      'name': 'Nome',
      'desc': 'Descrição',
      'tag': 'Tag',
      'status': 'Status'
    },
    'slider': {
      'empty_slider': 'Nenhuma iniciativa'
    },
    'timeline': {
      'already_selected_days': 'Você já  selecionou esses dias',
      'change_available_days': '  Primeiro altere outros status e disponibilize esses dias '
    },
    'activity': {
      'like': 'gostado',
      'created': 'criado',
      'follow': 'Seguidores',
      'unfollow': 'não seguido',
      'comment': 'comentado  sobre',
      'dislike': 'não gostei',
      'attachment': 'anexo adicionado',
      'status changed': 'O status mudou',
      'delete': 'elimine ',
      'remove like': 'Like removido ',
      'remove dislike': 'Dislike removido',
      'remove comment': 'comentário removido',
      'remove attachment': 'anexo removido',
      'feed': 'notícias sore as atividades ',
      'my_feed': 'Minhas notícias'
    },
    'statistics': {
      'statistics': 'Estatisticas',
      'ideas_per_status': 'Ideias por status',
      'initiatives_per_status': 'Iniciativas por status',
      'number_of_ideas': 'Número de ideias',
      'number_of_initiatives': 'Número de iniciativas',
      'initiatives_per_idea': 'Iniciativas por número de idéias',
      'likes_dislikes_chart': 'Likes dislikes , seguidores e comentários',
      'likes': 'Gosta',
      'dislikes': 'Não gosta',
      'comments': 'Comentários',
      'followers': 'Seguidores',
      'total_number': 'Número total'
    },
    'message': {
      'new': ' novas mensagens enviadas'
    }
  };
});