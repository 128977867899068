define('im-webapp/controllers/protected/tag-management', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        tagsTable: Ember.computed('model.{tags,tags.[]}', function () {
            var tags = this.get('model').tags;
            return {
                header: [{ title: 'Tag', propertyName: 'name', routeName: "protected.tag-management.tag" }, {
                    title: 'Edit',
                    component: "table-button",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-button",
                    isDelete: true,
                    disableSorting: true
                }],
                data: tags
            };
        }),

        actions: {
            closeModal: function closeModal() {
                this.set('deleteModal', false);
            },
            deleteTag: function deleteTag(id) {
                var tag = this.get('store').peekRecord('tag', id);
                this.set('currentTag', tag);
                this.set('deleteModal', true);
            },
            confirmDeleteTag: function confirmDeleteTag() {
                var tag = this.get('currentTag');
                tag.deleteRecord();
                tag.save();
                this.send('closeModal');
            },
            addTag: function addTag() {
                this.transitionToRoute('protected.tag-management.tag', 'new');
            },
            editTag: function editTag(id) {
                this.transitionToRoute('protected.tag-management.tag', id);
            }
        }
    });
});