define('im-webapp/controllers/protected', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ws: Ember.inject.service('ws'),

    setupViaModel: Ember.observer('model.unreadMessages.[]', 'model.model.unreadNotifications.[]', function () {
      var ws = this.get('ws');
      ws.setupProperty('unreadMessages', this.get('model.unreadMessages').get('length'));
      ws.setupProperty('unreadNotifications', this.get('model.unreadNotifications').get('length'));
    }),

    menuItems: Ember.computed('model.currentUser', function () {
      var userRoles = this.get('model').currentUser.get('roles');
      var userMenuItems = [{
        icon: 'homepage',
        link: 'protected.index',
        label: 'general.home'
      }, {
        icon: 'my-initiatives',
        link: 'protected.my-initiatives',
        label: 'user.my_initiatives'
      }, {
        icon: 'my-ideas',
        link: 'protected.my-ideas',
        label: 'user.my_ideas'
      }, {
        icon: 'my-activity-feed',
        link: 'protected.my-feed',
        label: 'user.my_feed'
      }, {
        icon: 'my-profile',
        link: 'protected.my-profile',
        label: 'user.my_profile'
      }];
      var adminMenuItems = [{
        isDivider: true
      }, {
        icon: 'statistics',
        link: 'protected.statistics',
        label: 'statistics.statistics'
      }, {
        icon: 'media-library',
        link: 'protected.media-library',
        label: 'media.media_library'
      }, {
        isDivider: true
      }, {
        icon: 'initiative-management',
        link: 'protected.initiative-management',
        label: 'initiative.initiative_management'
      }, {
        icon: 'idea-management',
        link: 'protected.idea-management',
        label: 'idea.idea_management'
      }, {
        icon: 'user-management',
        link: 'protected.user-management',
        label: 'user.user_management'
      }, {
        icon: 'group-management',
        link: 'protected.group-management',
        label: 'group.group_management'
      }, {
        icon: 'tag-management',
        link: 'protected.tag-management',
        label: 'tag.tag_management'
      }, {
        icon: 'category-management',
        link: 'protected.category-management',
        label: 'category.category_management'
      }, {
        icon: 'status-management',
        link: 'protected.status-management',
        label: 'status.status_management'
      }];
      return userRoles.includes('ROLE_ADMIN') ? userMenuItems.concat(adminMenuItems) : userMenuItems;
    }),

    actions: {
      closeNotification: function closeNotification(notification) {
        this.get("ws.notifications").removeObject(notification);
      }
    }
  });
});