define("im-webapp/routes/missing", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        /**
         * redirect method show notice and redirects user to homepage/login
         * @method redirect
         */
        redirect: function redirect() {
            this.transitionTo("protected.index");
        }
    });
});