define("im-webapp/locales/rs/config", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        // rtl: [true|FALSE],
        //
        // pluralForm: function(count) {
        //   if (count === 0) { return 'zero'; }
        //   if (count === 1) { return 'one'; }
        //   if (count === 2) { return 'two'; }
        //   if (count < 5) { return 'few'; }
        //   if (count >= 5) { return 'many'; }
        //   return 'other';
        // }
    };
});