define('im-webapp/routes/protected/idea/media', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function model(params, history) {
            var initiativeId = history.state.params['protected.idea'].id;
            return Ember.RSVP.hash({
                attachments: this.get('store').query('attachment', { initiative: initiativeId, include: 'uploader' }),
                initiativeId: initiativeId
            });
        }
    });
});