define('im-webapp/controllers/protected/initiative-management', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        initiativesTable: Ember.computed('model.{initiatives,initiatives.[]}', function () {
            var initiatives = this.get('model').initiatives || [];
            return {
                header: [{ title: 'Title', propertyName: 'title', routeName: "protected.initiative-management.initiative" }, {
                    title: 'Edit',
                    component: "table-button",
                    disableSorting: true
                }, {
                    title: 'Delete',
                    component: "table-button",
                    isDelete: true,
                    disableSorting: true
                }],
                data: initiatives
            };
        }),

        customFilters: Ember.computed('model.{initiatives,initiatives.[]}', function () {
            return this.get('model').initiatives.meta;
        }),

        actions: {
            closeModal: function closeModal() {
                this.set('deleteModal', false);
            },
            deleteInitiative: function deleteInitiative(id) {
                var initiative = this.get('store').peekRecord('initiative', id);
                this.set('currentInitiative', initiative);
                this.set('deleteModal', true);
            },
            confirmDeleteInitiative: function confirmDeleteInitiative() {
                var initiative = this.get('currentInitiative');
                initiative.deleteRecord();
                initiative.save();
                this.send('closeModal');
            },

            //adding idea
            addInitiative: function addInitiative() {
                this.set('newInitiative', true);

                var randomId = Math.floor(Math.random() * 10000) + 1000;
                var record = this.get('store').createRecord('initiative', {
                    id: randomId
                });

                this.set('newInitiative', {
                    initiative: record,
                    randomId: randomId
                });
            },
            closeAddModal: function closeAddModal() {
                //unload record if adding is canceled
                var initiative = this.get('store').peekRecord('initiative', this.get('newInitiative').randomId);
                initiative.unloadRecord();
                this.set('newInitiative', false);
            },
            saveInitiative: function saveInitiative(initiative) {
                var _this = this;

                var self = this;
                var customFilters = this.get('customFilters');
                customFilters['initiative[exists]'] = false;
                customFilters['order'] = { 'created': 'DESC' };

                //set id to null before saving to server
                initiative.set('id', null);
                initiative.save().then(function () {
                    //updating initiatives on add
                    self.get('store').query('initiative', customFilters).then(function (initiatives) {
                        _this.set('model.initiatives', initiatives);
                        self.set('newInitiative', false);
                    });
                });
            },
            editInitiative: function editInitiative(id) {
                this.transitionToRoute('protected.initiative-management.initiative', id);
            },
            updateInitiatives: function updateInitiatives(params) {
                var _this2 = this;

                params['initiative[exists]'] = false;
                this.get('store').query('initiative', params).then(function (res) {
                    return _this2.set('model.initiatives', res);
                });
            }
        }
    });
});