define('im-webapp/components/comment-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['comment-list'],
        session: Ember.inject.service(),
        store: Ember.inject.service(),

        sortedComments: Ember.computed('comments', 'comments.[]', function () {
            return this.comments.sortBy('created').reverse();
        }),

        actions: {
            addComment: function addComment(comment) {
                var self = this;
                if (comment) {

                    var commentRecord = self.get('store').createRecord('comment', {
                        text: comment,
                        created: new Date(),
                        creator: self.get('store').peekRecord('user', self.get('session.data.authenticated.user_id')),
                        initiative: self.get('store').peekRecord('initiative', self.get('initiativeId'))
                    });

                    commentRecord.save().then(function () {
                        self.set('blankComment', false);
                        self.set('newComment', null);
                        self.sendAction('updateComments');
                    });
                } else {
                    self.set('blankComment', true);
                }
            }
        }
    });
});