define('im-webapp/models/timeline-status', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    startDate: (0, _attr.default)(''),
    expireDate: (0, _attr.default)(''),
    status: (0, _relationships.belongsTo)('status', { inverse: null }),
    initiative: (0, _relationships.belongsTo)('initiative', { inverse: null })
  });
});