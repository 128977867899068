define('im-webapp/routes/protected/my-profile', ['exports', 'moment', 'im-webapp/config/environment'], function (exports, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    customAjax: Ember.inject.service('customAjax'),
    i18n: Ember.inject.service(),
    toast: Ember.inject.service(),

    model: function model() {
      var customAjax = this.get('customAjax');
      var toast = this.get('toast');
      var i18n = this.get('i18n');

      var userId = this.get('session.data.authenticated.user_id');

      var timeFilter = {
        start: (0, _moment.default)().startOf('month').format(_environment.default.APP.FILTER_FORMAT),
        end: (0, _moment.default)().endOf('month').format(_environment.default.APP.FILTER_FORMAT),
        owner: userId
      };

      var ideasFilter = {
        start: timeFilter.start,
        end: timeFilter.end,
        owner: timeFilter.owner,
        initiative: true
      };

      var initiativesFilter = {
        start: timeFilter.start,
        end: timeFilter.end,
        owner: timeFilter.owner,
        initiative: false
      };

      //per status
      var urlPerStatus = _environment.default.APP.API_HOST + '/count-initiatives-per-status';

      //comparison
      var urlCompare = _environment.default.APP.API_HOST + '/compare-initiatives';

      return Ember.RSVP.hash({
        currentUser: this.get('store').findRecord('user', userId),
        perStatusIdeas: customAjax.request('GET', urlPerStatus, ideasFilter, false).then(function (data) {
          return data;
        }).catch(function () {
          toast.error(i18n.t('error.server'), i18n.t('general.error'));
        }),
        perStatusInitiatives: customAjax.request('GET', urlPerStatus, initiativesFilter, false).then(function (data) {
          return data;
        }).catch(function () {
          toast.error(i18n.t('error.server'), i18n.t('general.error'));
        }),
        comparingIdeas: customAjax.request('GET', urlCompare, ideasFilter, false).then(function (data) {
          return data;
        }).catch(function () {
          toast.error(i18n.t('error.server'), i18n.t('general.error'));
        }),
        comparingInitiatives: customAjax.request('GET', urlCompare, initiativesFilter, false).then(function (data) {
          return data;
        }).catch(function () {
          toast.error(i18n.t('error.server'), i18n.t('general.error'));
        })
      });
    },
    actions: {
      loading: function loading() {
        this.controllerFor('protected').set('isLoading', true);
        this.router.on('didTransition', this, function () {
          this.controllerFor('protected').set('isLoading', false);
        });
      }
    }
  });
});