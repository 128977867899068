define('im-webapp/locales/rs/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    // "some.translation.key": "Text for some.translation.key",
    //
    // "a": {
    //   "nested": {
    //     "key": "Text for a.nested.key"
    //   }
    // },
    //
    // "key.with.interpolation": "Text with {{anInterpolation}}"

    'general': {
      'send': 'Pošalji',
      'email': 'Email',
      'confirm': 'Potvrdi',
      'save': 'Sačuvaj',
      'delete': 'Obriši',
      'cancel': 'Otkaži',
      'error': "Greška",
      'invalid': "Pogrešno",
      'successful': "Uspešno",
      'active': "Aktivno",
      'homepage': 'Početna strana',
      'are_you_sure': 'Da li ste sigurni da želite da obrišete ',
      'search': 'Pretraga',
      'add': 'Dodaj',
      'edit': 'Izmeni',
      'period': 'Period',
      'welcome': 'Dobrodošli',
      'title': 'Naslov',
      'desc': 'Opis',
      'categories': 'Kategorije',
      'tags': 'Tagovi',
      'date': 'Datum',
      'av_rate': 'prosek',
      'read_more': 'Pročitaj više',
      'read_less': 'Pročitaj manje',
      'close': 'Zatvori',
      'follow': 'Zaprati',
      'unfollow': 'Otprati',
      'prev': 'Prethodna',
      'next': 'Sledeća',
      'records_per_page': 'Po strani',
      'current_page': 'Trenutna strana',
      'show': 'Prikaz',
      'of': 'od',
      'columns': 'Kolone',
      'show_all': 'Prikaži sve',
      'hide_all': 'Sakrij sve',
      'restore_defaults': 'Vrati podrazumevane vrednosti',
      'no_data': 'Nema podataka',
      'see_all': 'Pogledaj sve',
      'home': 'Početna stranica'
    },
    'auth': {
      'username': 'Korisničko ime',
      'password': 'Lozinka',
      'forgot_password': 'Zaboravljena lozinka',
      'login': 'Prijava',
      'logout': 'Odjava',
      'back_to_login': 'Nazad na prijavu',
      'confirm_password': 'Potvrdi lozinku',
      'recovery_password': 'Ispravka lozinke',
      'password_changed': 'Lozinka promenjena',
      'change_password': 'Izmenite lozinku',
      'new_password': 'Nova lozinka',
      'successful_update_password': 'Uspešno izmenjena lozinka',
      'password_is_incorrect': 'Lozinka nije validna',
      'remember_me': 'Zapamti moje podatke',
      'email_with_instructions': 'Email sa instrukcijama je poslat na Vaš email'
    },
    'error': {
      'bad_request': 'Pogrešan zahtev',
      'resource_not_found': 'Resurs nije pronađen',
      'unauthorized': 'Neautorizovan',
      'no_permission': 'Nemate dozvolu za ovu akciju',
      'not_allowed': 'Akcija nije dozvoljena',
      'time_out': 'Time out',
      'too_many': 'Previše zahteva u isto vreme',
      'server': 'Greška servera',
      'refresh_token': 'Token pristupa nije mogao biti osvežen',
      'start_end_period': 'Izaberite i datum početka i datum isteka',
      'start_before_expired': 'Datum isteka mora biti ispred datuma početka'
    },
    'notify': {
      'updated': " uspešno ažuriran",
      'added': " uspešno dodat",
      'deleted': "Uspešno obrisano",
      'tags': 'Tag',
      'categories': 'Kategorija',
      'users': 'Korisnik',
      'companies': 'Kompanija',
      'statuses': 'Status',
      'groups': 'Grupa',
      'initiatives': 'Inicijativa',
      'comments': 'Komentar',
      'timeline-statuses': 'Period status'
    },
    'category': {
      'category': 'Kategorija',
      'category_management': 'Upravljanje kategorijama',
      'name': 'Naziv kategorije',
      'desc': 'Opis kategorije',
      'add_category': 'Dodaj kategoriju',
      'select_categories': 'Izaberite kategorije...'
    },
    'tag': {
      'tag': 'Tag',
      'tag_management': 'Upravljanje tagovima',
      'name': 'Naziv taga',
      'add_tag': 'Dodaj tag',
      'select_tags': 'Izaberite tagove...'
    },
    'user': {
      'user': 'Korisnik',
      'first_name': 'Ime',
      'last_name': 'Prezime',
      'full_name': 'Ime i prezime',
      'group': 'Grupa',
      'my_profile': 'Moj profil',
      'my_ideas': 'Moje ideje',
      'my_initiatives': 'Moje inicijative',
      'my_feed': 'Moj zid',
      'user_management': 'Upravljanje korisnicima',
      'roles': 'Role korisnika',
      'add_user': 'Dodaj korisnika',
      'admin_role': 'Administrator',
      'user_role': 'Korisnik',
      'active': 'Aktivan',
      'inactive': 'Neaktivan',
      'my_init_and_ideas': 'Moja statistika za inicijative i ideje'
    },
    'group': {
      'group': 'Grupa',
      'group_management': 'Upravljanje grupama',
      'name': 'Naziv grupe',
      'desc': 'Opis grupe',
      'add_group': 'Dodaj grupu'
    },
    'status': {
      'status': 'Status',
      'status_management': 'Upravljanje statusima',
      'name': 'Naziv statusa',
      'desc': 'Opis statusa',
      'active': 'Aktivan status',
      'add_status': 'Dodaj status',
      'color': 'Boja statusa',
      'select_statuses': 'Izaberite statuse...',
      'expire_date': 'Dan isticanja',
      'start_date': 'Početni dan',
      'voting': 'Status glasanja',
      'final_status': 'Konačni status',
      'pending': 'Na čekanju',
      'approved': 'Odobreno',
      'declined': 'Odbijeno'
    },
    'initiative': {
      'initiative': 'Inicijativa',
      'initiative_management': 'Upravljanje inicijativama',
      'title': 'Naslov inicijative',
      'desc': 'Opis inicijative',
      'categories': 'Kategorije inicijative',
      'tags': 'Tagovi inicijative',
      'add_initiative': 'Dodaj inicijativu',
      'follow_initiative': 'Zaprati inicijativu',
      'unfollow_initiative': 'Otprati inicijativu',
      'followers': 'Pratioci',
      'latest': 'Najnovije inicijative',
      'available_statuses': 'Dostupni statusi',
      'current_status': 'Trenutni status',
      'unrated': 'Neocenjeno',
      'like': 'se sviđa inicijativa',
      'created': 'je kreirao/la inicijativu',
      'follow': 'je zapratio/la inicijativu',
      'unfollow': 'je otpratio/la inicijativu',
      'comment': 'je komentarisao/la inicijativu',
      'dislike': 'se ne sviđa inicijativa',
      'attachment': 'je priložio/la datoteku na inicijativu',
      'feed': 'Aktivnosti'
    },
    'idea': {
      'idea': 'Ideja',
      'ideas': 'Ideje',
      'idea_management': 'Upravljanje idejama',
      'add_idea': 'Dodaj ideju',
      'follow_idea': 'Zaprati ideju',
      'unfollow_idea': 'Otprati ideju',
      'idea_feed': 'Ideja feed',
      'like': 'se sviđa idea',
      'created': 'je kreirao/la ideju',
      'follow': 'je zapratio/la ideju',
      'unfollow': 'je otpratio/la ideju',
      'comment': 'je komentarisao/la ideju',
      'dislike': 'se ne sviđa ideja',
      'attachment': 'je priložio/la datoteku na ideju',
      'feed': 'Aktivnosti'
    },
    'upload': {
      'drag_to_upload': 'Povuci za otpremanje',
      'drag_and_drop_area': 'Prevuci i otpusti zona',
      'upload_new_file': 'Otpremi novi dokument'
    },
    'comments': {
      'comments': 'Komentari',
      'comment': 'Komentar',
      'required_field': 'Komentar ne može biti prazan',
      'comment_text': 'Komentariši'
    },
    'media': {
      'media': 'Fajlovi',
      'no_media_added': 'Nema dodatih fajlova',
      'media_library': 'Biblioteka fajlova',
      'media_avatar': 'Pregled fajla',
      'media_name': 'Ime fajla',
      'media_author': 'Autor',
      'media_actions': 'Upravljanje fajlovima',
      'initiative_idea': 'Inicijativa/ideja'
    },
    'languages': {
      'language': 'Jezik',
      'en': 'En',
      'rs': 'Sr',
      'el': 'El',
      'es': 'Es',
      'fr': 'Fr',
      'it': 'It',
      'pt': 'Pt',
      'ru': 'Ru'
    },
    'messages': {
      'messages': 'Poruke',
      'type_message': 'Kucaj poruku...',
      'new_message': 'Nova poruka',
      'welcome': 'Dobrodošli u poruke!',
      'select_user': 'Izaberite korisinka'
    },
    'notifications': {
      'notifications': 'Obaveštenja'
    },
    'image': {
      'change_image': 'Promeni sliku',
      'confirm_image_note': 'Ukoliko želite da zadržite ovu sliku potvrdite.'
    },
    'like': {
      'likes': 'Lajkovi',
      'dislikes': 'Dislajkovi',
      'voting_finished': 'Glasanje je završeno'
    },
    'validations': {
      'password_must_match': 'Lozinke se moraju podudarati',
      'email': 'email adresa mora biti validna',
      'not_a_number': 'Mora biti broj',
      'present': 'Obavezno polje',
      'too_short': 'Minimum je {min} karaktera',
      'invalid': 'Neispravno',
      'password_visible': 'Sakrij lozinku',
      'password_invisible': 'Prikaži lozinku'
    },
    'table': {
      'title': 'Naslov',
      'edit': 'Izmeni',
      'delete': 'Obriši',
      'fullname': 'Ime i prezime',
      'name': 'Naziv',
      'desc': 'Opis',
      'tag': 'Tag',
      'status': 'Status'
    },
    'slider': {
      'empty_slider': 'Nema inicijativa'
    },
    'timeline': {
      'already_selected_days': 'Već odabrani dani',
      'change_available_days': ' Izmenite ostale statuse kako bi ovi dani postali dostupni'
    },
    'activity': {
      'like': 'se sviđa',
      'created': 'je kreirao/la',
      'follow': 'je zapratio/la',
      'unfollow': 'je otpratio/la',
      'comment': 'je komentarisao/la',
      'dislike': 'se ne sviđa',
      'attachment': 'je priložio/la datoteku',
      'status changed': 'Status je promenjen',
      'delete': 'obrisao/la',
      'remove like': 'obrisao/la sviđanje',
      'remove dislike': 'obrisao/la nesviđanje',
      'remove comment': 'obrisao/lakomentar',
      'remove attachment': 'obrisao/la priloženu datoteku',
      'feed': 'Aktivnosti',
      'my_feed': 'Moje aktivnosti'
    },
    'statistics': {
      'statistics': 'Statistika',
      'ideas_per_status': 'Ideje po statusu',
      'initiatives_per_status': 'Inicijative po statusu',
      'number_of_ideas': 'Broj ideja',
      'number_of_initiatives': 'Broj inicijativa',
      'initiatives_per_idea': 'Inicijative po broju ideja',
      'likes_dislikes_chart': 'Sviđanja, Nesviđanja, pratioci i komentari',
      'likes': 'Sviđanja',
      'dislikes': 'Nesviđanja',
      'comments': 'Komentari',
      'followers': 'Pratioci',
      'total_number': 'Ukupan broj'
    },
    'message': {
      'new': 'poslao novu poruku'
    }
  };
});