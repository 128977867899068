define('im-webapp/components/chat-container', ['exports', 'im-webapp/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['messages-chat-container'],
        session: Ember.inject.service(),
        ws: Ember.inject.service(),
        momentFormat: _environment.default.APP.FULL_MOMENT_FORMAT,

        init: function init() {
            this._super.apply(this, arguments);
            this.set('ws.newMessage', false);
            this.set('ws.chatMessages', []);
        },
        didRender: function didRender() {
            this._super.apply(this, arguments);
            var element = Ember.$('#' + this.elementId);
            var scrollHeight = element.prop('scrollHeight');
            element.scrollTop(scrollHeight);
        },


        formattedMessages: Ember.computed('model.{messages,messages.[]}', 'ws.newMessage', function () {
            var chatUserId = this.get('model.chatUserId');
            var currentUserId = this.get('session.data.authenticated.user_id');

            var formattedArray = this.get('model.messages').map(function (message) {
                message.set('isOtherUser', message.get('from').get('id') !== currentUserId.toString());
                return message;
            });

            if (this.get('ws.newMessage')) {
                var chatMessages = this.get('ws.chatMessages');
                chatMessages.push(this.get('ws.newMessage'));
                chatMessages.forEach(function (msg) {
                    if (msg.user === chatUserId) {
                        formattedArray.push(msg);
                    }
                });
                this.set('ws.newMessage', false);
                this.set('ws.chatMessages', chatMessages);
            }

            return formattedArray.sort(function (obj1, obj2) {
                return new Date(obj1.get('created')) - new Date(obj2.get('created'));
            });
        }),

        filteredUsers: Ember.computed('model.{users,users.[]}', function () {
            var currentUserId = this.get('session.data.authenticated.user_id');
            return this.get('model.users').filter(function (user) {
                if (user.get('id') !== currentUserId.toString()) {
                    return user;
                }
            });
        }),

        actions: {
            didSelectUser: function didSelectUser(selectedUser) {
                this.get('didSelectUser')(selectedUser);
            },
            sendMessage: function sendMessage(content, selectedUser) {
                this.get('sendMessage')(content, selectedUser);
            }
        }
    });
});