define('im-webapp/components/regular-slider', ['exports', 'im-webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['slider'],
    counter: Ember.inject.service(),
    coverPhoto: Ember.inject.service(),
    momentFormat: _environment.default.APP.MOMENT_FORMAT,

    init: function init() {
      this._super.apply(this, arguments);
      this.formatSlides();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.formatSlides();
    },
    formatSlides: function formatSlides() {
      var coverPhoto = this.get('coverPhoto');
      var counter = this.get('counter');

      var formattedSlides = this.slides.map(function (slide, index) {

        var imgUrl = slide.get('cover') ? _environment.default.APP.HOST + slide.get('cover') : coverPhoto.setCoverPhoto();

        slide.set('backgroundImage', Ember.String.htmlSafe("background-image: url('" + imgUrl + "')"));
        slide.set('isActive', index === 0);
        slide.set('ideas.number', counter.checkArray(slide, 'ideas').number);
        slide.set('comments.number', counter.checkArray(slide, 'comments').number);
        slide.set('attachments.number', counter.checkArray(slide, 'attachments').number);
        slide.set('followers.number', counter.checkArray(slide, 'followers').number);
        slide.set('likes.number', counter.checkArray(slide, 'likes').number);
        slide.set('dislikes.number', counter.checkArray(slide, 'dislikes').number);
        return slide;
      });

      this.set('numberOfSlides', formattedSlides.length);
      this.set('formattedSlides', formattedSlides);
      this.set('activeIndex', 0);
    },


    actions: {
      moveSlide: function moveSlide(slide) {

        var formattedSlides = this.get('formattedSlides');
        var movedSlides = [];
        var activeIndex = this.get('activeIndex');
        var numberOfSlides = this.get('numberOfSlides');

        //TODO Refactor
        if (slide === 'left') {
          if (activeIndex === 0) {
            activeIndex = numberOfSlides - 1;
          } else {
            activeIndex--;
          }
          this.set('toLeft', true);
        } else {
          if (activeIndex === numberOfSlides - 1) {
            activeIndex = 0;
          } else {
            activeIndex++;
          }
          this.set('toLeft', false);
        }

        movedSlides = formattedSlides.map(function (slide, index) {
          var isActive = false;
          if (index === activeIndex) {
            isActive = true;
          }
          slide.set('isActive', isActive);

          return slide;
        });

        this.set('formattedSlides', movedSlides);
        this.set('activeIndex', activeIndex);
      }
    }
  });
});