define('im-webapp/controllers/protected/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Controller.extend({
        queryParams: ['search'],
        search: null,

        actions: {
            updateInitiatives: function updateInitiatives(params) {
                var _this = this;

                var additionalFilters = this.get('model.additionalFilters');
                //removing init period filters
                delete additionalFilters['created[after]'];
                delete additionalFilters['created[before]'];

                Object.assign.apply(Object, [params].concat(_toConsumableArray(params), [additionalFilters]));
                this.get('store').query('initiative', params).then(function (res) {
                    return _this.set('model.initiatives', res);
                });
            },
            addInitiative: function addInitiative() {
                this.set('newInitiative', true);

                var randomId = Math.floor(Math.random() * 10000) + 1000;
                var record = this.get('store').createRecord('initiative', {
                    id: randomId
                });

                this.set('newInitiative', {
                    initiative: record,
                    randomId: randomId
                });
            },
            closeModal: function closeModal() {
                //unload record if adding is canceled
                var initiative = this.get('store').peekRecord('initiative', this.get('newInitiative').randomId);
                initiative.unloadRecord();
                this.set('newInitiative', false);
            },
            saveInitiative: function saveInitiative(initiative) {
                var _this2 = this;

                var self = this;
                var filters = this.get('model.additionalFilters');
                //set id to null before saving to server
                initiative.set('id', null);
                initiative.save().then(function () {
                    self.set('newInitiative', false);
                    //updating initiatives on add
                    self.get('store').query('initiative', filters).then(function (initiatives) {
                        _this2.set('model.initiatives', initiatives);
                    });
                });
            },
            loadNewData: function loadNewData(data) {
                this.set('model.initiatives', data);
            }
        }
    });
});